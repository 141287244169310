import React from "react";
import {
  makeStyles,
  Typography,
  Divider,
  ListItemAvatar,
  Button,
  Avatar,
  TextareaAutosize,
  ListSubheader,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { withAllContexts } from "../../../../HOCs";

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    height: "100%",
    width: 300,
  },
  title: {
    backgroundColor: "#F8F7F9",
  },
  titleText: {
    fontSize: "14px",
    color: "#110f47",
    fontFamily: "fontSemiBold",
    margin: 0,
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  closeIcon: {
    fontSize: 16,
  },
  commentParent: {
    padding: 10,
  },
  commentBorder: {
    padding: 10,
    border: ".8px solid gray",
    borderStyle: "dashed",
    borderRadius: 8,
    backgroundColor: "#F8F7F9",
  },
  commentActionDis: {
    margin: 0,
    fontSize: 14,
  },
  commentActionTitle: {
    marginTop: 0,
    marginBottom: 12,
    fontSize: 16,
    fontWeight: 500,
  },
  commentBox: {
    width: "90%",
    height: 45,
    marginTop: "6%",
    padding: 12,
    border: ".8px solid gray",
    borderRadius: 8,
  },
  commentButtonDiv: {
    marginTop: "4%",
    textAlign: "end",
  },
  postBtn: {
    borderRadius: 8,
  },
  preCommentDis: {
    fontSize: ".92rem",
    fontWeight: 400,
    color: "#18164C",
  },
  preCommentTitle: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#18164C",
  },
  days: {
    fontSize: ".8rem",
    fontWeight: 400,
    color: "#ADADBC",
  },
  MoreIcon: {
    textAlign: "right",
    float: "right",
    fontSize: 19,
  },
  replyBtn: {
    padding: 0,
    minWidth: 0,
    marginTop: "2%",
    fontSize: ".88rem",
    color: "#f50057",
    opacity: 0.9,
  },
}));

function Comments(props) {
  const classes = useStyles();
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ backgroundColor: "#F8F7F9" }}
        >
          <div className={classes.title}>
            <p className={classes.titleText}>
              Comments
              <IconButton component="span" onClick={props.closeHandle}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </p>
          </div>
        </ListSubheader>
      }
    >
      <div className={classes.commentParent}>
        <div className={classes.commentBorder}>
          <p className={classes.commentActionTitle}>Action ID: #22929</p>
          <p className={classes.commentActionDis}>
            Patient selects a Doctor & Proceeds for Appointment
          </p>
        </div>
        <div>
          <TextareaAutosize
            aria-label="minimum height"
            rowsMin={6}
            className={classes.commentBox}
            placeholder={"Type your Comments..."}
          />
          <div className={classes.commentButtonDiv}>
            <Button>Cancel</Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.postBtn}
              onClick={() => props.onClickcomm()}
            >
              post
            </Button>
          </div>
        </div>
      </div>
      {/* <Divider /> */}
      <List className={classes.root}>
        {props.comments?.map((item, j) => {
          return (
            <>
              <Divider />
              <ListItem key={j} alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt="Remy Sharp"
                    src="https://picsum.photos/200/300"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="h6"
                        className={classes.preCommentTitle}
                      >
                        {item.name} &nbsp;
                      </Typography>
                      <Typography
                        component="span"
                        variant="h6"
                        className={classes.days}
                      >
                        | {item.days}
                      </Typography>
                      <MoreHoriz className={classes.MoreIcon} />
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="h6"
                        className={classes.preCommentDis}
                      >
                        {item.message}
                      </Typography>
                      <br />
                      <Button className={classes.replyBtn}>Reply</Button>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </>
          );
        })}
      </List>
    </List>
  );
}
export default withAllContexts(Comments);

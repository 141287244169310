import React from "react";

// import { localStorageKeys } from "../../utils";
// import { withRouter } from "react-router-dom";
// import routes from "../../router/routes";

import MainHeader from "../../components/mainheader";
import Body from "./body";

class PlayHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header: "",
      save: false,
    };
  }

  handleSave = (v) => {
    this.setState({ save: v === "save" ? false : true });
  };
  // onUnload = (e) => {
  //   e.preventDefault();
  //   e.returnValue = "";
  // };
  componentDidMount() {
    // window.addEventListener('beforeunload', this.onUnload)
    if (window.location.search && window.location.search.length > 0) {
      let params = new URLSearchParams(window.location.search);
      localStorage.setItem("projectName", params.get("projectName"));
      this.setState({ header: params.get("projectName") });
    }
  }

  render() {
    return (
      <div>
        <MainHeader
          savefunc={this.handleSave}
          save={this.state.save}
          header={this.state.header}
        />
        <div style={{ padding: "0px 50px" }}>
          <Body savefunc={this.handleSave} />
        </div>
      </div>
    );
  }
}

// export default withStyles(styles)(withRouter(Home));
export default PlayHome;

/*
 * @authored by porkalacchiyam
 */

/**
 * @author Sri Rahul
 * @email rahul@qdmplatforms.com
 * @create date 2021-02-24 14:51:22
 * @modify date 2021-02-24 14:51:22
 * @desc [description]
 */

import React from "react";
import {
  makeStyles,
  List as MUList,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Popover,
  Button,
  Divider,
} from "@material-ui/core";
//  import AddIcon from "@material-ui/icons/Add";
//  import AvatarGroup from "@material-ui/lab/AvatarGroup";
import CheckIcon from "@material-ui/icons/Check";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
const useStyles = makeStyles((theme) => ({
  root: {},
  users: {
    // marginBottom: 4,
    display: "flex",
    alignItems: "center",
  },
  addBtn: {
    width: 25,
    height: 25,
    fontSize: 13,
    // zIndex:1,
    backgroundColor: "#bdbdbd",
    color: "#fff",
  },
  userList: {
    minWidth: 220,
    "& .MuiTextField-root": {
      padding: 10,
      "& .MuiInputLabel-formControl": {
        padding: 10,
      },
    },
    "& .MuiList-dense": {
      minHeight: 220,
      maxHeight: 220,
      overflow: "auto",
    },
  },
  addPeople: {
    color: "#7B7B84",
    fontSize: 12,
    fontWeight: 500,
  },
  saveBtn: {
    backgroundColor: "#E34D65",
    color: "#fff",
  },
  okBtnDiv: {
    textAlign: "center",
    padding: "0px 10px 10px",
    "& .MuiButton-contained": {
      "&:hover": {
        backgroundColor: "#E34D65 !important",
      },
    },
  },
  closeIcon: {
    position: "absolute",
    right: "9px",
    top: "6px",
  },
  selectIcon: {
    padding: "1px",
    borderRadius: "50%",
    boxShadow: "0px 0px 1px 1px",
  },
}));

export const UserList = (props) => {
  const classes = useStyles(props);
  const [userAdd, setUserAdd] = React.useState(props?.selectedUsers ?? []);

  const [anchorEl, setAnchorEl] = React.useState(
    props.anchorEl ? props.anchorEl : null
  );
  let list = [];
  if (props.userList) {
    list = JSON.parse(JSON.stringify(props.userList));
  }
  const [listUser, setListUser] = React.useState(list);
  //  const handleClick = (event) => {
  //    setAnchorEl(event.currentTarget);
  //  };
  React.useEffect(() => {
    setAnchorEl(props.anchorEl ? props.anchorEl : null);
    setListUser(props.userList);
    setUserAdd(props?.selectedUsers ?? []);
  }, [props.anchorEl]);
  const handleClose = (text) => {
    if (text !== "fromAddPeople") {
      setUserAdd(props?.selectedUsers ?? []);
    }
    setValue("");
    setAnchorEl(null);
    setListUser(props?.userList);
  };

  // console.log(props?.selectedUsers)

  const [value, setValue] = React.useState("");

  const onChangeInput = (e) => {
    const filteredUser = [];

    setValue(e.target.value);

    props?.userList &&
      props.userList.forEach((user) => {
        if (
          user.label.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
        ) {
          filteredUser.push(user);
        }
      });

    setListUser(filteredUser);
  };

  const open = anchorEl ? true : false;
  const id = open ? "simple-popover" : undefined;

  const addPeople = () => {
    handleClose("fromAddPeople");
    props.addUser(userAdd);
  };

  const selectPeople = (data) => {
    const check = userAdd && userAdd?.find((d) => d.value === data.value);
    if (check) {
      const filteredData = userAdd?.filter((_) => _.value !== data.value);
      setUserAdd([...filteredData]);
    } else {
      setUserAdd([...userAdd, data]);
    }
  };

  return (
    <div
      className={classes.root}
      style={{ marginLeft: "10px", marginTop: "10px" }}
    >
      {/* <Typography className={classes.addPeople}>ADD PEOPLE</Typography>       */}
      <div className={classes.users}>
        {/* {props?.selectedUsers && (
         <AvatarGroup className={"topnav-avatar"} max={4}>
           {props?.selectedUsers.map((user) => {
             return (
               <Avatar key={user.value}>{user.label.charAt(0)}</Avatar>
             );
           })}
         </AvatarGroup>
       )} */}
        {/* <IconButton
         aria-describedby={id}
         onClick={handleClick}
         className={classes.addBtn}
         style={{ marginLeft: props?.selectedUsers?.length > 0 ? "-10px" : 0 }}
       >
         <AddIcon fontSize="small" />
       </IconButton> */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={props.handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className={classes.userList}>
            <div style={{ fontSize: "15px", margin: "12px" }}>
              <span>Tag Entity</span>
              <HighlightOffIcon
                onClick={props.handleClose}
                className={classes.closeIcon}
              />
            </div>
            <Divider />
            <TextField
              id="standard-basic"
              label="Search for Entities"
              onChange={(e) => onChangeInput(e)}
              value={value ?? ""}
              name="value"
              variant="outlined"
              size="small"
            />

            <MUList dense>
              {listUser &&
                listUser.map((user) => {
                  return (
                    <ListItem
                      key={user.value}
                      dense
                      button
                      onClick={() => {
                        selectPeople(user);
                      }}
                    >
                      {/* <ListItemIcon className={classes.listItemIcon}>
                                 <Avatar alt={user.value} src={user?.user_pic && user?.user_pic}>
                                     {user.user_pic ?? user.label.charAt(0)}
                                 </Avatar>
                             </ListItemIcon> */}
                      <ListItemText primary={user.label} />

                      {userAdd &&
                      userAdd.find((su) => su.value === user.value) ? (
                        <ListItemIcon
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <CheckIcon
                            className={classes.selectIcon}
                            fontSize="small"
                            htmlColor="green"
                          />
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            className={classes.selectIcon}
                            style={{ padding: "11px" }}
                          ></div>
                          {/* <CheckIcon  style={{color:'white'}} fontSize="small" htmlColor="green" /> */}
                        </ListItemIcon>
                      )}
                    </ListItem>
                  );
                })}
            </MUList>
            <div className={classes.okBtnDiv}>
              <Button
                variant="contained"
                onClick={addPeople}
                style={{ width: "80%" }}
                className={classes.saveBtn}
              >
                Update
              </Button>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

import React from "react";
import {
  Typography,
  makeStyles,
  // Collapse,
  OutlinedInput,
  IconButton,
  Button,
  Popover,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import TabIcon from "../icons/tabIcon";
import Tick from "../icons/tick";
//import Comments from "../icons/comments";
import DeleteIcon from "../icons/delete";
//import PictureIcon from "../icons/picture";
import SettingsIcon from "@material-ui/icons/Settings";
import Properties from "../../screens/home/rightSection/components/properties";
import Function from "../../screens/home/rightSection/components/function";
import Document from "../../screens/home/rightSection/components/document";
import { withAllContexts } from "../../HOCs";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import moment from "moment";
import { v4 as uuid } from "uuid";
import environment from "../../config";
import axios from "axios";
import {
  digitalEndPointsOptions,
  uxChannelOptions,
  stepsSettings,
  propertiesList,
} from "../../utils";
// import {default as PopoverDrawer} from "./drawer";
const useStyles = makeStyles((theme) => ({
  // parentDiv: {
  //   height: "fit-content",
  //   width: 270,
  // },
  drawerPopup: {
    height: "100%",
    width: 300,
    "& .MuiPaper-elevation8": {
      boxShadow: "4px 6px 13px 0 rgb(17 15 71 / 6%)",
    },
  },
  deleteDiv: {
    height: "100%",
    // width: 300,

    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
  },
  // scroll: {
  //   "& .MuiPopover-paper": {
  //     "&::-webkit-scrollbar": {
  //       // width: "1px",
  //       display: "none",
  //     },
  //     "&::-webkit-scrollbar-track": {
  //       "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
  //     },
  //     "&::-webkit-scrollbar-thumb": {
  //       backgroundColor: "rgba(0,0,0,.1)",
  //     },
  //   },
  // },
  moreIcon: {
    fontSize: 20,
    color: "#9696a9",
  },
  inline: {
    fontSize: ".9rem",
    fontWeight: 600,
    color: "#9696A9",
    alignItems: "center",
    display: "flex",
  },
  moreIconbtn: {
    padding: "12px",
  },
  inputFieldChipsDiv: {
    marginLeft: "4%",
    marginBottom: "1%",
  },
  inputFieldChips: {
    fontFamily: "fontRegular",
    fontSize: "11px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    padding: "4px 10px",
    backgroundColor: "white",
    border: "1px solid rgba(17, 15, 71, 0.08)",
    marginRight: "1%",
    borderRadius: 4,
    color: "#545465",

    marginBottom: "2%",
    "&:focus": {
      border: "1px solid rgba(17, 15, 71, 0.08)",
      outline: "none",
    },
    "&:active": {
      border: "1px solid rgba(17, 15, 71, 0.08)",
    },
    "&:hover": {
      border: "1px solid rgba(17, 15, 71, 0.08)",
    },
  },
  inputselect: {
    fontFamily: "fontRegular",
    fontSize: "12px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    padding: "4px 10px",
    backgroundColor: "aliceblue",
    border: "1px solid rgba(17, 15, 71, 0.08)",
    marginRight: "1%",
    borderRadius: 4,
    color: "#545465",
    marginBottom: "2%",
  },
  stepChild: (props) => ({
    maxWidth: 266,
    minWidth: 266,
    position: "relative",
    borderRadius: 8,
    marginRight: "2%",
    backgroundColor: "#f5f5f582",
    padding: "10px 0px 1px",
    minHeight: "50px",
    overFlow: "hidden",
    "& .MuiOutlinedInput-multiline": {
      padding: "12px",
      // width: 230,
      flex: 1,
    },
    "& textarea": {
      border: `2px solid ${props?.borderColor}`,
      borderRadius: 4,
      padding: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black",
    },
    "&:hover": {
      boxShadow: "black",
      // boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px",
    },
  }),

  round1: {
    height: 6,
    width: 6,
    borderRadius: "50%",

    position: "absolute",
    left: "265px",
    top: "22px",
  },
  ListItemTitle: {
    paddingLeft: 6,
    borderBottom: "1px solid #d2d2d2",
  },
  checkboxList: {
    userSelect: "none",
    justifyContent: "space-between",
    marginLeft: 0,
    height: 36,
    "& .MuiIconButton-label": {
      padding: 6,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      color: "#110f47",
      fontFamily: "fontRegular",
      textTransform: "none",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
  },
  checkhead: {
    fontSize: 12,
    marginLeft: "2px",
    color: "#9696a9",
    fontFamily: "fontRegular",
    textTransform: "none",
    fontWeight: "800",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  checklistselect: {
    userSelect: "none",
    justifyContent: "space-between",
    marginLeft: 0,
    height: 36,
    "& .MuiIconButton-label": {
      padding: 6,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      color: "#007aff",
      fontFamily: "fontSemiBold",
      textTransform: "none",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
  },
  collapseDiv: {
    "& .MuiListItem-gutters": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  kpiPopup: {
    padding: "2px 4px",
  },
  flag: {
    transform: "rotate(179deg)",
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderRight: "0px solid transparent",
    position: "absolute",
    left: -2,
    top: -2,
  },
  flagss: {
    transform: "rotate(179deg)",
    display: "none",
    position: "absolute",
    right: -2,
    top: -2,
  },
  focus: {
    "& textarea": {
      padding: 4,
      borderRadius: 4,
      border: (props) => `2px solid ${props.borderColor}`,
    },
  },
  focusdisabled: {
    "& textarea": {
      padding: 4,
      borderRadius: 4,
      border: "none !important",
      backgroundColor: "#f5f5f582 !important",
    },
  },
  iconButton: {
    textTransform: "capitalize",
    display: "flex",
    width: "100%",
    textAlign: "left",
    alignItems: "center",
    "& span": {
      color: "rgba(0, 0, 0, 0.54)",
      flex: 1,
    },
  },
  inputName: {
    flex: 1,
    padding: 12,
    cursor: "auto",
    wordBreak: "break-word",
  },
  flexStyle: {
    display: "flex",
    alignItems: "center",
  },
}));

function Input(props) {
  const {
    selectedarrIndex,
    parentid,
    personaIndex,
    levelIndex,
    subllevel,
    Borderindex,
  } = props;

  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorDel, setAnchorDel] = React.useState(null);
  const [inputPopover, setinputPopover] = React.useState(null);
  // const { Data, setScenes } = props.datajson;
  const { Data, setScenes } = props.scenes;

  const divRef = React.useRef();

  const [expanded] = React.useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorDel(null);
  };
  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const funCallOnFoucsInput = (e) => {
    setinputPopover(e.currentTarget);
  };
  const handleCloseInputPop = (e) => {
    setinputPopover(null);
  };

  const [propstep, setpropstep] = React.useState("");

  const MorePop = (e, steps) => {
    setAnchorDel(e.currentTarget);
    setpropstep(steps);
  };

  const open = Boolean(anchorEl);
  const id = open ? "list-popover" : undefined;

  const open1 = Boolean(anchorEl1);
  const id1 = open ? "list-popover" : undefined;

  const open2 = Boolean(anchorDel);
  const id2 = open2 ? "Delete" : undefined;

  const inputopen = Boolean(inputPopover);
  const inputid = inputopen ? "input-popover" : undefined;

  // onenter subpropText
  const keypresssubprop = (item, value) => {
    const newProp = {
      id: uuid(),
      value,
    };

    const findProperty = props?.step?.properties?.find(
      (_) => _.value === item?.value
    );
    if (findProperty?.value) {
      findProperty.props.push(newProp);
    } else {
      props.step.properties.push({
        value: item?.value,
        props: [newProp],
      });
    }

    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  // delete Subprop
  const DeletesubProp = (item, val) => {
    const filteredProperty = props?.step?.properties
      ?.find((_) => _?.value === item?.value)
      ?.props?.filter((_) => _.id !== val.id);
    props.step.properties.find((_) => _?.value === item?.value).props =
      filteredProperty;
    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  const keypresseditsubprop = (item, value, index) => {
    const findProperty = props?.step?.properties?.find(
      (_) => _?.value === item?.value
    )?.props?.[index];
    if (findProperty?.id) {
      findProperty.value = value;
      setScenes({ ...props.scenes, Data });
      props.savefunc();
    }
  };

  const keypressfun = (name) => {
    const newFunction = {
      id: uuid(),
      name,
      subFunctions: [],
    };
    props.step.function.push(newFunction);

    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  const deleteFunction = (item) => {
    const filteredFunction = props?.step?.function?.filter(
      (_) => _?.id !== item?.id
    );
    props.step.function = filteredFunction;
    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  const handleKeyPressEditFunction = (val, index) => {
    props.step.function[index].name = val;
    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  const keypresssubfun = (name, funcIndex) => {
    const newSubFunction = {
      id: uuid(),
      name,
    };
    props.step.function[funcIndex].subFunctions.push(newSubFunction);
    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  // delete sub func
  const Deletesubfun = (functionIndex, item) => {
    const filteredSubFunction = props?.step?.function?.[
      functionIndex
    ]?.subFunctions?.filter((_) => _?.id !== item?.id);
    props.step.function[functionIndex].subFunctions = filteredSubFunction;
    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  const keypresseditsubfun = (funcIndex, subFunctionIndex, val) => {
    props.step.function[funcIndex].subFunctions[subFunctionIndex].name = val;
    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  // hover for connect lines

  const [show, setShow] = React.useState(false);

  const styles = {
    border: {
      border:
        !props.steps && show
          ? ".125rem solid " + props.borderColor
          : ".125rem solid " + props.borderColor,
    },
  };

  const [openDrawerPopup, setOpenDrawerPopup] = React.useState(null);
  const [activemenu, setActivemenu] = React.useState("");

  const open3 = Boolean(openDrawerPopup);
  const id3 = open3 ? "openDrawerPopup" : undefined;

  const toggleDrawerPop = (handleClose = false, anchor = null, type = "") => {
    if (handleClose) {
      handleClose2();
    }
    setActivemenu(type);
    setOpenDrawerPopup(anchor);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    const data = {
      name: file?.name,
      lastModified: moment().format("DD/MM/YYYY hh:mm a"),
      load: true,
    };

    let files = new FormData();
    files.append("files", file);

    if (props?.step?.document?.some((_) => _?.name === data?.name)) {
      alert("File already uploaded");
    } else {
      await axios.post(`${environment.uploadUrl}`, files).then((response) => {
        data.fileId = response.data.fileid;
        if (response.data.status === "200") {
          props.step.document.push(data);
        }
      });
    }
    setScenes({ ...props.scenes, Data });
    props.savefunc();

    // SaveSchema(Data);
  };

  const SaveSchema = async (Dat) => {
    // props.savefunc("save");
    let dataList = {
      db_name: "QDM_Metadata",
      entity: "QPAS",
      is_metadata: false,
      projectname: "CRAYOND3",
      doc: {
        metadataId: localStorage.metadataId ? localStorage.metadataId : uuid(),
        qpasid: localStorage.qpasid ? localStorage.qpasid : uuid(),
        projectname: "CRAYOND3",
        attributes: Dat,
      },
    };
    if (localStorage.qpasid) {
      dataList.filter = { qpasid: dataList.qpasid };
    }
    let data = JSON.stringify([dataList]);

    var config = {
      method: "post",
      url: `${environment.api_url}/api/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios;
    axios(config)
      .then((response) => {
        localStorage.setItem(
          "qpasid",
          response.data.Result[0].properties.doc.qpasid
        );
        localStorage.setItem(
          "metadataId",
          response.data.Result[0].properties.doc.metadataId
        );
        props.savefunc();

        // setLoad(false);
        // setOpenAlert(true);
      })
      .catch((err) => {
        // setLoad(false);
        // alert("something went wrong!");
      });
    setScenes({ ...props.datajson, Data });
  };

  const DeleteDocument = (obj, i) => {
    const filteredDoc = props?.step?.document?.filter(
      (_) => _?.name !== obj?.name
    );
    props.step.document = filteredDoc;
    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  return (
    <div
      className={classes.stepChild}
      style={styles.border}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
    >
      {/* {!props.steps && show && (
        <div
          className={classes.round1}
          style={{
            border: ".225rem solid " + props.borderColor,
          }}
        ></div>
      )} */}
      <Popover
        style={{ marginLeft: 124, marginTop: -14 }}
        id={inputid}
        open={inputopen}
        anchorEl={inputPopover}
        onClose={handleCloseInputPop}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Grid container alignItems="center" className={classes.kpiPopup}>
          <IconButton aria-label="delete">
            <input
              type="color"
              style={{
                backgroundColor: "#fff",
                width: 26,
                height: 26,
                border: "none",
                padding: 0,
                outline: "none",
              }}
              id="favcolor"
              name="favcolor"
              value={props.borderColor}
              onChange={(e) => props.colorChange(e, props.Borderindex)}
            />
          </IconButton>
          {/* <IconButton aria-label="delete">
            <Comments style={{ color: "#545465" }} />
          </IconButton> */}
          {/* delete step and action */}
          {/* <IconButton
            aria-label="delete"
            onClick={() => {
              props.handleDelete(props.index);
              handleCloseInputPop();
            }}
          >
            <DeleteIcon />
          </IconButton> */}
          {/* <IconButton aria-label="delete">
            <PictureIcon />
          </IconButton> */}
          {/* <Divider orientation="vertical" flexItem />
          <Button>+ Add KPI</Button> */}
        </Grid>
      </Popover>
      <div className={classes.flexStyle}>
        {props?.editText?.id ===
        (props?.steps ? props?.step?.stepid : props?.level?.id) ? (
          <OutlinedInput
            onKeyDown={(e) => props.onkeypressDown(e)}
            onBlur={(e) => props.onBlurstep(e)}
            multiline
            type={"text"}
            placeholder={props.steps ? "Untitled Function" : "Untitled Action"}
            value={props?.editText?.name}
            autoFocus={true}
            onChange={props.onChange}
          />
        ) : (
          <Typography
            variant="body1"
            className={classes.inputName}
            style={{
              color: props?.value ? "#000" : "#adadad",
            }}
            onDoubleClick={() => {
              if (props.steps) {
                props.doubleClick(props?.step?.stepid, props?.value);
              } else {
                props.doubleClick(props?.level?.id, props?.value);
              }
            }}
          >
            {props?.value
              ? props?.value
              : `Untitled ${props?.steps ? "Function" : "Action"}`}
          </Typography>
        )}

        {/* {props.steps ? (
        <IconButton
          className={classes.moreIconbtn}
          aria-label="toggle visibility"
          edge="end"
          onClick={(e) => MorePop(e, props.steps)}
        >
          <MoreVertIcon className={classes.moreIcon} />
        </IconButton>
      ) : ( */}
        <IconButton
          className={classes.moreIconbtn}
          aria-label="toggle visibility"
          // edge="end"
          ref={divRef}
          onClick={(e) => MorePop(e, props.steps)}
        >
          <SettingsIcon className={classes.moreIcon} />
        </IconButton>
      </div>
      {/* )} */}

      <span
        onClick={(e) => funCallOnFoucsInput(e)}
        className={classes.flag}
        style={{ borderBottom: "20px solid " + props.borderColor }}
      />
      {/* {show && ( */}
      <span className={classes.flagss} style={{ display: show && "block" }}>
        <FlipToFrontIcon
          style={{
            padding: "0px 8px 1px",
            fontSize: 19,
            color: "#909090",
          }}
        />
      </span>
      {/* )} */}

      {/* {props.data?.chips?.length > 0 ? (
        <div className={classes.inputFieldChipsDiv}>
          {props.data.chips?.map((item, index) => {
            return (
              <Button className={classes.inputFieldChips} variant="outlined">
                {item}
              </Button>
            );
          })}
        </div>
      ) : null} */}
      {props.steps ? (
        <div style={{ textAlign: "center" }}>
          <Button
            className={
              props?.digitalEndPoint?.length > 0
                ? classes.inputselect
                : classes.inputFieldChips
            }
            onClick={handleClick}
            variant="outlined"
          >
            {props?.digitalEndPoint?.length > 1
              ? `${props?.digitalEndPoint[0]?.label} +${
                  props?.digitalEndPoint?.length - 1
                }`
              : props?.digitalEndPoint[0]?.label}

            {/* {props.data.chips?.map((item, index) => {
              return <>{(index ? ", " : "") + item}&nbsp;</>;
            })} */}
            {props?.digitalEndPoint?.length > 0 ? "" : "+ Digital end point"}
          </Button>
          <Button
            className={
              props?.uxChannel?.length > 0
                ? classes.inputselect
                : classes.inputFieldChips
            }
            onClick={handleClick1}
            variant="outlined"
          >
            {props?.uxChannel?.length > 1
              ? `${props?.uxChannel[0]?.label} +${props?.uxChannel?.length - 1}`
              : props?.uxChannel[0]?.label}

            {props?.uxChannel?.length > 0 ? "" : "+ UX Channel"}
          </Button>
        </div>
      ) : (
        " "
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.parentDiv}>
          <List
            component="nav"
            style={{ padding: "0px" }}
            aria-labelledby="nested-list-subheader"
          >
            <ListItem
              button
              // onClick={handleExpandClick}
              className={classes.ListItemTitle}
            >
              {expanded ? (
                <ArrowDropDownIcon style={{ fontSize: 24, marginRight: 4 }} />
              ) : (
                <ArrowRightIcon style={{ fontSize: 20, marginRight: 4 }} />
              )}
              <span>
                <TabIcon />
              </span>
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography component="span" className={classes.checkhead}>
                      &nbsp;DIGITAL END POINTS
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            {/* <Collapse
              in={expanded}
              timeout="auto"
              unmountOnExit
              className={classes.collapseDiv}
            > */}
            <List component="div" disablePadding>
              <ListItem alignItems="flex-start">
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        <FormGroup aria-label="position">
                          {digitalEndPointsOptions &&
                            digitalEndPointsOptions?.map((_) => {
                              return (
                                <FormControlLabel
                                  className={
                                    props?.digitalEndPoint?.some(
                                      (l) => l?.value === _?.value
                                    )
                                      ? classes.checklistselect
                                      : classes.checkboxList
                                  }
                                  value={_?.value}
                                  control={
                                    <Checkbox
                                      style={{ color: "#fff" }}
                                      checked={props?.digitalEndPoint?.some(
                                        (l) => l?.value === _?.value
                                      )}
                                      checkedIcon={
                                        <Tick
                                          style={{
                                            padding: 6,
                                            fontSize: 12,
                                          }}
                                        />
                                      }
                                      name={_?.label}
                                      onChange={(e) =>
                                        props.checkboxValue(
                                          _,
                                          "digitalEndPoint"
                                        )
                                      }
                                    />
                                  }
                                  label={_?.label}
                                  labelPlacement="start"
                                />
                              );
                            })}
                        </FormGroup>
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
            {/* </Collapse> */}
          </List>
        </div>
      </Popover>
      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List
          component="nav"
          style={{ padding: "0px" }}
          aria-labelledby="nested-list-subheader"
        >
          <ListItem
            button
            // onClick={handleExpandClick}
            className={classes.ListItemTitle}
          >
            {expanded ? (
              <ArrowDropDownIcon style={{ fontSize: 24, marginRight: 4 }} />
            ) : (
              <ArrowRightIcon style={{ fontSize: 20, marginRight: 4 }} />
            )}
            <span>
              <TabIcon />
            </span>
            <ListItemText
              secondary={
                <React.Fragment>
                  <Typography className={classes.checkhead}>
                    &nbsp; UX CHANNEL
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          {/* <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            className={classes.collapseDiv}
          > */}
          <List component="div" disablePadding>
            <ListItem alignItems="flex-start">
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      <FormGroup aria-label="position">
                        {uxChannelOptions &&
                          uxChannelOptions?.map((_) => {
                            return (
                              <FormControlLabel
                                className={
                                  props?.uxChannel?.some(
                                    (l) => l?.value === _?.value
                                  )
                                    ? classes.checklistselect
                                    : classes.checkboxList
                                }
                                value={_?.value}
                                control={
                                  <Checkbox
                                    style={{ color: "#fff" }}
                                    checked={props?.uxChannel?.some(
                                      (l) => l?.value === _?.value
                                    )}
                                    checkedIcon={
                                      <Tick
                                        style={{
                                          padding: 6,
                                          fontSize: 12,
                                        }}
                                      />
                                    }
                                    name={_?.label}
                                    onChange={(e) =>
                                      props.checkboxValue(_, "uxChannel")
                                    }
                                  />
                                }
                                label={_?.label}
                                labelPlacement="start"
                              />
                            );
                          })}
                      </FormGroup>
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
          {/* </Collapse> */}
        </List>
      </Popover>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorDel}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={classes.scroll}
      >
        <div className={propstep ? "" : classes.deleteDiv}>
          {propstep ? (
            <div>
              {stepsSettings?.map((_) => {
                return (
                  <div key={_?.value}>
                    <Button
                      aria-label={_?.value}
                      onClick={() => {
                        if (_?.value === "Delete") {
                          props.handleDelete();
                          handleClose2();
                        } else {
                          toggleDrawerPop(true, divRef.current, _?.value);
                        }
                      }}
                      className={classes.iconButton}
                    >
                      {_?.icon && _?.icon}
                      <span style={{ fontSize: "15px" }}>
                        &nbsp;&nbsp;{_?.label}
                      </span>
                    </Button>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  props.handleDelete();
                  handleClose2();
                }}
              >
                <DeleteIcon />
                <span style={{ fontSize: "15px" }}>&nbsp;&nbsp;Delete</span>
              </IconButton>
            </div>
          )}
        </div>
      </Popover>
      <Popover
        id={id3}
        open={open3}
        anchorEl={openDrawerPopup}
        onClose={() => toggleDrawerPop(false, null, "")}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.drawerPopup}>
          {(() => {
            switch (activemenu) {
              case "Properties":
                return (
                  <Properties
                    closeHandle={() => toggleDrawerPop(false, null, "")}
                    head="Action"
                    properties={propertiesList}
                    value={props?.step?.properties}
                    keypresssubprop={keypresssubprop}
                    keypresseditsubprop={keypresseditsubprop}
                    DeletesubProp={DeletesubProp}
                  />
                );
              case "Function":
                return (
                  <Function
                    function={props?.step?.function}
                    keypressfun={keypressfun}
                    deleteFunction={deleteFunction}
                    handleKeyPressEditFunction={handleKeyPressEditFunction}
                    keypresssubfun={keypresssubfun}
                    Deletesubfun={Deletesubfun}
                    keypresseditsubfun={keypresseditsubfun}
                  />
                );
              case "Document":
                return (
                  <Document
                    closeHandle={() => toggleDrawerPop(false, null, "")}
                    handleUpload={handleUpload}
                    uploaddocc={props?.step?.document}
                    DeleteDocument={DeleteDocument}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      </Popover>
    </div>
  );
}

export default withAllContexts(Input);

import React from "react";

export default function Body(props) {

  let width = props.width ? props.width : "14";
  let height = props.height ? props.height : "16";
  let color = props.color ? props.color : "#545465";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 16"
    >
      <g fill="none">
        <g fill={color}>
          <g>
            <path
              d="M22.639 15c-.189.007-.368.08-.504.208l-5.898 5.5c-.227.212-.299.537-.182.821.117.284.4.47.714.47h11.795c.314 0 .597-.186.714-.47.117-.284.045-.61-.182-.821l-5.897-5.5c-.15-.14-.353-.215-.56-.207zm.028 1.788l3.98 3.712h-7.96l3.98-3.712zM16.769 24c-.314 0-.597.186-.714.47-.117.285-.045.61.182.822l5.898 5.5c.297.277.766.277 1.064 0l5.897-5.5c.227-.212.299-.537.182-.822-.117-.284-.4-.47-.714-.47H16.769zm1.917 1.5h7.961l-3.98 3.712-3.98-3.712z"
              transform="translate(-1388 -406) translate(1372 391)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

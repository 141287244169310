import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
//import Chip from "@material-ui/core/Chip";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
// import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import DeleteIcon from "../../../components/icons/delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { withAllContexts } from "../../../HOCs";
import axios from "axios";
import { uuid } from "uuidv4";
import { PopoverComponent } from "../../../components/popover";
import environment from "../../../config";
import { v4 as uuidv4 } from "uuid";
import routes from "../../../router/routes";
import {
  checkError,
  ConstructUpsert,
  fetchData,
  SaveSchema,
} from "../../../components/functions/commonapi";
// import AddIcon from "@material-ui/icons/Add";

// CreateAct styles

const CreateStyles = makeStyles((theme) => ({
  root: {
    width: "260px",
    // width: "100%",
    boxShadow: "none",
    border: "1.5px solid #f2b23a",
    borderRadius: "6px",
    overflow: "hidden",
    "& .MuiCardHeader-content": {
      height: "70px",
    },
  },
  Chip: {
    padding: "4px 0px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    fontSize: "13px",
    // fontWeight: 600,
    fontStretch: "normal",
    lineHeight: "normal",
    marginTop: "auto",
    marginBottom: "auto",
    letterSpacing: "0.5px",
    color: "#545465",
    border: "1px solid rgba(17, 15, 71, 0.08)",
  },
  avatar: {
    backgroundColor: "#fff",
    color: "#545465 ",
    border: "1px solid rgba(17, 15, 71, 0.08)",
    // marginBottom: "30px",
    width: "30px",
    height: "30px",
  },
  textavatar: {
    backgroundColor: "#fff",
    color: "#545465 ",
    border: "1px solid rgba(17, 15, 71, 0.08)",
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },
  header: {
    color: "#110f47",
    fontSize: "15px",
    fontWeight: 400,
    fontStretch: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    cursor: "pointer",
    overflowWrap: "anywhere",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  chipdiv: {
    display: "flex",
    justifyContent: "center",
  },
  action: {
    padding: 14,
    display: "flex",
    justifyContent: "center",
    color: "#110f47",
    fontSize: "15px",
    fontFamily: "fontSemiBold",
    fontWeight: "bold",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    borderTop: "1px solid #f2b23a",
    cursor: "pointer",
  },
  textfieldDiv: {
    paddingBottom: "16px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#7070703D",
      },
      "&:hover fieldset": {
        borderColor: "#7070703D",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#27A2FF",
      },
    },
  },
  textfieldDivcard: {
    color: "#110f47",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    // paddingBottom: "16px",
    "& .MuiOutlinedInput-multiline": {
      padding: "0px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        borderColor: "#7070703D",
      },
      "&:hover fieldset": {
        borderColor: "#7070703D",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#27A2FF",
      },
    },
  },

  actDiv: {
    display: "flex",
    flexWrap: "wrap",
  },
  act: {
    color: "#e34d65",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.5px",
    cursor: "pointer",
  },
  deleteDiv: {
    height: "fit-content",
    width: 100,
    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
  },
  Tree_loading_parent: {
    height: "100%",
    display: "flex",
  },
  Tree_loading: {
    margin: "auto",
  },
  Tree_loading_icon: {
    marginRight: "10px",
  },
  input: {
    "& textarea": {
      // fontWeight: 500,
    },
  },
  more: {
    padding: "0px",
  },
}));
// main act component

const Acts = (props) => {
  const classes = CreateStyles();

  const { Data, setStories } = props.stories;
  const { allkpi } = props.kpi;

  const [editAct, setEditAct] = React.useState({
    id: "",
    name: "",
  });

  const [load, setLoad] = React.useState(false);
  const [values, setValues] = React.useState("");
  const [anchorDel, setAnchorDel] = React.useState(null);

  const [deleteIndex, setDeleteIndex] = React.useState(null);

  const [newAct, setNewAct] = React.useState(false);
  //const [answerType2, setAnswerType2] = React.useState(null);
  //   ClickScene router push
  // const Clickscence = async (act) => {
  //   // setLoad(true);
  //   let overAllData = ConstructUpsert(Data);
  //   var config = {
  //     method: "post",
  //     url: `${environment.api_url}/api/upsert_document`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: overAllData,
  //   };

  //   await axios;
  //   axios(config)
  //     .then((response) => {
  //       localStorage.setItem(
  //         "qpasid",
  //         response.data.Result[0].properties.doc.qpasid
  //       );
  //       localStorage.setItem(
  //         "metadataId",
  //         response.data.Result[0].properties.doc.metadataId
  //       );
  //       // debugger;
  //       props.history.push({
  //         pathname: routes.addscene,
  //         state: {
  //           act,
  //           play: props?.play,
  //         },
  //       });
  //     })
  //     .catch((err) => {
  //       alert("something went wrong!");
  //     });

  //   // SaveSchema(id, value);
  // };

  const Clickscence = async (act) => {
    let overAllData = ConstructUpsert(Data);
    try {
      const data = await fetchData(
        { body: JSON.stringify(overAllData) },
        `${environment.api_url}/api/upsert_document`
      );
      const { isError, errMsg } = checkError(data);
      if (isError) {
        alert(errMsg);
      } else {
        localStorage.setItem("qpasid", data.Result[0].properties.doc.qpasid);
        props.history.push({
          pathname: routes.addscene,
          state: {
            act,
            play: props?.play,
          },
        });
      }
    } catch (error) {
      alert("error");
    }
  };

  // const SaveSchema = async (id, value) => {
  //   debugger;
  //   let dataList = {
  //     db_name: "QDM_Metadata",
  //     entity: "QPAS",
  //     is_metadata: false,
  //     projectname: "test",
  //     doc: {
  //       metadataId: localStorage.metadataId ? localStorage.metadataId : uuid(),
  //       qpasid: localStorage.qpasid ? localStorage.qpasid : uuid(),
  //       projectname: "CRAYOND3",
  //       attributes: Data,
  //     },
  //   };
  //   if (localStorage.qpasid) {
  //     dataList.filter = { qpasid: dataList.doc.qpasid };
  //   }
  //   let data = JSON.stringify([dataList]);

  //   var config = {
  //     method: "post",
  //     url: `${environment.api_url}/api/upsert_document`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   await axios(config)
  //     .then((response) => {
  //       localStorage.setItem(
  //         "qpasid",
  //         response.data.Result[0].properties.doc.qpasid
  //       );
  //       localStorage.setItem(
  //         "metadataId",
  //         response.data.Result[0].properties.doc.metadataId
  //       );
  //       setLoad(false);
  //       const { story_id, story_title, play_id, play_title } = props;
  //       debugger;
  //       props.history.push(
  //         `/addscene/${value.replace(/\//g, "===")}/${id}/${play_title.replace(
  //           /\//g,
  //           "==="
  //         )}/${play_id}/${story_title.replace(/\//g, "===")}/${story_id}`
  //       );

  //       // window.location.href = `/addscene/${value.replace(
  //       //   /\//g,
  //       //   "==="
  //       // )}/${id}/${play_title.replace(
  //       //   /\//g,
  //       //   "==="
  //       // )}/${play_id}/${story_title.replace(/\//g, "===")}/${story_id}`;

  //       // window.location.href changed to history.push
  //       // props.history.push(
  //       //   `/addscene/${value}/${id}/${play_title}/${play_id}/${story_title}/${story_id}`
  //       // );
  //     })
  //     .catch((err) => {
  //       debugger;
  //       setLoad(false);
  //       alert("something went wrong");
  //     });
  // };

  // editing the text in card
  const handleEdit = (act) => {
    setEditAct({
      id: act?.actid,
      name: act?.actname,
    });
  };

  // after edit keypress the changed value
  const onKeyeditPress = (actIndex) => {
    const { storyIndex, playIndex } = props;

    if (editAct?.name?.trim() === "") {
      props.setDuplicate(true);
      props.setDupmsg("Textfield should not be empty!");
    } else {
      const checkPlayName = Data?.[storyIndex]?.plays?.[playIndex]?.acts?.some(
        (_) =>
          _?.actname?.replace(/\n/g, "")?.toLowerCase()?.trim() ===
            editAct?.name?.replace(/\n/g, "")?.toLowerCase()?.trim() &&
          _?.actid !== editAct?.id &&
          _?.activestatus
      );
      if (checkPlayName) {
        props.setDuplicate(true);
        props.setDupmsg("The scene already exists in this act!");
      } else {
        Data[storyIndex].plays[playIndex].acts[actIndex].actname =
          editAct?.name;
        setStories({ ...props.stories, Data });
        setEditAct({
          id: "",
          name: "",
        });
      }
    }
  };

  const keypressAct = (e, actIndex) => {
    if (e.keyCode === 27) {
      setEditAct({
        id: "",
        name: "",
      });
    } else if (e.keyCode === 13) {
      onKeyeditPress(actIndex);
    }
  };

  const blurAct = () => {
    setValues("");
    setNewAct(false);
  };

  const onKeyDownAct = (e) => {
    if (e.keyCode === 27) {
      blurAct();
    } else if (e.keyCode === 13) {
      props.onKeyPressact(values, blurAct);
    }
  };

  const open2 = Boolean(anchorDel);
  const id2 = open2 ? "Delete" : undefined;

  const MorePop = (e, index) => {
    e.stopPropagation();
    setDeleteIndex(index);
    setAnchorDel(e.currentTarget);
  };

  const actBlurEvent = (e) => {
    if (!e.relatedTarget) {
      blurAct();
    }
  };

  const handleClose2 = () => {
    setAnchorDel(null);
  };
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {load && (
        <div
          className={classes.Tree_loading_parent}
          style={{ height: "calc(80vh)" }}
        >
          <div className={classes.Tree_loading}></div>
        </div>
      )}

      {props?.acts?.map((act, k) => {
        if (act.activestatus) {
          return (
            <div style={{ paddingBottom: 10, paddingRight: 10 }} key={k}>
              <Card className={classes.root}>
                <CardHeader
                  style={{ overflow: "hidden" }}
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      <CalendarTodayIcon style={{ fontSize: "initial" }} />
                    </Avatar>
                  }
                  title={
                    editAct?.id === act.actid ? (
                      <div className={classes.textfieldDivcard}>
                        <TextField
                          className={classes.input}
                          autoComplete="off"
                          multiline
                          rowsMax={3}
                          autoFocus={true}
                          value={editAct?.name}
                          id={act.actid}
                          onChange={(event) =>
                            setEditAct({
                              ...editAct,
                              name: event.target.value,
                            })
                          }
                          placeholder="Untitled Act"
                          onKeyDown={(e) => keypressAct(e, k)}
                          onBlur={() => {
                            setEditAct({
                              id: "",
                              name: "",
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <Tooltip title={act.actname}>
                        <Typography
                          onDoubleClick={() => handleEdit(act)}
                          id={act.actid}
                          className={classes.header}
                        >
                          {act.actname}
                        </Typography>
                      </Tooltip>
                    )
                  }
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={(e) => MorePop(e, k)}
                      onFocus={(event) => event.stopPropagation()}
                      className={classes.more}
                    >
                      <MoreVertIcon style={{ color: "#b1a7a7bd" }} />
                    </IconButton>
                  }
                />

                <CardContent>
                  <div className={classes.chipdiv}>
                    {/* <Chip
                    variant="outlined"
                    className={classes.Chip}
                    label={
                      <span
                        style={{
                          fontSize: 11,
                        }}
                      >
                        + Strategic KPI
                      </span>
                    }
                  /> */}
                    <PopoverComponent
                      data={allkpi}
                      selectedValue={act.actkpi}
                      label="+ Strategic KPI"
                      onChange={(e) =>
                        props.onChangeAct(
                          e,
                          props?.playIndex,
                          props.storyIndex,
                          k
                        )
                      }
                    />
                  </div>
                </CardContent>

                <CardActions className={classes.action}>
                  <div
                    onClick={() => Clickscence(act)}
                    style={{ fontSize: 13 }}
                  >
                    View / Create Scene
                  </div>
                </CardActions>
              </Card>
            </div>
          );
        } else {
          return false;
        }
      })}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {newAct ? (
          <div className={classes.textfieldDiv}>
            <TextField
              autoComplete="off"
              className={classes.input}
              style={{ width: "max-content" }}
              multiline
              value={values}
              id={"input" + props.id}
              onChange={(e) => setValues(e.target.value)}
              onKeyDown={(e) => onKeyDownAct(e)}
              autoFocus={true}
              onBlur={(e) => actBlurEvent(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Avatar
                      aria-label="recipe"
                      sizes="5"
                      className={classes.textavatar}
                    >
                      <CalendarTodayIcon style={{ fontSize: "initial" }} />
                    </Avatar>
                  </InputAdornment>
                ),
              }}
              placeholder="Untitled Act"
              variant="outlined"
            />
            <IconButton onClick={blurAct}>
              <DeleteOutlineIcon />
            </IconButton>
          </div>
        ) : (
          <Grid>
            <div className={classes.actDiv} style={{ marginTop: 12 }}>
              <div onClick={() => setNewAct(true)} className={classes.act}>
                + New Act
              </div>
            </div>
          </Grid>
        )}
      </div>

      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorDel}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.deleteDiv}>
          <List component="nav" style={{ padding: 0 }}>
            <ListItem
              button
              onClick={() => {
                handleClose2();
                props.handleDeleteAct(deleteIndex);
              }}
            >
              <DeleteIcon />
              &nbsp;
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                      style={{ fontWeight: 500 }}
                    >
                      Delete
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Popover>
    </div>
  );
};
export default withAllContexts(withRouter(Acts));

/*
 * @authored by porkalacchiyam
 */

import React from "react";
import {
  makeStyles,
  Popover,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
} from "@material-ui/core";

import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
  card: {
    maxWidth: 345,
    "& .MuiCardActions-root": {
      justifyContent: "center",
      "& .MuiButton-contained": {
        "&:hover": {
          backgroundColor: "#E34D65 !important",
          color: "#fff",
        },
      },
    },
  },
  saveBtn: {
    backgroundColor: "#E34D65",
    color: "#fff",
  },
  cancelBtn: {
    backgroundColor: "#fff",
  },
}));

export const PopoverConformation = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    props.persona && props.PersonaUse();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    props.persona && props?.UseFalse && props.UseFalse();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const Delete = () => {
    props.Deletefun();
    handleClose();
  };
  const CancelDelete = () => {
    handleClose();
  };
  return (
    <div className={classes.root}>
      <Button aria-describedby={id} onClick={handleClick}>
        {props.icon}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card className={classes.card}>
          <CardActionArea>
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.msg}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button
              className={classes.cancelBtn}
              variant="contained"
              size="small"
              onClick={() => CancelDelete()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              className={classes.saveBtn}
              onClick={() => Delete()}
            >
              Yes
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
};

PopoverConformation.propTypes = {
  component: PropTypes.element,
  icon: PropTypes.element,
};

// PopoverComponent.defaultProps = {
//   label: "Select",
// };

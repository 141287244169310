import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import Icon from "./Icon";
import Medialibrary from "./Medialibrary";
import Personas from "./Personas";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Flower from "../../../components/icons/flower";
import Persona from "../../../components/icons/persona";
import Media from "../../../components/icons/media";
import Open from "../../../components/icons/open";
import Close from "../../../components/icons/close";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import Iconpop from "./Popover/Iconpop";
import Personapop from "./Popover/Personapop";
import Mediapop from "./Popover/Mediapop";
// import DataJson from "../../../data.json";
import { withAllContexts } from "../../../HOCs";
import { v4 as uuidv4 } from "uuid";
// import {
//   getEnterpriceModuler,
//   SaveEnterPriseModuler,
//   SaveSchema,
// } from "../../../components/functions/commonapi";

const drawerWidth = 210;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "10px",
    // padding: "10px 6px",
  },
  menuButtondivide: {
    marginLeft: "14px",
    marginRight: "14px",
    marginBottom: "14px",
    marginTop: "11px",
  },
  menuButtondividetwo: {
    marginLeft: "14px",
    marginRight: "14px",
    marginBottom: "14px",
    marginTop: "11px",
  },
  hide: {
    display: "none",
  },
  hideline: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaper: {
    width: drawerWidth,
    // position: "absolute",
    marginTop: "100px",
    overflowY: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "hidden",
  },
  drawerPaper1: {
    width: drawerWidth,
    // position: "absolute",
    marginTop: "56px",
    // overflowY: "auto",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "hidden",
  },
  drawerHeader: {
    padding: "0px",
    bottom: -13,
    position: "fixed",
    display: "flex",
    alignItems: "center",
  },
  drawerCollapse: {
    bottom: -13,
    padding: "0px",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end!important",
  },
  content: {
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 3,
    paddingBottom: 3,
    boxShadow: "4px 6px 13px 0 rgba(17, 15, 71, 0.06)",
    backgroundColor: "white",
    borderRadius: 90,
    cursor: "pointer",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& .tooltip": {
      backgroundColor: "black",
    },

    marginLeft: -190,
  },
  toolhead: {
    backgroundColor: "white",
    boxShadow: "none",
    border: "none",
  },
  list: {
    padding: "0",
    borderRadius: 0,
  },
  mainlist: {
    // overflowY: "auto",
  },
  divide: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: 24 + " !important",
    marginBottom: 15 + " !important",
  },
  librarytxt: {
    paddingLeft: 20,
    fontSize: "16px",

    fontFamily: "fontSemiBold",
    color: "#110f47",
    textAlign: "left",
  },
  collapsetxt: {
    fontSize: 14,
    color: "black",
  },
  closebtn: {
    fontWeight: 500,
    marginBottom: "5px",
    color: "#110f47",
    width: "210px",
    backgroundColor: "white",
    borderRadius: "0px",
    padding: "0px",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "none",
      "& svg": {
        "& g": {
          fill: "#fff",
        },
        "& path": {
          fill: "#fff",
        },
      },
    },
  },

  button2: {
    color: "white",
    backgroundColor: "#fff",
    borderRadius: 50,
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 0,
    cursor: "pointer",
    width: 40,
    height: 53,
    outline: "none",
    "&:hover": {
      color: "white",
      boxShadow: "none",
      "& svg": {
        "& g": {
          fill: "#fff",
        },
        "& path": {
          fill: "#fff",
        },
      },
    },
  },

  button: {
    color: "grey",
    backgroundColor: "#fff",
    marginTop: 12,
    marginBottom: 6,
    borderRadius: 50,
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    lineHeight: 0,
    boxShadow: "4px 6px 13px 0 rgba(17, 15, 71, 0.06)",
    cursor: "pointer",
    width: 40,
    height: 40,
    "&:hover": {
      // backgroundColor: '#006ee6',
      color: "white",
      boxShadow: "none",
      "& svg": {
        "& g": {
          fill: "#fff",
        },
      },
    },
    "&:focus": {
      outline: "none",
    },
  },
  buttonDiv: {
    textAlign: "left",
    marginLeft: "23px",
  },

  flower: {
    marginBottom: "10px",
  },
  collapsebtn: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  clpse: {
    paddingLeft: "10px",
    fontSize: "14px",
    fontFamily: "fontSemiBold",
    color: "#110f47",
  },
  parentdiv: {
    // position: "fixed",
    height: "100%",
    zIndex: 1,
  },
  popover: {
    "& .MuiPopover-paper": {
      boxShadow: "4px 6px 13px 0 rgba(17, 15, 71, 0.06)",
      width: "240px",
    },
  },
}));

function Body(props) {
  // const { selectedArr } = props;
  const classes = useStyles();
  // const { Data, setLibrary } = props.datajson;

  const { Data, setLibrary } = props.library;

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorep, setAnchorep] = React.useState(null);
  const [anchorem, setAnchorem] = React.useState(null);

  const handleClickicon = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickpersona = (event) => {
    setAnchorep(event.currentTarget);
  };
  const handleClickmedia = (event) => {
    setAnchorem(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseper = () => {
    setAnchorep(null);
  };
  const handleClosemed = () => {
    setAnchorem(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    props.setisopen(true);
  };

  const handleDrawerClose = () => {
    props.setisopen(false);
    setOpen(false);
  };

  const opens = Boolean(anchorEl);
  const id = opens ? "simple-popover" : undefined;

  const openp = Boolean(anchorep);
  const id1 = opens ? "simple-popover" : undefined;

  const openm = Boolean(anchorem);
  const id2 = opens ? "simple-popover" : undefined;

  // adding persona in persona list

  const [editid, setEditid] = React.useState("");

  const addpersona = (e, text) => {
    e.stopPropagation();

    if (text?.id) {
      Data?.personas &&
        Data.personas.map((_) => {
          if (_?.id === text?.id) {
            _.imgUrl = text?.imgUrl;
            _.name = text?.value;
          }
          return _;
        });

      props.scenes.Data.map((scene, s) => {
        scene.personas.map((persona) => {
          if (persona?.id === text?.id) {
            persona.imageurl = text?.imgUrl;
            persona.name = text?.value?.toUpperCase();
          }
          return persona;
        });
        return scene;
      });

      props.scenes.setScenes({
        ...props.scenes,
        Data: props.scenes.Data,
      });
    } else {
      var persona = {
        id: uuidv4(),
        imgUrl: text?.imgUrl,
        name: text?.value,
        activestatus: true,
      };
      Data.personas.push(persona);
      setLibrary({ ...props.library, Data });
    }
    props.savefunc();
  };

  //delte persona and related scene will also delete
  const DeletePersona = async (id, index) => {
    // const filteredPersona = Data?.personas?.filter((_) => _?.id !== id);
    // Data.personas = filteredPersona;
    Data.personas[index].activestatus = false;
    setLibrary({ ...props.library, Data });

    props.scenes.Data.map((scene, s) => {
      debugger;
      // const filter = scene?.personas?.filter(
      //   (persona) => persona?.persona_id !== id
      // );
      scene.personas.forEach((persona) => {
        if (persona?.persona_id === id) {
          persona.activestatus = false;
        }
      });
      // scene.personas = filter;
      return scene;
    });

    props.scenes.setScenes({
      ...props.scenes,
      Data: props.scenes.Data,
    });

    handleCloseper();

    // Data.scene.map((ps, pi) => {
    //   ps.scene.map((s, p) => {
    //     let val = s.persona.filter((v) => v.id !== editid);
    //     Data.scene[pi].scene[p].persona = val;

    //     return s;
    //   });
    //   return ps;
    // });

    // let persona = Data.library.Personas.filter((v) => v.id !== editid);
    // Data.library.Personas = persona;
    // let saveQpas = await SaveSchema(Data);
    //   let readEnterpriseData = await getEnterpriceModuler();
    //   if(Object.keys(readEnterpriseData).length>0){
    //     let  list = readEnterpriseData;
    //     let  is_filter = false;
    //     //match delete field are filter of qpas
    //     // Object.keys(list).map(_key=>{
    //       if(Array.isArray(list.enterPriseList) ){
    //         list.enterPriseList = list?.enterPriseList?.filter(_li=>{
    //           if(_li?.id){
    //             if(_li?.id!==editid) {
    //               return true;
    //             }
    //             else{
    //               is_filter = true;
    //               return false;
    //             }
    //           } else{
    //             return true;
    //           }

    //          });
    //       }

    //     // })
    //     //check if the qpas data match to  the entity designer table
    //     if(is_filter){
    //       await SaveEnterPriseModuler(list);
    //     };

    //   }
    // setText("");
    // SetUpload("");
    // setPersonaedit(false);
    // setEdit("");
    // setLibrary({ ...props.datajson, Data });
    // setAnchorElP(null);
    // setUse(false);
    // props.savefunc();
  };

  //persona use check in scene
  const [use, setUse] = React.useState(false);
  // to check whether persona used
  const PersonaUse = () => {
    debugger;
    Data.scene.map((ps, pi) => {
      ps.scene.map((s, p) => {
        s.persona.map((v, c) => {
          if (v.id === editid) {
            setUse(true);
          }
          return v;
        });
        return s;
      });
      return ps;
    });
  };

  const UseFalse = () => {
    setUse(false);
  };
  // adding media function
  const onClickMedia = (e) => {
    e.stopPropagation();
    let mediacount = Data?.mediaLibrary?.length + 1;
    var media = {
      id: uuidv4(),
      image_url: "https://picsum.photos/200/300?random=" + mediacount,
      library_name: "Media Library",
      activestatus: true,
    };
    Data.mediaLibrary.push(media);
    setLibrary({ ...props.library, Data });
  };
  // 10px 6px

  // accordion open

  const [openA, setOpena] = React.useState("");

  const handleClickA = (v) => {
    debugger;
    setOpena(v);
    if (openA === v) {
      setOpena(false);
    }
  };

  return (
    <Grid
      container
      direction="column"
      className={classes.parentdiv}
      style={{
        position: "fixed",
        width: !props.props && "72px",
        top: !props.isheaderhidden && 62,
        // marginLeft: 14
      }}
    >
      <Grid container direction="column">
        <div className={classes.root}>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: props.isheaderhidden
                ? classes.drawerPaper
                : classes.drawerPaper1,
            }}
          >
            <div>
              <p className={classes.librarytxt}>Library</p>
            </div>
            <div className={classes.mainlist}>
              <List className={classes.list}>
                <Icon openA={openA} handleClickA={handleClickA} />
              </List>
              <List className={classes.list}>
                <Personas
                  onClickPersona={addpersona}
                  openA={openA}
                  // className={classes.pers}
                  handleClickA={handleClickA}
                  DeletePersona={DeletePersona}
                  // PersonaUse={PersonaUse}
                  // UseFalse={UseFalse}
                  // use={use}
                />
              </List>
              <List className={classes.list}>
                <Medialibrary
                  openA={openA}
                  handleClickA={handleClickA}
                  onClickMedia={onClickMedia}
                />
              </List>
            </div>
            <Grid className={classes.collapsebtn}>
              <List className={classes.drawerCollapse}>
                <IconButton
                  onClick={handleDrawerClose}
                  className={classes.closebtn}
                >
                  {theme.direction === "ltr" ? <Close /> : <Close />}
                  <p className={classes.clpse}>Collapse</p>
                </IconButton>
              </List>
            </Grid>
          </Drawer>

          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            <div
              aria-describedby={id}
              variant="contained"
              color="primary"
              onClick={handleClickicon}
            >
              <button className={classes.button2}>
                <Tooltip
                  title={
                    <span
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      Icons
                    </span>
                  }
                  placement="right"
                  arrow
                >
                  <div className={classes.flowertest}>
                    <Flower />
                  </div>
                </Tooltip>
              </button>
            </div>

            <Popover
              arrow
              id={id}
              open={opens}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 130, left: 85 }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              className={classes.popover}
            >
              <Iconpop />
            </Popover>

            <Divider
              className={clsx(classes.menuButtondivide, open && classes.hide)}
            />

            <div
              aria-describedby={id1}
              variant="contained"
              color="primary"
              onClick={handleClickpersona}
            >
              <button className={classes.button2}>
                <Tooltip
                  title={
                    <span
                      style={{
                        fontSize: "13px",
                        color: "white",
                      }}
                    >
                      Persona
                    </span>
                  }
                  placement="right"
                  arrow
                >
                  <div className={classes.flowertest}>
                    <Persona />
                  </div>
                </Tooltip>
              </button>
            </div>
            <Popover
              id={id1}
              open={openp}
              anchorep={anchorep}
              onClose={handleCloseper}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 210, left: 85 }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              className={classes.popover}
            >
              <Personapop
                onClickPersona={addpersona}
                // className={classes.pers}
                DeletePersona={DeletePersona}
                // PersonaUse={PersonaUse}
                // UseFalse={UseFalse}
                // use={use}
              />
            </Popover>

            <Divider
              className={clsx(
                classes.menuButtondividetwo,
                open && classes.hide
              )}
            />

            <div
              aria-describedby={id2}
              variant="contained"
              color="primary"
              onClick={handleClickmedia}
            >
              <button className={classes.button2}>
                <Tooltip
                  title={
                    <span
                      style={{
                        fontSize: "13px",
                        color: "white",
                        // padding: '10px'
                      }}
                    >
                      Media Library
                    </span>
                  }
                  placement="right"
                  arrow
                  className={classes.tool}
                >
                  <div className={classes.flowertest}>
                    <Media />
                  </div>
                </Tooltip>
              </button>
            </div>
            <Popover
              id={id2}
              open={openm}
              anchorem={anchorem}
              onClose={handleClosemed}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 285, left: 85 }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              className={classes.popover}
            >
              <Mediapop onClickMedia={onClickMedia} />
            </Popover>
          </main>
        </div>
      </Grid>
      <Grid container direction="column">
        <div className={classes.buttonDiv}>
          <div
            cursor="pointer"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <button className={classes.button}>
              <Open />
            </button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default withAllContexts(Body);

import React from "react";

export default function Body() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none">
        <g fill="#545465">
          <g>
            <path
              d="M18.635 15c-.376 0-.753.142-1.037.426l-2.169 2.169c-.567.568-.567 1.508 0 2.075l3.34 3.34-2.128 2.128c-.176.176-.305.395-.373.635l-1.244 4.357c-.062.22-.001.456.16.617.162.162.398.223.617.16l4.357-1.244c.24-.07.458-.197.634-.373l9.28-9.283c1.141-1.141 1.141-3.01 0-4.151-.57-.57-1.322-.856-2.075-.856-.752 0-1.505.285-2.075.856l-2.91 2.91-.79-.79c-.039-.054-.086-.101-.14-.14l-1.537-1.538c-.039-.054-.086-.1-.14-.14l-.732-.732c-.284-.284-.66-.426-1.038-.426zm0 1.25c.053 0 .105.022.149.065l.357.358-.184.185c-.164.157-.23.392-.173.612.058.22.23.393.45.45.22.058.455-.008.613-.173l.184-.184.788.788-.185.184c-.164.158-.23.392-.173.613.058.22.23.392.45.45.22.057.455-.009.613-.173l.184-.185.415.416-2.465 2.465-3.34-3.34c-.086-.087-.086-.21 0-.297l2.17-2.169c.043-.043.095-.065.147-.065zm9.362 0c.428 0 .856.166 1.185.495.661.66.661 1.712 0 2.373l-.812.813-2.372-2.372.813-.814c.33-.33.758-.494 1.186-.494zm-2.888 2.198l2.371 2.372-7.578 7.58c-.024.025-.055.043-.09.053l-3.268.934.933-3.269c.01-.034.028-.065.054-.09l7.578-7.58zm2.913 5.33l-.889.889 1.233 1.233-.185.184c-.164.158-.23.392-.173.612.058.22.23.393.45.45.22.058.455-.008.613-.173l.184-.184.43.43c.087.087.087.21 0 .297l-2.169 2.169c-.087.087-.209.087-.296 0l-2.552-2.553-.89.89 2.553 2.552c.567.568 1.507.568 2.075 0l2.168-2.169c.568-.568.568-1.508 0-2.075l-.805-.806c-.039-.053-.086-.1-.14-.14l-1.607-1.607z"
              transform="translate(-1387 -580) translate(1372 565)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

import React from "react";

export default function Body() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" transform="rotate(180)">
    <defs>
        <filter id="vbuewere6a">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.066667 0 0 0 0 0.058824 0 0 0 0 0.278431 0 0 0 1.000000 0"/>
        </filter>
    </defs>
    <g fill="none">
        <g>
            <g>
                <g filter="url(#vbuewere6a)" transform="translate(-41 -362) translate(17 130) translate(0 208)">
                    <g>
                        <path stroke="#110f47"  d="M0 0L4.75 4.75 0 9.5M7 0L11.75 4.75 7 9.5" transform="translate(25 25)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

  );
}

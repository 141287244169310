import React from "react";
import { Kpi } from "./";

const KpiProvider = (props) => {
  let [kpi, setKpi] = React.useState({
    allkpi: [],
  });

  return (
    <Kpi.Provider value={{ ...kpi, setKpi }}>{props.children}</Kpi.Provider>
  );
};

export default KpiProvider;

import React from "react";

export default function Body() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="24"
      viewBox="0 0 19 24"
    >
      <defs>
        <filter id="tg6jxsrona">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.329412 0 0 0 0 0.329412 0 0 0 0 0.396078 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none">
        <g>
          <g
            filter="url(#tg6jxsrona)"
            transform="translate(-37 -216) translate(17 130)"
          >
            <g>
              <path
                fill="#000"
                d="M9.192 0C6.029 0 3.447 2.558 3.447 5.69c0 3.133 2.582 5.691 5.745 5.691 2.242 0 4.405-1.334 6.085-2.59 1.679-1.258 2.872-2.516 2.872-2.516.312-.328.312-.84 0-1.17 0 0-1.193-1.257-2.872-2.514C13.597 1.334 11.434 0 9.192 0zm0 1.707c1.491 0 3.494 1.085 5.046 2.246 1.16.87 1.603 1.355 1.988 1.737-.385.383-.827.868-1.988 1.738-1.552 1.161-3.555 2.246-5.046 2.246C6.96 9.674 5.17 7.9 5.17 5.69s1.79-3.983 4.022-3.983zm-6.906 11.95C1.033 13.657 0 14.681 0 15.921v.866c0 2.05 1.31 3.637 3.049 4.588 1.739.95 3.941 1.387 6.143 1.387 2.201 0 4.404-.437 6.143-1.387 1.483-.81 2.599-2.106 2.912-3.735h.138v-1.719c0-1.24-1.035-2.264-2.287-2.264H2.286zm0 1.707h13.812c.32 0 .563.24.563.557v.012h-.001v.854c0 1.363-.773 2.337-2.158 3.094-1.384.757-3.347 1.174-5.31 1.174-1.964 0-3.926-.417-5.31-1.174-1.385-.757-2.159-1.73-2.159-3.094v-.866c0-.318.242-.557.563-.557z"
                transform="translate(20 86.119)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

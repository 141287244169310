import React from "react";
import { makeStyles, Button } from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import { withAllContexts } from "../../HOCs";
import Text from "../../screens/home/rightSection/components/textfield";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import { PopoverConformation } from "../../components/confirmationPopover";

const useStyles = makeStyles((theme) => ({
  pop: {
    padding: "15px",
    height: "218px",
    width: "260px",
  },
  input: {
    opacity: 0,
    position: "absolute",
    height: "100%",
    width: "100%",
  },

  saveBtn: {
    backgroundColor: "#E34D65",
    color: "#fff",
  },

  deletdiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5px",
    "& .MuiButton-contained": {
      "&:hover": {
        backgroundColor: "#E34D65 !important",
      },
    },
  },
}));

function AddEditPersonas(props) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);

  const { Data } = props.library;

  const [personaText, setPersonaText] = React.useState({
    value: props?.editPersona?.value ?? "",
    id: props?.editPersona?.id ?? "",
    imgUrl: props?.editPersona?.imgUrl ?? "",
    error: {
      value: "",
      message: "",
    },
    isEdit: props?.editPersona?.id ? true : false,
    index: props?.editPersona?.index ?? 0,
  });

  const clearPersonaText = (
    value = "",
    id = "",
    imgUrl = "",
    error = false,
    message = "",
    isEdit = false
  ) => {
    setPersonaText({
      ...personaText,
      value,
      id,
      imgUrl,
      error: {
        value: error,
        message,
      },
      isEdit,
    });
  };

  const onChangePersonaText = (e) => {
    setPersonaText({
      ...personaText,
      value: e.target.value,
    });
  };

  const addPersonaText = (e) => {
    if (
      personaText?.value?.trim() === "" ||
      !/([A-Za-z]{1}[\w\-]+)$/y.test(personaText?.value) ||
      Data?.personas?.some(
        (_) =>
          _?.name?.toLowerCase()?.trim() ===
            personaText?.value?.toLowerCase()?.trim() &&
          (personaText?.isEdit ? personaText?.id !== _?.id : true)
      )
    ) {
      clearPersonaText(
        personaText?.value?.trim(),
        personaText?.id,
        personaText?.imgUrl,
        true,
        !Data?.personas?.some(
          (_) =>
            _?.name?.toLowerCase()?.trim() ===
            personaText?.value?.toLowerCase()?.trim()
        )
          ? "Please provide valid input"
          : "Persona name already exists",
        personaText?.isEdit
      );
    } else {
      props.onClickPersona(e, personaText);
      props.handleClose();
      clearPersonaText();
    }
  };

  const addPersonaTextKeyDown = (e) => {
    if (e.keyCode === 13) {
      addPersonaText(e);
    } else if (e.keyCode === 27) {
      clearPersonaText();
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setPersonaText({
        ...personaText,
        imgUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const [perosaInUse, setPersonaInUse] = React.useState(false);

  const usePersonaInScene = () => {
    const result = props?.scenes?.Data?.map((scene) => {
      if (scene?.personas?.some((_) => _?.persona_id === personaText?.id)) {
        setPersonaInUse(true);
        return;
      }
    });
    return result;
  };

  return (
    <div>
      <div className={classes.pop}>
        <div
          style={{
            textAlign: "center",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            paddingBottom: "6px",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {!personaText.imgUrl && !personaText.isEdit && (
            <label htmlFor="icon-button-file">
              <Avatar
                id="icon-button-file"
                style={{
                  width: "100px",
                  height: "100px",
                  margin: "auto",
                  fontSize: 12,
                  background: "#bdbdbd85",
                }}
              >
                <input
                  accept="image/*"
                  className={classes.input}
                  // id='icon-button-file'
                  type="file"
                  onChange={(e) => handleImageChange(e)}
                />
                <CloudUploadIcon
                  style={{
                    fontSize: "1.5rem",
                    color: "#007AFF",
                    marginBottom: 4,
                  }}
                />
                &nbsp;
                <span
                  style={{
                    color: "#417aff",
                    fontWeight: "bolder",
                  }}
                >
                  Upload
                </span>
              </Avatar>
            </label>
          )}
          {(personaText.isEdit || personaText.imgUrl) && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <label
                htmlFor="icon-button-file"
                id="icon-button-file"
                style={{
                  width: "100px",
                  height: "100px",
                  margin: "auto",
                  fontSize: 12,
                }}
              >
                <Avatar
                  style={{
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    fontSize: 12,
                    background: "#bdbdbd85",
                  }}
                  className={classes.avtr}
                >
                  {
                    <img
                      alt={personaText.imgUrl}
                      src={personaText.imgUrl}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        opacity: 0.7,
                      }}
                    />
                  }
                  <input
                    accept="image/*"
                    className={classes.input}
                    // id='icon-button-file'
                    type="file"
                    onChange={(e) => handleImageChange(e)}
                  />
                </Avatar>
                <EditIcon
                  style={{
                    marginTop: "-64px",
                    position: "absolute",
                    color: "#3f3f44",
                    marginLeft: "-10px",
                    pointerEvents: "none",
                  }}
                />
                {personaText.isEdit && (
                  <PopoverConformation
                    icon={<DeleteIcon style={{ color: "#9696a9" }} />}
                    // persona
                    msg={
                      perosaInUse
                        ? "This persona is used in scene(s), would you like to delete?"
                        : "Would you Like to Delete ?"
                    }
                    Deletefun={() => {
                      props.DeletePersona(personaText?.id, personaText?.index);
                      props.handleClose();
                      clearPersonaText();
                    }}
                    persona
                    PersonaUse={usePersonaInScene}
                  />
                )}
              </label>
            </div>
          )}
        </div>
        <br />
        <Text
          placeholder="New Persona"
          value={personaText?.value}
          onChange={onChangePersonaText}
          onKeyDown={(e) => addPersonaTextKeyDown(e)}
          onClickadd={(e) => addPersonaText(e)}
          onClickdelete={() => {
            props.handleClose();
            clearPersonaText();
          }}
          error={personaText?.error?.value}
          helperText={personaText?.error?.message}
        />
        <div className={classes.deletdiv}>
          <Button
            variant="contained"
            onClick={(e) => addPersonaText(e)}
            // style={{ width: "80%" }}
            className={classes.saveBtn}
          >
            {personaText.isEdit ? "UPDATE" : "ADD"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withAllContexts(AddEditPersonas);

import React from "react";
import {
  makeStyles,
  Typography,
  Collapse,
  Divider,
  Avatar,
  ListItemAvatar,
  Button,
  ListSubheader,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddIcon from "@material-ui/icons/Add";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CloseIcon from "@material-ui/icons/Close";

import { withAllContexts } from "../../../../HOCs";

import Text from "../components/textfield";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    height: "100%",
    width: 300,
  },
  title: {
    backgroundColor: "#F8F7F9",
  },
  titleText: {
    fontSize: "14px",
    color: "#110f47",
    fontFamily: "fontSemiBold",
    margin: 0,
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },

  dotIcon: {
    minWidth: 20,
    marginTop: 6,
  },
  inline: {
    fontSize: "12px",
    fontStretch: "normal",
    fontWeight: 800,
    color: "#9696A9",
  },

  closeIcon: {
    fontSize: 16,
  },
  add: {
    color: "#e34d65",
    fontSize: "11px",
    fontWeight: 500,
    fontStretch: "normal",
    lineHeight: "1.71",
    letterSpacing: "normal",
    "&:hover": {
      backgroundColor: "#fff",
    },
    padding: "10px 19px 10px",
  },
  proptext: {
    color: "#110f47",
    fontFamily: "fontRegular",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "1.71",
    letterSpacing: "normal",
    cursor: "pointer",
  },
  lis: {
    "& li": {
      padding: "0px 20px",
    },
  },
  purple: {
    width: "21px",
    height: "21px",
    backgroundColor: "#110f47",
    fontFamily: "fontRegular",
    fontSize: "12px",
  },
}));

function Properties(props) {
  const classes = useStyles();
  const [opene, setopene] = React.useState(null);

  const [addNewprop,setAddNewProp] = React.useState(null);
  const [addText,setAddText] = React.useState('');
  const [addTextError,setAddTextError] = React.useState({
    error:false,
    message:''
  })

  const [editText,setEditText] = React.useState({
    value:'',
    id:'',
    error:{
      value:false,
      message:''
    }
  })

  const addNewPropBtn = (val) =>{
    onClearAddText(val)
  }

  const onChangeAddText = (e) =>{
    setAddText(e.target.value)
  }

  const handleClick = (item) => {
    setAddNewProp(null);
    onClearEditText()
    setopene(item);
    if (item?.id === opene?.id) {
      setopene(null);
    }
  };

  const onAddPropText = (e, item, value) =>{
    if (e.keyCode === 13) {
      checkAddTextProp(item,value)
    }
    else if (e.keyCode === 27) {
      setAddNewProp(null);
    }
  }

  const onClearAddText = (val=null) =>{
    setAddText('');
    setAddNewProp(val);
    setAddTextError({
      error:false,
      message:''
    })
  }

  const onClickAddInput = (item,addText) =>{
    checkAddTextProp(item,addText);
  }

  const checkAddTextProp = (item,addText) =>{
    if(addText?.trim() === "" || props?.value?.find(_=>_?.value === item?.value)?.props?.some(_=>_.value?.toLowerCase()?.trim() === addText?.toLowerCase()?.trim())){
      setAddTextError({
        error:true,
        message:addText?.trim() === "" ? 'Please provide valid input' : 'Name already exists'
      });
      setAddText(addText.trim());
    }
    else{
      props.keypresssubprop(item,addText);
      onClearAddText();
    }
  }

  const onChangeEditText = (e) =>{
    setEditText({
      ...editText,
      value:e.target.value
    })
  }

  const onClickEditPropText = (val) =>{
    setEditText({
      ...editText,
      id:val?.id,
      value:val?.value
    })
  }

  const onClearEditText = () =>{
    setEditText({
      ...editText,
      value:'',
      id:'',
      error:{
        value:false,
        message:''
      }
    })
  }

  const onClickEditInput = (item,text,ind) =>{
    checkEditTextProp(item,text,ind);
  }

  const checkEditTextProp = (item,text,ind) =>{
    if(
      text?.value?.trim() === "" || 
      props?.value?.find(_=>_?.value === item?.value)?.props?.some(_=>_.value?.toLowerCase()?.trim() === text?.value?.toLowerCase()?.trim() && 
      text?.id != _?.id
      )
    ){
      setEditText({
        ...editText,
        value:text?.value?.trim(),
        error:{
          value:true,
          message:text?.value?.trim() === "" ? 'Please provide valid input' : 'Name already exists'
        }
      })
      
    }
    else{
      props.keypresseditsubprop(item,text?.value,ind)
      onClearEditText();
    }
  }

  const onEditPropText = (e,item,value,ind) =>{
    if (e.keyCode === 13) {
      onClickEditInput(item,value,ind)
    }
    else if (e.keyCode === 27) {
      onClearEditText();
    }
  }

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ backgroundColor: "#F8F7F9" }}
        >
          <div className={classes.title}>
            <p className={classes.titleText}>
              Properties ({props.head})
              <IconButton component="span" onClick={props.closeHandle}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </p>
          </div>
        </ListSubheader>
      }
    >
      {props.properties?.map((item, index) => {
        return (
          <>
            <Divider />
            <ListItem
              key={index}
              button
              onClick={() => handleClick(item)}
              style={{ paddingLeft: 12 }}
            >
              {item?.id === opene?.id ? (
                <ArrowDropDownIcon style={{ fontSize: 24, marginRight: 8 }} />
              ) : (
                <AddIcon style={{ fontSize: 20, marginRight: 8 }} />
              )}
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {item.value}
                    </Typography>
                  </React.Fragment>
                }
              />
              {item?.id === opene?.id ? (
                ""
              ) : (
                <Avatar className={classes.purple}>{props?.value?.find(_=>_?.value === item.value)?.props?.length ?? 0}</Avatar>
              )}
            </ListItem>

            <Collapse in={item.id === opene?.id} timeout="auto" unmountOnExit>
              {
                props?.value?.find(_=>_?.value === item.value)?.props?.map((_,i)=>{
                  return(
                  <List
                    key={i}
                    className={classes.lis}
                    component="div"
                    disablePadding
                  >
                    {editText?.id === _?.id ? (
                      <ListItem alignItems="flex-start">
                        <div style={{ paddingBottom: "16px" }}>
                          <Text
                            placeholder={`New ${opene?.value}`}
                            value={editText?.value}
                            onChange={onChangeEditText}
                            onKeyDown={(e) => onEditPropText(e,item,editText,i)}
                            onClickadd={(e) =>
                              onClickEditInput(item,editText,i)
                            }
                            onClickdelete={onClearEditText}
                            error={editText?.error?.value}
                            helperText={editText?.error?.message}
                          />
                        </div>
                      </ListItem>
                    ) : (
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar className={classes.dotIcon}>
                          <FiberManualRecordIcon
                            style={{ color: "#34b69c", fontSize: 10 }}
                          />
                        </ListItemAvatar>

                        <ListItemText
                          onDoubleClick={() =>
                            onClickEditPropText(_)
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="div"
                                className={classes.proptext}
                              >
                                {_?.value}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                        <div
                          style={{ marginTop: "auto",cursor:'pointer' }}
                          onClick={() => props.DeletesubProp(item,_)}
                        >
                          <Delete
                            style={{ fontSize: "17px", color: "#34b69c" }}
                          />
                        </div>
                      </ListItem>
                    )}
                  </List>
                  )
                })
              }
              {
                addNewprop?.value === item?.value ? (
                  <div style={{ padding: "16px" }}>
                    <Text
                      placeholder={`New ${opene?.value}`}
                      value={addText}
                      onChange={onChangeAddText}
                      onKeyDown={(e) => onAddPropText(e,item,addText)}
                      onClickadd={(e) => {
                        onClickAddInput(item,addText)
                      }}
                      onClickdelete={(e) =>
                        onClearAddText()
                      }
                      error={addTextError?.error}
                      helperText={addTextError?.message}
                    />
                  </div> ) : (
                    <>
                      <Divider />
                      <Button
                        className={classes.add}
                        onClick={() => addNewPropBtn(item)}
                      >
                        {/* <AddIcon
                        style={{
                          fontSize: 17,
                          marginBottom: 2
                        }}
                      />{' '} */}
                        + Add new
                      </Button>
                    </>
                  )
                }
            </Collapse>
          </>
        );
      })}
    </List>
  );
}
export default withAllContexts(Properties);

import React from "react";
import { Scenes } from "./";

const ScenesProvider = (props) => {
  let [scenes, setScenes] = React.useState({
    Data: [],
    selectedSceneId: null,
    selectedScene: null,
  });

  return (
    <Scenes.Provider value={{ ...scenes, setScenes }}>
      {props.children}
    </Scenes.Provider>
  );
};

export default ScenesProvider;

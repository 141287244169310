import React from "react";
import { themes, alertProps } from "../utils";

export const ThemeContext = React.createContext({
  name: themes.default,
  setTheme: () => null,
});

export const AuthContext = React.createContext({
  user: {},
  setAuth: () => null,
});

export const AlertContext = React.createContext({
  open: false,
  severity: alertProps.severity.success,
  msg: "",
  vertical: alertProps.vertical.top,
  horizontal: alertProps.horizontal.center,
  onclose: () => null,
  setSnack: () => null,
});

export const HandleDataJson = React.createContext({
  Data: {},
  KPI: [],
  setData: () => null,
});

export const Stories = React.createContext({
  stories: [],
  setStories: () => null,
});

export const Scenes = React.createContext({
  scenes: [],
  selectedSceneId: null,
  setScenes: () => null,
});

export const Library = React.createContext({
  library: {},
  setLibrary: () => null,
});

export const Color = React.createContext({
  color: {},
  setColor: () => null,
});

export const Kpi = React.createContext({
  kpi: [],
  setKpi: () => null,
});

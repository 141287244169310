import React from "react";
import { Color } from "./";

const ColorProvider = (props) => {
  let [color, setColor] = React.useState({
    color: [],
  });

  return (
    <Color.Provider value={{ ...color, setColor }}>
      {props.children}
    </Color.Provider>
  );
};

export default ColorProvider;

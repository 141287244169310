/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020
 * @desc withAllContexts HOC will send all the necessary Context such as HandleDataJson as a prop.
 */

import React from "react";
import {
  HandleDataJson,
  Stories,
  Scenes,
  Library,
  Color,
  Kpi,
} from "../contexts/index";

const withAllContexts = (Component) => (props) => {
  const datajson = React.useContext(HandleDataJson);
  const stories = React.useContext(Stories);
  const scenes = React.useContext(Scenes);
  const library = React.useContext(Library);
  const color = React.useContext(Color);
  const kpi = React.useContext(Kpi);

  return (
    <Component
      {...props}
      datajson={datajson}
      stories={stories}
      scenes={scenes}
      library={library}
      color={color}
      kpi={kpi}
    >
      {props.children}
    </Component>
  );
};

export default withAllContexts;

import React from "react";
import { Grid, makeStyles,Popover } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Personalist from "../librarylist/Personalist";
import IconButton from "@material-ui/core/IconButton";
// import DataJson from "../../../../data.json";
import { withAllContexts } from "../../../../HOCs";
import AddEditPersonas from '../../../../components/addEditPersona';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "grey",
    fontSize: 20,
    margin: "12px",
  },
  textwidth: {
    borderRadius: 0,
    padding: "0px 0px 5px 0px",
  },
  headtext: {
    fontSize: 12,
    color: "#110f47",
    fontFamily: "fontRegular",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    fontWeight: 800,
    display: "inline-flex",
    paddingLeft: "20px",
    textTransform: "uppercase",
  },
  add: {
    color: "#e34d65",
    fontSize: 16,
    justifyContent: "right!important",
    textAlign: "right!important",
    cursor: "pointer",
  },
  gridadd: {
    marginRight: "10px",
  },
  pop: {
    padding: "20px",
    height: "45px",
  },
  addBtn:{
    width:40,
    height:40,
    borderRadius:'50%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  }
}));

function Personapop(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [editPersona,setEditPersona] = React.useState({
    value:props?.value?.name ?? '',
    id:props?.value?.id ?? '',
    imgUrl:props?.value?.imageurl ?? '',
  })

  const handleChange = (e,val) =>{   
    if(props?.main){
      props?.handleChange && props.handleChange(val);
    } 
    else{
      clearEditPersona(val?.name,val?.id,val?.imgUrl)
      setAnchorEl(e.currentTarget)
    }
  }

  const clearEditPersona = (value="",id="",imgUrl="") =>{
    setEditPersona({
      ...editPersona,
      value,
      id,
      imgUrl
    })
  }

  const handleClose = () => {
    setAnchorEl(null);
    clearEditPersona()
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simsple-popovers' : undefined;

  return (
    <>
    <div className={classes.textwidth}>
      <Grid justify="space-between" container>
        <Grid>
          <p className={classes.headtext}>
            Persona
          </p>
        </Grid>
        <Grid className={classes.gridadd}>
          {props.main ? (
            ""
          ) : (
            <div className={classes.addBtn} aria-describedby={id} onClick={e=>setAnchorEl(e.currentTarget)}>
              <AddOutlinedIcon className={classes.add} />
            </div>
          )}
        </Grid>
      </Grid>
      <Divider />
      <Personalist
        main={props.main}
        handleChange={handleChange}
        value={editPersona?.value}
      />
    </div>
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={()=>{
          handleClose();
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <AddEditPersonas 
          onClickPersona={props?.onClickPersona}
          DeletePersona={props?.DeletePersona}
          handleClose={handleClose}
          editPersona={editPersona}
          PersonaUse={props.PersonaUse}
          UseFalse={props.UseFalse}
        />
      </Popover>
    </>
  );
}
export default withAllContexts(Personapop);

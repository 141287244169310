import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Typography,
  Button,
  Grid,
  Avatar,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withAllContexts } from "../../HOCs";
// import axios from "axios";
// import { uuid } from "uuidv4";
// import Alerts from "../alert";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    backgroundColor: "white",
    padding: "15px",
    boxShadow: "none",
    borderRadius: 0,
    textTransform: "none",
    color: "#110f47",
    fontSize: "13px",
    fontWeight: 400,
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.5px",

    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  headtitile: {
    color: "#110f47",
    fontSize: "13px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  qdm: {
    padding: "0px 10px",
  },
  rightalign: {
    textAlign: "right",
    // display: "grid",
    // gridTemplateColumns: '36px 97px 8px',
    float: "right",
    padding: "8px",
    display: "flex",
  },
  rounded: {
    width: "22px",
    height: "22px",
    fontSize: "11px",
    margin: "auto",
    background: "black",
    color: "#fff",
  },
  username: {
    fontSize: "13px",
    color: "#fff",
    fontFamily: "fontSemiBold",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    margin: "auto",
  },
}));

function HeaderBar(props) {
  const classes = useStyles();

  // const { Data, setData } = props.datajson;
  // const [load, setLoad] = React.useState(false);
  // const [openAlert, setOpenAlert] = React.useState(false);

  // const SaveSchema = async () => {
  //   let dataList = {
  //     db_name: "QDM_Metadata",
  //     entity: "QPAS",
  //     is_metadata: false,
  //     projectname: "CRAYOND3",
  //     doc: {
  //       metadataId: localStorage.metadataId ? localStorage.metadataId : uuid(),
  //       qpasid: localStorage.qpasid ? localStorage.qpasid : uuid(),
  //       projectname: "CRAYOND3",
  //       attributes: Data,
  //     },
  //   };
  //   if (localStorage.qpasid) {
  //     dataList.filter = { qpasid: dataList.qpasid };
  //   }
  //   let data = JSON.stringify([dataList]);

  //   var config = {
  //     method: "post",
  //     url: "http://13.71.3.63:8084/api/upsert_document",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   await axios;
  //   axios(config)
  //     .then((response) => {
  //       localStorage.setItem(
  //         "qpasid",
  //         response.data.Result[0].properties.doc.qpasid
  //       );
  //       localStorage.setItem(
  //         "metadataId",
  //         response.data.Result[0].properties.doc.metadataId
  //       );
  //       setLoad(false);
  //       setOpenAlert(true);
  //     })
  //     .catch((err) => {
  //       setLoad(false);
  //       alert("something went wrong!");
  //     });
  // };
  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0}>
        {/* <Toolbar variant="dense"> */}
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sm={1}
            container
            justify="space-between"
            alignItems="center"
          >
            <Typography variant="h6" color="inherit" className={classes.qdm}>
              QDM
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Tooltip title={props.title}>
              <Button
                variant="contained"
                disableElevation
                color="default"
                className={classes.button}
              >
                <Typography className={classes.headtitile}>
                  Play&nbsp;1: &nbsp;{props.title}
                </Typography>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className={classes.rightalign}>
              <Avatar variant="rounded" className={classes.rounded}>
                DP
              </Avatar>
              <Typography variant="h6" className={classes.username}>
                &nbsp; &nbsp;Dmirch Preach
              </Typography>
              <ExpandMoreIcon style={{ marginBottom: 3 }} />
            </div>
          </Grid>
        </Grid>
        {/* <Button
            color="secondary"
            variant="contained"
            onClick={() => SaveSchema()}
          >
            Save
          </Button> */}
        {/* </Toolbar> */}
      </AppBar>
      {/* {openAlert && (
        <Alerts
          open={openAlert}
          closeAlert={() => {
            setOpenAlert(false);
          }}
          vertical={"top"}
          horizontal={"right"}
          msg={"Successfully schema saved"}
        />
      )} */}
    </div>
  );
}

export default withAllContexts(HeaderBar);

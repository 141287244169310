import React from "react";
import {
  Grid,
  makeStyles,
  withStyles,
  Popover,
  Button,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import WebIcon from "@material-ui/icons/Web";
import Divider from "@material-ui/core/Divider";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Avatar from "@material-ui/core/Avatar";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Personalist from "./librarylist/Personalist";
// import DataJson from "../../../data.json";
import EditIcon from "@material-ui/icons/Edit";
import { withAllContexts } from "../../../HOCs";
import Text from "../rightSection/components/textfield";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import { PopoverConformation } from "../../../components/confirmationPopover";
import AddEditPersonas from "../../../components/addEditPersona";

const useStyles = makeStyles((theme) => ({
  head: {
    boxShadow: "none",
    width: 210,
    borderTop: "solid 1px rgba(17, 15, 71, 0.08)",
    borderRadius: "0!important",
    margin: 0 + " !important",
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "14px 0px",
    },
    "& .MuiAccordionSummary-content": {
      display: "inline-block",
    },
  },
  headtext: {
    paddingLeft: 10,
    fontSize: 12,
    color: "#9696a9",
    fontWeight: 500,
    textTransform: "uppercase",
  },
  webicons: {
    fontSize: 17,
    color: "grey",
  },
  imges: {
    width: 200,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    display: "flex",
  },
  avttar: {
    display: "inline-flex",
    margin: "16px 19px 16px 21px",
  },

  add: {
    color: "#e34d65",
    fontSize: 16,
    justifyContent: "right!important",
    textAlign: "right!important",
  },
  arrowdown: {
    color: "black",
    marginTop: -5,
  },
  textwidth: {
    borderRadius: 0,
    padding: "7px 0px 7px 0px",
    height: "45vh",
    overflowY: "auto",
  },
  expmore: {
    display: "none",
  },
  avttxt: {
    display: "inline-flex",
    fontSize: "12px",
  },
  accro: {
    marginTop: -5 + " !important",
    marginBottom: -5 + " !important",
    minHeight: "0!important",
  },
  gridadd: {
    marginLeft: "72px",
  },
  pop: {
    padding: "15px",
    height: "218px",
    width: "260px",
  },
  input: {
    opacity: 0,
    position: "absolute",
    height: "100%",
    width: "100%",
  },

  saveBtn: {
    backgroundColor: "#E34D65",
    color: "#fff",
  },
  cancelBtn: {
    backgroundColor: "#fff",
  },
  deletdiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5px",
    "& .MuiButton-contained": {
      "&:hover": {
        backgroundColor: "#E34D65 !important",
      },
    },
  },
}));

const AccordionSummary = withStyles({
  root: {
    "& .MuiAccordionSummary-content": {
      margin: "9px 0px",
    },
  },
  expandIcon: {
    order: -1,
    width: "10px",
    height: "10px",
    // border: "1px solid rgba(17, 15, 71, 0.08)",

    color: "black",
    margin: "0px",
    backgroundColor: "white !important",
    "&$expanded": {
      transform: "rotate(90deg)",
    },
  },
  expanded: {
    // background: "whitesmoke",
  },
})(MuiAccordionSummary);

function Personas(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [editPersona, setEditPersona] = React.useState({
    value: "",
    id: "",
    imgUrl: "",
    index: 0,
  });

  const handleChange = (e, val, i) => {
    clearEditPersona(val?.name, val?.id, val?.imgUrl, i);
    setAnchorEl(e.currentTarget);
  };

  const clearEditPersona = (value = "", id = "", imgUrl = "", index) => {
    setEditPersona({
      ...editPersona,
      value,
      id,
      imgUrl,
      index,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    clearEditPersona();
  };

  const usePersonaInScene = () => {
    // props?.scenes?.Data?.map()
  };

  const open = Boolean(anchorEl);
  const id = open ? "ssimple-popover" : undefined;

  return (
    <div>
      <Accordion
        className={classes.head}
        onChange={() => props.handleClickA("persona")}
        expanded={props.openA === "persona"}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<ArrowRightIcon fontSize="small" />}
          className={classes.accro}
        >
          <Grid container justify="space-between" style={{ marginTop: "2px" }}>
            <Grid item style={{ marginTop: "auto", marginBottom: "auto" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <WebIcon className={classes.webicons} />
                </div>
                <div className={classes.headtext}>Persona</div>
              </div>
            </Grid>
            <Grid item>
              <div>
                <AddOutlinedIcon
                  className={classes.add}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.textwidth}>
          <Personalist value={editPersona?.id} handleChange={handleChange} />
        </AccordionDetails>
      </Accordion>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          handleClose();
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <AddEditPersonas
          onClickPersona={props?.onClickPersona}
          DeletePersona={props?.DeletePersona}
          handleClose={handleClose}
          editPersona={editPersona}
          PersonaUse={props.PersonaUse}
          UseFalse={props.UseFalse}
        />
      </Popover>
    </div>
  );
}

export default withAllContexts(Personas);

import React from "react";

export default function Body() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <defs>
        <filter id="wx0twskd1a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.329412 0 0 0 0 0.329412 0 0 0 0 0.396078 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="#545465">
        <g>
          <g
            filter="url(#wx0twskd1a)"
            transform="translate(-37 -149) translate(18 130)"
          >
            <g>
              <path
                fill="#545465"
                d="M11.012 0C9.136 0 7.856 1.45 7.069 2.735c-.502.82-.78 1.496-.957 1.977-.06-.001-.046-.004-.114-.004-.724 0-1.894.068-3.052.512C1.788 5.663.598 6.56.166 8.109c-.552 1.98.37 3.706 1.333 4.832.599.701 1.142 1.138 1.543 1.431-.138.48-.303 1.16-.369 2.079-.106 1.482.118 3.403 1.601 4.673.734.628 1.606.876 2.422.876 2.12 0 3.711-1.216 4.308-1.706.587.503 2.105 1.706 4.233 1.706.728 0 1.49-.166 2.165-.615 1.557-1.037 1.817-3.02 1.794-4.6-.016-1.115-.16-1.922-.262-2.413.371-.298.927-.776 1.561-1.558.934-1.152 1.863-2.833 1.366-4.697-.45-1.685-1.917-2.538-3.233-2.946-1.119-.348-2.106-.406-2.703-.42-.17-.473-.448-1.166-.97-2.016C14.17 1.45 12.889 0 11.013 0zm0 1.571c1.074 0 1.981.917 2.644 1.998.662 1.082.996 2.18.996 2.18.102.338.413.566.759.554 0 0 .048-.002.149-.002.398 0 1.555.042 2.622.373s1.94.883 2.2 1.856c.3 1.131-.286 2.316-1.068 3.281-.783.966-1.665 1.6-1.665 1.6-.263.188-.384.522-.304.84 0 0 .296 1.198.316 2.558.02 1.36-.274 2.711-1.096 3.258-.383.254-.822.362-1.328.362-1.723 0-3.713-1.683-3.713-1.683-.285-.26-.712-.267-1.004-.017 0 0-2.146 1.7-3.824 1.7-.534 0-.997-.133-1.437-.51-.95-.813-1.143-2.124-1.055-3.354.088-1.23.452-2.233.452-2.233.13-.354-.005-.753-.322-.948 0 0-.896-.56-1.68-1.478-.784-.917-1.377-2.057-1.011-3.365.277-.994.964-1.513 1.84-1.85.877-.335 1.898-.411 2.515-.411.361 0 .564.021.564.021.364.037.702-.193.81-.55 0 0 .336-1.1.998-2.182.663-1.081 1.569-1.998 2.642-1.998zm-.02 6.28c-1.309 0-.218 3.655-.218 3.655s-2.856-2.234-3.32-.907c-.465 1.326 3.186 1.351 3.186 1.351s-2.775 2.23-1.834 3.096c.94.866 2.187-2.82 2.187-2.82s1.152 3.712 2.189 2.82c1.034-.892-1.834-3.096-1.834-3.096s3.67-.028 3.185-1.351c-.483-1.323-3.32.907-3.32.907s1.09-3.655-.22-3.655z"
                transform="translate(19 19)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import environment from "../../config";

export const getEnterpriceModuler = () => {
  return new Promise(async (resolve, reject) => {
    axios
      .post(`${environment.api_url}/api/read_documents`, {
        db_name: `${environment.database}`,
        entity: `${environment.qdm_enterprisemodeler_entity}`,
        filter: `${environment.qdm_enterprisemodeler_entity}.EnterpriceModelerid == '${localStorage.qpasid}'`,
        sort: `TO_NUMBER(${environment.qdm_enterprisemodeler_entity}._key) DESC `,
        limit: {
          count: 1,
        },
        return_fields: `{${environment.qdm_enterprisemodeler_entity}}`,
      })
      .then((response) => {
        debugger;
        console.log(response);
        if (
          response.data.result[response.data.result.length - 1]
            ?.EnterpriseModuler?.attributes
        ) {
          let list =
            response.data.result[response.data.result.length - 1]
              .EnterpriseModuler.attributes;
          debugger;
          let fillValue = Object.keys(list).length > 0 ? list : {};
          resolve(fillValue);
        } else {
          reject([]);
        }
      })
      .catch((err) => {});
  });
};
export const SaveEnterPriseModuler = async (Data) => {
  return new Promise(async (resolve, reject) => {
    let dataList = {
      db_name: `${environment.database}`,
      entity: `${environment.qdm_enterprisemodeler_entity}`,
      is_metadata: false,
      projectname: localStorage.projectName
        ? localStorage.projectName
        : "CRAYOND3",
      doc: {
        metadataId: localStorage.metadataId
          ? localStorage.metadataId
          : "0f9dc6ce-1d9b-4abd-a83b-462e319f7aa4",
        EnterpriceModelerid: localStorage.qpasid
          ? localStorage.qpasid
          : "ad8c5c3e-161f-4635-8b46-4b857fa6071e",
        projectname: localStorage.projectName
          ? localStorage.projectName
          : "CRAYOND3",
        attributes: Data,
      },
    };
    let data = JSON.stringify([dataList]);
    var config = {
      method: "post",
      url: `${environment.api_url}/api/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject([]);
      });
  });
};

export const SaveSchema = (Data) => {
  return new Promise(async (resolve, reject) => {
    var config = {
      method: "post",
      url: `${environment.api_url}/api/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: Data,
    };

    await axios(config)
      .then((response) => {
        //resolve(response);
        localStorage.setItem(
          "qpasid",
          response.data.Result[0].properties.doc.qpasid
        );
        localStorage.setItem(
          "metadataId",
          response.data.Result[0].properties.doc.metadataId
        );
      })
      .catch((err) => {
        reject([]);
      });
  });
};

//payload construct for story
export const ConstructUpsert = (Data) => {
  let overAllData = [];
  let storyArr = [];
  let playArr = [];
  let actArr = [];
  let qpasiD = localStorage.qpasid ? localStorage.qpasid : uuidv4();
  Data.map((story) => {
    let extraJson = {};
    let filter = {};
    if (story._key) {
      extraJson = {
        _id: story._id,
      };
      filter = { filter: { _key: story._key } };
    }

    if (!story._id && !story?.activestatus) {
      console.log("notadded");
    } else {
      let storyObj = {
        db_name: `${environment.database}` ,
        entity: "qpas_stories",
        is_metadata: false,
        ...filter,
        doc: {
          ...extraJson,
          qpasid: qpasiD,
          playsLength: story?.playsLength,
          storyid: story?.storyid,
          storyname: story?.storyname,
          createdby: "",
          updatedby: "",
          activestatus: story?.activestatus,
        },
      };

      storyArr.push(storyObj);
    }
    (story?.plays || []).map((play) => {
      let extraJson = {};
      let filter = {};
      if (play._key) {
        extraJson = {
          _id: play._id,
        };
        filter = { filter: { _key: play._key } };
      }
      if (!play._id && !play?.activestatus) {
        console.log("notadded");
      } else {
        let playObj = {
          db_name: `${environment.database}`,
          entity: "qpas_plays",
          is_metadata: false,
          ...filter,
          doc: {
            ...extraJson,
            qpasid: qpasiD,
            actLength: play?.actLength,
            storyid: play?.storyid,
            playid: play?.playid,
            playname: play?.playname,
            createdby: "",
            updatedby: "",
            activestatus: play?.activestatus,
            addKPI: play?.addKPI,
          },
        };
        playArr.push(playObj);
      }
      (play.acts || []).map((act) => {
        let extraJson = {};
        let filter = {};
        if (act._key) {
          extraJson = {
            _id: act._id,
          };
          filter = { filter: { _key: act._key } };
        }
        if (!act._id && !act?.activestatus) {
          console.log("notadded");
        } else {
          let actObj = {
            db_name: `${environment.database}`,
            entity: "qpas_acts",
            is_metadata: false,
            ...filter,
            doc: {
              ...extraJson,
              qpasid: qpasiD,
              projectid: "",
              storyid: act?.storyid,
              playid: act?.playid,
              actid: act?.actid,
              actname: act?.actname,
              createdby: "",
              updatedby: "",
              activestatus: act?.activestatus,
              actkpi: act?.actkpi,
            },
          };
          actArr.push(actObj);
        }
      });
    });
  });
  let UpsertData = overAllData.concat(storyArr, playArr, actArr);
  return UpsertData;
};

//payload construct for scene

export const ConstructUpsertScene = (Data, persona, library, color) => {
  let overAlldata = [];
  let qdm_color = [];
  let qdm_persona = [];
  let qpas_library = [];
  let qpas_scene = [];
  let qpas_steps = [];
  let qpas_persona = [];
  let qpasiD = localStorage.qpasid ? localStorage.qpasid : uuidv4();

  //qdm colors
  color.forEach((clr) => {
    let extraJson = {};
    let filter = {};
    if (clr._key) {
      extraJson = {
        _id: clr._id,
      };
      filter = { filter: { _key: clr._key } };
    }

    let colorObj = {
      db_name: `${environment.database}`,
      entity: "qdm_color",
      is_metadata: false,
      ...filter,
      doc: {
        ...extraJson,
        color_id: clr?.color_id,
        color_name: clr?.color_name,
        activestatus: clr?.activestatus,
      },
    };
    qdm_color.push(colorObj);
  });

  //qdm library
  library.forEach((lbry) => {
    let extraJson = {};
    let filter = {};
    if (lbry._key) {
      extraJson = {
        _id: lbry._id,
      };
      filter = { filter: { _key: lbry._key } };
    }
    let libraryObj = {
      db_name: `${environment.database}`,
      entity: "qpas_library",
      is_metadata: false,
      ...filter,
      doc: {
        ...extraJson,
        library_id: lbry?.library_id,
        library_name: lbry?.library_name,
        image_url: lbry?.image_url,
        activestatus: lbry?.activestatus,
      },
    };
    qpas_library.push(libraryObj);
  });

  //qdm persona
  persona.forEach((pers) => {
    debugger;
    let extraJson = {};
    let filter = {};
    if (pers._key) {
      extraJson = {
        _id: pers._id,
      };
      filter = { filter: { _key: pers._key } };
    }
    let qdmPersonaObj = {
      db_name: `${environment.database}`,
      entity: "qdm_persona",
      is_metadata: false,
      ...filter,
      doc: {
        ...extraJson,
        id: pers?.id,
        name: pers?.name,
        imgUrl: pers?.imgUrl,
        activestatus: pers?.activestatus,
      },
    };
    qdm_persona.push(qdmPersonaObj);
  });

  Data.forEach((scne) => {
    let extraJson = {};
    let filter = {};
    if (scne._key) {
      extraJson = {
        _id: scne._id,
      };
      filter = { filter: { _key: scne._key } };
    }
    debugger;
    let sceneObj = {
      db_name: `${environment.database}`,
      entity: "qpas_scene",
      is_metadata: false,
      ...filter,
      doc: {
        ...extraJson,
        qpasid: qpasiD,
        storyid: scne?.storyid ?? "",
        playid: scne?.playid ?? "",
        actid: scne?.actid ?? "",
        sceneid: scne?.sceneid ?? "",
        scenename: scne?.scenename ?? "",
        activestatus: scne?.activestatus,
        entity: scne?.entity ?? [],
        uxChannel: scne?.uxChannel,
        digitalEndPoint: scne?.digitalEndPoint,
      },
    };
    qpas_scene.push(sceneObj);

    (scne.steps || []).forEach((stps) => {
      let extraJson = {};
      let filter = {};
      if (stps._key) {
        extraJson = {
          _id: stps._id,
        };
        filter = { filter: { _key: stps._key } };
      }
      if (!stps._key && !stps?.activestatus) {
        console.log("notadded");
      } else {
        let stepsObj = {
          db_name: `${environment.database}`,
          entity: "qpas_steps",
          is_metadata: false,
          ...filter,
          doc: {
            ...extraJson,
            qpasid: qpasiD,
            storyid: stps?.storyid,
            playid: stps?.playid,
            actid: stps?.actid,
            sceneid: stps?.sceneid,
            stepid: stps?.stepid,
            properties: stps?.properties,
            document: stps?.document,
            function: stps?.function,
            activestatus: stps?.activestatus,
            uxChannel: stps?.uxChannel,
            digitalEndPoint: stps?.digitalEndPoint,
            stepname: stps?.stepname,
          },
        };
        qpas_steps.push(stepsObj);
      }
    });

    (scne.personas || []).forEach((prsna) => {
      let extraJson = {};
      let filter = {};
      if (prsna._key) {
        extraJson = {
          _id: prsna._id,
        };
        filter = { filter: { _key: prsna._key } };
      }
      if (!prsna._key && !prsna?.activestatus) {
        console.log("notadded");
      } else {
        let personaObj = {
          db_name: `${environment.database}`,
          entity: "qpas_persona",
          is_metadata: false,
          ...filter,
          doc: {
            ...extraJson,
            qpasid: qpasiD,
            storyId: prsna?.storyId,
            PlayId: prsna?.PlayId,
            ActId: prsna?.storyId,
            SceneId: prsna?.SceneId,
            persona_id: prsna?.persona_id,
            levels: prsna?.levels,
            name: prsna?.name,
            activestatus: prsna?.activestatus,
            imageurl: prsna?.imageurl,
            arrows: prsna?.arrows,
            personaLevel: prsna?.personaLevel,
            personaParentId: prsna?.personaParentId,
          },
        };
        qpas_persona.push(personaObj);
      }
    });
  });

  let UpserData = overAlldata.concat(
    qdm_color,
    qdm_persona,
    qpas_library,
    qpas_scene,
    qpas_steps,
    qpas_persona
  );

  return UpserData;
};

//api call
export const fetchData = async (
  input,
  url = `${environment.api_url}/api/read_documents`
) => {
  const __options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: {},
  };
  const res = await fetch(url, {
    ...__options,
    ...input,
  });
  const data = await res.json();
  return data;
};

///error check
export let checkError = (data) => {
  const isError = data?.error || data?.data?.error;
  let errMsg = "Something went wrong! Try Again.";
  if (
    data?.data?.error ||
    data?.data?.errorMessage ||
    data?.data?.error_description
  ) {
    if (typeof data?.data?.error === "string") {
      errMsg =
        data?.data?.error ||
        data?.data?.errorMessage ||
        data?.data?.error_description;
    } else {
      errMsg =
        data?.data?.errorMessage ||
        data?.data?.error_description ||
        data?.data?.message ||
        errMsg;
    }
  }
  if (errMsg === "invalid_grant" || errMsg === "Invalid user credentials") {
    errMsg = "Invalid Password / User ID";
  }
  return {
    isError: !!isError,
    errMsg,
  };
};

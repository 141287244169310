import React from "react";

export default function Body() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
    >
      <defs>
        <filter id="su8awlv36a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.329412 0 0 0 0 0.329412 0 0 0 0 0.396078 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none">
        <g>
          <g>
            <g
              filter="url(#su8awlv36a)"
              transform="translate(-1012 -368) translate(120 223) translate(852 124)"
            >
              <g>
                <path
                  fill="#000"
                  d="M2.153 0c-.074 0-.147.004-.22.011-.144.015-.283.044-.418.085C.841.305.307.835.097 1.506c-.042.133-.07.272-.086.415C.004 1.994 0 2.066 0 2.14V11.86C0 13.035.97 14 2.153 14h10.569c1.182 0 2.153-.965 2.153-2.139V2.14c0-.074-.004-.146-.011-.218-.015-.143-.044-.282-.086-.416-.21-.67-.744-1.2-1.418-1.409-.135-.041-.274-.07-.419-.085C12.869.004 12.796 0 12.722 0H2.153zm0 1.167c.324 0 .587.261.587.583 0 .322-.263.583-.587.583-.324 0-.587-.261-.587-.583 0-.322.263-.583.587-.583zm1.957 0c.324 0 .587.261.587.583 0 .322-.263.583-.587.583-.324 0-.587-.261-.587-.583 0-.322.263-.583.587-.583zm2.349 0h6.263c.324 0 .587.261.587.583 0 .322-.263.583-.587.583H6.459c-.324 0-.587-.261-.587-.583 0-.322.263-.583.587-.583zM1.174 3.5h12.527v8.361c0 .544-.432.972-.979.972H2.153c-.547 0-.979-.428-.979-.972V3.5z"
                  transform="translate(40 21)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

import React from "react";
import Grid from "@material-ui/core/Grid";
import NewScreenBar from "./newScreenbar";
import TitleSection from "./screenTitleSection";
import WorkflowSection from "./workflowSection";
// import DataJson from "../../../data.json";
import { withAllContexts } from "../../../HOCs";
//import { Typography } from "@material-ui/core";
// import Skeleton from '@material-ui/lab/Skeleton'
//import CircularProgress from "@material-ui/core/CircularProgress";
import { v4 as uuidv4 } from "uuid";
const Body = (props) => {
  // const { Data, setData } = props.datajson;
  const { Data, selectedScene, selectedSceneId, setScenes } = props.scenes;

  // const [disabled, setDisabled] = React.useState(false);

  const [Text, setText] = React.useState("");

  //const [selectedScene, setSelectedScene] = React.useState(null);

  const [edit, setEdit] = React.useState(false);

  // createScene
  const createScene = () => {
    const { act } = props?.location?.state;

    let sceneid = uuidv4();

    var newScene = {
      storyid: act?.storyid,
      playid: act?.playid,
      actid: act?.actid,
      sceneid,
      scenename: "",
      digitalEndPoint: [],
      uxChannel: [],
      steps: [
        {
          storyid: act?.storyid,
          playid: act?.playid,
          actid: act?.actid,
          sceneid: sceneid,
          stepid: uuidv4(),
          stepname: "",
          properties: [],
          document: [],
          function: [],
          digitalEndPoint: [],
          uxChannel: [],
          activestatus: true,
        },
      ],
      personas: [],
      entity: [],
      activestatus: true,
    };

    Data.push(newScene);
    setScenes({ ...props.scenes, Data, selectedSceneId: newScene?.sceneid });

    if (Data?.length === 1) {
      //setSelectedScene(newScene);
      setScenes({
        ...props.scenes,
        selectedSceneId: newScene?.sceneid,
        selectedScene: newScene,
      });
    }
    props.savefunc();
  };

  React.useEffect(() => {
    if (Data.length > 0) {
      //setSelectedScene(Data[0]);
      if (!selectedScene && !selectedSceneId) {
        setScenes({
          ...props.scenes,
          selectedSceneId: Data[0]?.sceneid,
          selectedScene: Data[0],
        });
      }
    } else {
      createScene();
    }
  }, [Data]);

  // TextchangeTo upload
  const textChange = (e) => {
    setText(e.target.value);
  };

  const keyPressSave = (e, scene) => {
    if (e.key === "Enter") {
      sceneNameMap(e, scene);
    }
  };

  const sceneNameMap = (e, scene) => {
    const modifiedScene = Data?.map((_) => {
      if (_?.sceneid === scene?.sceneid) {
        _["scenename"] = e.target.value;
      }
      return _;
    });

    setScenes({ ...props.scenes, Data: modifiedScene });
    props.savefunc();
  };

  const keyPressedit = (e, scene) => {
    if (e.keyCode === 13) {
      sceneNameMap(e, scene);
      setEdit(false);
    } else if (e.keyCode === 27) {
      setEdit(false);
    }
  };

  const onBlursave = (e, index) => {
    setEdit(false);
  };

  const checkboxValue = (val, key) => {
    const modifiedSceneData = Data?.map((_) => {
      if (_?.sceneid === selectedScene?.sceneid) {
        if (_?.[key]?.find((d) => d?.value === val?.value)) {
          _[key] = _[key]?.filter((l) => l?.value !== val?.value);
        } else {
          _[key].push(val);
        }
        return _;
      }
      return _;
    });

    setScenes({ ...props.scenes, Data: modifiedSceneData });
    props.savefunc();
  };

  return (
    <Grid
      style={{
        zoom: `${props.zooming}%`,
        width: !props.isopen && "100%",
        // margin: !props.isopen && 16,
        marginLeft: props.isopen ? (window.innerHeight <= 520 ? 70 : 30) : 0,
      }}
    >
      {/* {!item.scene && (
        <div>
          <Typography
            component="div"
            style={{
              textAlign: "center",
              height: "80vh",
              width: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={25} />
          </Typography>
        </div>
      )} */}

      {true && (
        <>
          <NewScreenBar
            scenes={Data}
            selectedScene={selectedScene}
            selectBtn={(scene) => {
              //setSelectedScene(scene);
              setScenes({
                ...props.scenes,
                selectedSceneId: scene?.sceneid,
                selectedScene: scene,
              });
            }}
            createScene={createScene}
          />

          {selectedScene && (
            <>
              <TitleSection
                scene={selectedScene ?? {}}
                edit={edit}
                textChange={textChange}
                keyPressSave={(e) => keyPressSave(e, selectedScene)}
                setEdit={setEdit}
                keyPressedit={(e) => keyPressedit(e, selectedScene)}
                onBlursave={(e) => onBlursave(e, selectedScene)}
                projectName={selectedScene?.scenename ?? ""}
                digitalEndPoint={selectedScene?.digitalEndPoint ?? []}
                uxChannel={selectedScene?.uxChannel ?? []}
                selectedScene={selectedScene}
                checkboxValue={checkboxValue}
                selectedarrIndex={props.selectedarrIndex}
              />
              <WorkflowSection
                savefunc={props.savefunc}
                selectedScene={selectedScene}
                parentid={selectedScene}
                steps={selectedScene?.steps}
                personas={selectedScene?.personas}
                UrlactId={props.UrlactId}
                UrlplayId={props.UrlplayId}
                UrlstoryId={props.UrlstoryId}
                {...props}
              />
            </>
          )}
        </>
      )}
    </Grid>
  );
};
export default withAllContexts(Body);

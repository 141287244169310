import React from "react";
import { makeStyles, ListSubheader, List, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withAllContexts } from "../../../../HOCs";

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    height: "100%",
    width: 300,
  },
  title: {
    backgroundColor: "#F8F7F9",
  },
  closeIcon: {
    fontSize: 16,
  },
  titleText: {
    fontSize: "14px",
    color: "#110f47",
    fontFamily: "fontSemiBold",
    margin: 0,
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
}));

function Share(props) {
  const classes = useStyles();
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ backgroundColor: "#F8F7F9" }}
        >
          <div className={classes.title}>
            <p className={classes.titleText}>
              Share{" "}
              <IconButton component="span" onClick={props.closeHandle}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </p>
          </div>
        </ListSubheader>
      }
    ></List>
  );
}
export default withAllContexts(Share);

import React from "react";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
// import DataJson from "../../../../data.json";
import Radio from "@material-ui/core/Radio";
// import Tick from "../../../../components/icons/tick";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import DoneIcon from "@material-ui/icons/Done";
import { withAllContexts } from "../../../../HOCs";
const useStyles = makeStyles((theme) => ({
  icon: {
    color: "grey",
    fontSize: 20,
    margin: "12px",
  },
  headtext: {
    fontSize: 10,
    color: "#545465",
    fontWeight: "normal",
    fontFamily: "fontRegular",
    textAlign: "center",
  },
  avttar: {
    display: "inline-flex",
    // margin: "4px 10px",
    textAlign: "center",
    marginLeft: "9px",
  },
  Radio: {
    "& .MuiButtonBase-root": {
      position: "absolute",
      zIndex: "99",
      top: "-3px",
      left: "0px",
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: "0px",
      marginRight: "13px",
    },

    // "& .Mui-checked": {
    //   color: "black",
    // },
  },
  avtr: {
    backgroundColor: "#fcc223",
  },
  avtr1: {
    backgroundColor: "#007aff",
  },
  avtr2: {
    backgroundColor: "#e34d65",
  },
  // width: {
  //   overflowY: "auto",
  //   height: "45vh",
  // },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      checkedIcon={
        // <Tick
        //   style={{
        //     padding: 6,
        //     fontSize: 18,
        //   }}
        // />
        <DoneIcon style={{ color: "#2196f3", fontSize: "25px" }} />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

function Personalist(props) {
  const classes = useStyles();
  const { Data } = props.library;

  return (
    <div className={classes.width}>
      <div style={{ paddingTop: "12px", textAlign: "center" }}>
        {Data?.personas?.map((person, i) => {
          if (person?.activestatus) {
            return (
              <div
                key={i}
                // style={{ marginLeft: props.main ? "9px" : "22px" }}
                className={classes.avttar}
              >
                <div>
                  {/* {props.main ? ( */}
                  <FormControl className={classes.Radio} component="fieldset">
                    <RadioGroup
                      aria-label="persona"
                      value={props?.value?.toLowerCase()}
                      name="persona"
                      onChange={(e) => props.handleChange(e, person, i)}
                    >
                      <FormControlLabel
                        value={person?.name?.toLowerCase()}
                        control={<StyledRadio />}
                        label={
                          <Avatar
                            key={i}
                            className={
                              i % 3 === 0
                                ? classes.avtr
                                : i % 2 === 0
                                ? classes.avtr1
                                : classes.avtr2
                            }
                            alt={person.name}
                            src={person.imgUrl}
                          >
                            {person?.name?.[0]}
                          </Avatar>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  {/* ) : (
                  <Avatar
                    key={i}
                    className={
                      i % 3 === 0
                        ? classes.avtr
                        : i % 2 === 0
                        ? classes.avtr1
                        : classes.avtr2
                    }
                    alt={person.name}
                    src={person.imgUrl}
                  />
                )} */}

                  <p className={classes.headtext}>{person.name}</p>
                </div>
              </div>
            );
          } else {
            return false;
          }
        })}
      </div>
    </div>
  );
}

export default withAllContexts(Personalist);

import React from "react";

export default function Body() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="24"
      viewBox="0 0 13 9"
    >
      <g fill="none">
        <g stroke="#007AFF" stroke-width="1.5">
          <g>
            <g>
              <path
                d="M185 75.088L188.912 79 195.912 72"
                transform="translate(-1156 -418) translate(120 223) translate(852 124)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

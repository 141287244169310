import React, { useRef, useState } from "react";
// import { DropTarget, DragDropContainer } from 'react-drag-drop-container'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Typography,
  makeStyles,
  IconButton,
  Collapse,
  Button,
  Avatar,
  Popover,
} from "@material-ui/core";
// import AttachmentClip from '@material-ui/icons/AttachmentOutlined'
import AddIcon from "@material-ui/icons/Add";
import Stepicon from "../../../assets/images/group-4@2x.png";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import Divder from '../../../components/icons/divder'
import Input from "../../../components/inputSteper";
import Xarrow from "react-xarrows";
import DeleteIcon from "../../../components/icons/delete";
import Personapop from "../leftSction/Popover/Personapop";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { withAllContexts } from "../../../HOCs";
import "./style.css";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import axios from "axios";
import environment from "../../../config";
import { v4 as uuidv4 } from "uuid";
const boxStyle = {
  border: "none",
  position: "relative",
  // paddingRight: 12,
};
const connectPointStyle = {
  position: "absolute",
  width: 10,
  height: 10,
  borderRadius: "50%",

  top: "13px",
  left: "270px",
  zIndex: 10000,
  // float: "right",
};

const useStyles = makeStyles((theme) => ({
  parent99: {
    marginTop: "8px",
    // marginLeft: "2%",
    marginBottom: "3%",
    backgroundColor: "#fff",
    textAlign: "left",
    border: "1px solid #d8d8d8",
    borderRadius: 6,
  },
  Attachment: {
    fontSize: 18,
    marginTop: "1px",
    marginRight: "3px",
    color: "#545465",
  },
  step: {
    // marginLeft: '1%',
    fontSize: 14,
    marginTop: ".6%",
    cursor: "pointer",
    color: "#9696a9",
    fontFamily: "fontSemiBold",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginLeft: "6px",
  },
  stepDivParent: {
    padding: "0px 30px 10px",
    marginTop: "1%",
    marginBottom: "1%",
  },
  stepDivParent1: {
    padding: "0px 30px 0px ",
    marginTop: "1%",
    // marginBottom: "1%",
  },
  personaDiv: {
    padding: "8px 44px 16px",
  },
  stepDiv: {
    // marginTop: "2%",
    display: "flex",
    overflowX: "auto",
    // paddingBottom: '1%',
    "&::-webkit-scrollbar": {
      // height: "3px",
      display: "none",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  newBtn: {
    fontSize: 16,
    fontFamily: "fontSemiBold",
    textTransform: "none",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#e34d65",
    backgroundColor: "#fff",
    width: "fit-content",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  newAct: {
    marginTop: 16,
    background: "transparent",
    fontSize: 16,
    fontFamily: "fontSemiBold",
    textTransform: "none",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#110f47",
    marginBottom: "15px",
    width: "fit-content",
    maxWidth: 266,
    minWidth: 266,
    justifyContent: "end",
    marginLeft: "3%",
    "&:hover": {
      background: "transparent",
    },
  },
  flag: {
    transform: "rotate(179deg)",
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderRight: "0px solid transparent",

    position: "absolute",
    left: -2,
    top: -3,
  },
  newPersona: {
    fontSize: 14,
    fontFamily: "fontSemiBold",
    textTransform: "none",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#e34d65",
    padding: 0,
  },
  avtr: {
    width: 30,
    height: 30,
    backgroundColor: "#fcc223",
    cursor: "pointer",
  },
  avtr1: {
    width: 30,
    height: 30,
    backgroundColor: "#e34d65",
    cursor: "pointer",
  },
  avtr2: {
    width: 30,
    height: 30,
    backgroundColor: "#007aff",
    cursor: "pointer",
  },
  moreIcon: {
    fontSize: 20,
  },
  divderDiv: {
    display: "flex",
  },
  addNewBtnDiv: {
    minWidth: 114,
  },
  InputDiv: {
    marginBottom: "9%",
    cursor: "grab",
  },
  popover: {
    "& .MuiPopover-paper": {
      boxShadow: "4px 6px 13px 0 rgba(17, 15, 71, 0.06)",
      width: "240px",
    },
  },
  drop: {
    marginRight: "3%",
    "& .ddcontainer": {
      display: "block !important",
    },
  },
  arrow: {
    height: "1px",
    backgroundColor: "red",
    position: "relative",

    "&:after": {
      position: "absolute",
      content: "",
      display: "inline-block ",
      left: "-2px",
      top: "-5px",
      width: "0px",
      height: "0px",
      borderTop: "5px solid transparent",
      borderBottom: "5px solid transparent",
      borderLeft: "5px solid red",
      borderRight: "5px solid transparent",
    },
  },
  dotted: {
    backgroundImage: "linear-gradient(90deg, #fff 5px, red 0)",
    backgroundSize: "10px 3px",
    backgroundPosition: "0 100%",
  },
  deleteDiv: {
    height: "100%",
    width: 100,

    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
  },
  Arrowsvghidden: {
    "& svg": {
      display: "none",
    },
  },
}));

function Body(props) {
  const { selectedarrIndex } = props;
  const classes = useStyles();
  const { Data, setScenes } = props.scenes;
  const { color, setColor } = props.color;

  // to add new Persona section
  const addPersona = (val) => {
    let selectedScene = props?.selectedScene;
    var personaCountUpdate = props?.selectedScene?.personas?.length + 1;
    var levelNo = props?.selectedScene?.steps?.length;
    var levelJsonData = [];
    for (let index = 0; index < levelNo; index++) {
      var a = {
        personaLevelId: personaCountUpdate,
        childId: index + 1,
        level: [],
      };
      levelJsonData.push(a);
    }
    // let createPersona = {
    //   storyId: selectedScene?.storyid,
    //   qpasid: "",
    //   persona_id: val?.id,
    //   projectname: val?.name?.toUpperCase(),
    //   PlayId: selectedScene?.playid,
    //   ActId: selectedScene?.actid,
    //   SceneId: selectedScene?.sceneid,
    //   persona_actions: levelJsonData,
    // };
    // console.log(createPersona);
    debugger;
    var newPersona = {
      storyId: selectedScene?.storyid,
      qpasid: "",
      PlayId: selectedScene?.playid,
      ActId: selectedScene?.actid,
      SceneId: selectedScene?.sceneid,
      personaParentId: personaCountUpdate,
      personaLevel: personaCountUpdate,
      name: val?.name?.toUpperCase(),
      imageurl: val?.imgUrl,
      levels: levelJsonData,
      persona_id: val?.id,
      arrows: [],
      activestatus: true,
    };
    props.selectedScene.personas.push(newPersona);

    // persona collapse open
    var personaindex = personaCountUpdate - 1;
    setopene([...opene, personaindex]);
    setScenes({ ...props.scenes, Data });
    setAnchorep(null);
    props.savefunc();
  };

  // to add new action button in Persona section
  const newActionBtn = (personaId, childId) => {
    let uniquekey =
      props?.selectedScene?.personas?.[personaId]?.levels?.[childId]?.level
        ?.length + 1;
    var newAct = {
      id: uuidv4(),
      uniquekeyid: uniquekey,
      value: "",
    };
    props.selectedScene.personas[personaId].levels[childId].level.push(newAct);
    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  // to add new Step funcation and action on the all persona section
  const createStep = (scene) => {
    var personaCount = props?.selectedScene?.personas?.length;
    var levelNo = props?.selectedScene?.steps?.length + 1;
    // ** for color
    color.push({
      color_id: uuidv4(),
      color_name: `${
        "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6)
      }`,
      activestatus: true,
    });
    setColor({ ...props.color, color });
    // ** to add persone action box on creating new step box
    for (let index = 0; index < personaCount; index++) {
      var a = {
        personaLevelId: index + 1,
        childId: levelNo + 1,
        level: [],
      };
      props.selectedScene.personas[index].levels.push(a);
    }

    const newStep = {
      storyid: scene?.storyid,
      playid: scene?.playid,
      actid: scene?.actid,
      sceneid: scene?.sceneid,
      stepid: uuidv4(),
      stepname: "",
      properties: [],
      document: [],
      function: [],
      digitalEndPoint: [],
      uxChannel: [],
      activestatus: true,
    };
    scene.steps.push(newStep);
    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  // disable textfield
  const [editText, setEditText] = React.useState({
    id: "",
    name: "",
  });

  const editStepName = (id = "", name = "") => {
    setEditText({
      id,
      name,
    });
  };

  const onBlurstep = (e) => {
    editStepName();
  };

  const keyPressStep = (e) => {
    if (e.key === "Enter") {
      onBlurstep(e);
    }
  };

  const onkeypressDown = (e, key, index, levelsIndex, childindex) => {
    if (e.keyCode === 13) {
      if (key === "steps") {
        onEnterStepName(e, index);
      } else {
        onEnterPersonaLevelsChildName(e, index, levelsIndex, childindex);
      }
    } else if (e.keyCode === 27) {
      editStepName();
    }
  };

  const onEnterStepName = (e, index) => {
    props.selectedScene.steps[index].stepname = e.target.value;
    setScenes({ ...props.scenes, Data });
    editStepName();
  };

  const onEnterPersonaLevelsChildName = (
    e,
    personaIndex,
    levelsIndex,
    childindex
  ) => {
    props.selectedScene.personas[personaIndex].levels[levelsIndex].level[
      childindex
    ].value = e.target.value;
    setScenes({ ...props.scenes, Data });
    editStepName();
  };

  const disableFunc = (i) => {
    setEditText(i);
  };

  const onChangeVal = (e) => {
    setEditText({
      ...editText,
      name: e.target.value,
    });
  };

  const colorChange = (e, Borderindex) => {
    console.log(Borderindex + " Borderindex");
    console.log(e.target.value + " e.target.value");
    color[Borderindex].color_name = e.target.value;
    setColor({ ...props.color, color });
  };

  // selectoption value

  const checkboxValue = (val, key, index) => {
    if (
      props?.selectedScene?.steps?.[index]?.[key]?.find(
        (d) => d?.value === val?.value
      )
    ) {
      const modifySteps = props?.selectedScene?.steps?.[index]?.[key]?.filter(
        (l) => l?.value !== val?.value
      );
      props.selectedScene.steps[index][key] = modifySteps;
    } else {
      props.selectedScene.steps[index][key].push(val);
    }
    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  // popover

  const [anchorep, setAnchorep] = React.useState(null);
  const [anchorec, setAnchorec] = React.useState(null);

  const [img, setImg] = React.useState("");
  const [personaIndex, setPersonaIndex] = React.useState("");

  const [selectPersona, setSelectPersona] = React.useState({
    value: null,
    index: "",
  });

  const openp = Boolean(anchorep);
  const id1 = openp ? "simple-popover" : undefined;

  const openc = Boolean(anchorec);
  const idc = openc ? "simple-popover" : undefined;

  const handleClickpersona = (event) => {
    debugger;
    setAnchorep(event.currentTarget);
  };

  // open popover to change persona
  const handleClickc = (e, item, index) => {
    setAnchorec(e.currentTarget);
    setSelectPersona({
      ...selectPersona,
      value: item,
      index,
    });
    // setImg(img);
    // setPersonaIndex(personaIndex);
    setAnchorPersona(null);
  };

  const handleCloseper = () => {
    setAnchorep(null);
  };

  const handleClosec = () => {
    setAnchorec(null);
  };

  // edit persona avtr and name
  const editPersonasave = (val) => {
    props.selectedScene.personas[selectPersona.index].name =
      val?.name?.toUpperCase();
    props.selectedScene.personas[selectPersona.index].imageurl = val?.imgUrl;
    setScenes({ ...props.scenes, Data });
    setAnchorec(null);
    setAnchorPersona(null);
    props.savefunc();
  };

  // deleteStep
  const handleDeleteStep = (step, index) => {
    debugger;
    // const filteredSteps = props?.selectedScene?.steps?.filter(
    //   (_) => _?.stepid !== step?.stepid
    // );
    props.selectedScene.steps[index].activestatus = false;

    props.selectedScene.personas.map((_) => {
      _.levels[index].level.map((l, i) => {
        if (_.arrows.some((ar) => ar.start === l.id || ar.end === l.id)) {
          _.arrows = _?.arrows?.filter(
            (_) => !(_.start === l.id || _.end === l.id)
          );
        }
      });

      _.levels.splice(index, 1);
      return _;
    });

    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  // action delete
  const handleDeleteAction = (
    levelitem,
    personaIndex,
    levelsIndex,
    childindex
  ) => {
    const filteredChildLevel = props?.selectedScene?.personas?.[
      personaIndex
    ]?.levels?.[levelsIndex]?.level?.filter((_) => _?.id !== levelitem?.id);
    props.selectedScene.personas[personaIndex].levels[levelsIndex].level =
      filteredChildLevel;
    setScenes({ ...props.scenes, Data });
    props.savefunc();

    // const ad = arrows.filter((v) => {
    //   if (v.start !== ids && v.end !== ids) {
    //     return v;
    //   }
    // });
    // setArrows(ad);
  };

  // const [dragIndex, setDragIndex] = React.useState(null)
  // const dragged = index => {
  //   setDragIndex(index)
  // }

  // action drag and drop function
  const dropped = (e, personaIndex, levelIndex) => {
    if (e.destination) {
      let dragedvalue = props?.selectedScene?.personas?.[
        personaIndex
      ]?.levels?.[levelIndex]?.level?.splice(e.source.index, 1)[0];
      props.selectedScene.personas[personaIndex].levels[
        levelIndex
      ].level.splice(e.destination.index, 0, dragedvalue);
      setScenes({ ...props.scenes, Data });
      props.savefunc();
    }
    // }
  };

  // step drag and drop func
  const stepsfun = (e) => {
    if (e?.destination) {
      let dragedvalue = props?.selectedScene?.steps?.splice(
        e.source.index,
        1
      )?.[0];
      props.selectedScene.steps.splice(e.destination.index, 0, dragedvalue);

      props.selectedScene.personas.filter((v1) => {
        v1.levels.splice(
          e.destination.index,
          0,
          v1.levels.splice(e.source.index, 1)[0]
        );
      });

      // let color = Data.borderColor.splice(e.source.index, 1)[0];
      // Data.borderColor.splice(e.destination.index, 0, color);

      setScenes({ ...props.scenes, Data });
      props.savefunc();
    }
  };

  // drag and drop style
  const getListStyle = (isDraggingOver) => ({
    border: "none",
    outline: "none",
  });

  // drag and drop style step
  const getItemStyle2 = (isDragging, draggableStyle) => ({
    background: isDragging ? "#f5f5f561" : "",
    border: isDragging ? "2px dashed #d4d4d4" : "",
    padding: isDragging ? 10 : "",
    marginRight: "3%",
    //  marginBottom: "6%",
    outline: "none",
    display: "flex",
    ...draggableStyle,
  });

  // drag and drop style action
  const getItemStyle1 = (isDragging, draggableStyle) => ({
    background: isDragging ? "#f5f5f561" : "",
    border: isDragging ? "2px dashed #d4d4d4" : "",
    padding: isDragging ? 10 : "",
    marginRight: "3%",
    marginBottom: "12%",
    outline: "none",
    display: "flex",
    ...draggableStyle,
  });
  const [arrows, setArrows] = useState([]);
  const [arrows1, setArrows2] = useState([]);

  // adding arrow
  const addArrow = ({ start, end, personaIndex }) => {
    if (
      !props.selectedScene.personas[personaIndex]["arrows"]?.some(
        (_) => _?.start === start && _?.end === end
      )
    ) {
      props.selectedScene.personas[personaIndex]["arrows"].push({ start, end });
      setScenes({ ...props.scenes, Data });
      props.savefunc();
    }

    // setArrows([...arrows, { start, end }]);
    // setArrows2([...arrows, { start, end }]);
    // Data.scene[props.selectedarrIndex].scene[props.selectedScene].arrow = [
    //   ...arrows,
    //   { start, end },
    // ];
  };

  const scrollFun = (e) => {
    let scrollLeft = e.target.scrollLeft;
    var classess = document.getElementsByClassName("scroll-part");
    setArrows([...arrows]);
    for (var i = 0; i < classess.length; i++) {
      classess[i].scrollLeft = scrollLeft;
    }
  };
  const ref1 = useRef();
  const [show, setShow] = React.useState(false);

  // arrowdelete poup
  const [anchorDel, setAnchorDel] = React.useState(null);

  const [isdata, setdata] = React.useState({});

  const [selectArrow, setSelectArrow] = React.useState({});

  const handleClose2 = () => {
    setAnchorDel(null);
    setSelectArrow({});
  };
  // arrow delete pop
  const [anchorPersona, setAnchorPersona] = React.useState(null);

  const MorePop = (e, index, val) => {
    setAnchorDel(e.currentTarget);
    setSelectArrow({
      ...selectArrow,
      index,
      val,
    });
  };

  // delete arrow
  const handleDelete = (e) => {
    const filteredArrows = props?.selectedScene?.personas[
      selectArrow?.index
    ]?.arrows?.filter(
      (_) =>
        !(
          _?.start === selectArrow?.val?.start &&
          _?.end === selectArrow?.val?.end
        )
    );

    props.selectedScene.personas[selectArrow.index].arrows = filteredArrows;

    setSelectArrow({});
    setScenes({ ...props.scenes, Data });
    props.savefunc();
  };

  const open2 = Boolean(anchorDel);
  const id2 = open2 ? "Delete" : undefined;

  const open3 = Boolean(anchorPersona);

  const id3 = open3 ? "Delete" : undefined;
  const [arrowdel, setArrowdel] = React.useState([]);

  const PersonaPop = (e, item, index, val) => {
    setAnchorPersona(e.currentTarget);
    setSelectPersona({
      ...selectPersona,
      value: item,
      index,
    });

    // setImg(img);
    // setPersonaIndex(personaIndex);
    setArrowdel(val);
  };
  const handleClosepersona = () => {
    setAnchorPersona(null);
  };

  const handleDeletePersona = async (e) => {
    props.selectedScene.personas[selectPersona?.index].activestatus = false;

    setScenes({ ...props.scenes, Data });
    setAnchorPersona(null);
    props.savefunc();
  };

  const [opene, setopene] = React.useState([]);

  const CollapsePersona = async (e, personaIndex, con, val) => {
    if (opene.includes(personaIndex)) {
      const a = opene.filter((v) => v !== personaIndex);
      setopene(a);
    } else {
      setopene([...opene, personaIndex]);
    }
    // window.scrollTo(0, e.currentTarget)
  };
  const sectiondropped = (e) => {
    if (e.destination) {
      let dragedvalue = props?.selectedScene?.personas?.splice(
        e.source.index,
        1
      )[0];
      props.selectedScene.personas.splice(e.destination.index, 0, dragedvalue);
      setScenes({ ...props.scenes, Data });
      props.savefunc();
    }
  };

  return (
    <div className={classes.parent99}>
      <Popover
        id={id3}
        open={open3}
        anchorEl={anchorPersona}
        onClose={handleClosepersona}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={classes.scroll}
      >
        <div className={classes.deleteDiv} style={{ width: "155px" }}>
          <div
            onClick={(e) =>
              handleClickc(e, selectPersona?.value, selectPersona?.index)
            }
            style={{
              padding: "10px  15px 10px ",
              // borderTop: '1px solid rgba(0, 0, 0, 0.12)',
              // justifyContent: "center",
              display: "flex",
            }}
          >
            <div style={{ paddingRight: "5px", cursor: "pointer" }}>
              <PersonOutlineIcon fontSize="small" />
            </div>
            <div>
              <Typography
                // style={{ cursor: 'pointer' }}
                color="textPrimary"
                style={{ fontSize: 13, fontWeight: 500, cursor: "pointer" }}
              >
                Modify Persona
              </Typography>
            </div>
          </div>
          <div
            onClick={(e) => handleDeletePersona(e)}
            style={{
              padding: "10px  15px 10px ",
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              display: "flex",
              // justifyContent: "center",
            }}
          >
            <div style={{ paddingRight: "5px", cursor: "pointer" }}>
              <DeleteIcon />
            </div>
            <div>
              <Typography
                variant="body2"
                color="textPrimary"
                style={{ fontSize: 13, fontWeight: 500, cursor: "pointer" }}
              >
                <span style={{ cursor: "pointer" }}>Delete</span>
              </Typography>
            </div>
          </div>
        </div>
      </Popover>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorDel}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={classes.scroll}
      >
        <div className={classes.deleteDiv}>
          <div
            onClick={(e) => {
              handleDelete(e);
              handleClose2();
            }}
            style={{
              padding: "10px  15px 10px ",
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              display: "flex",
            }}
          >
            <div style={{ paddingRight: "5px", cursor: "pointer" }}>
              <DeleteIcon />
            </div>
            <div>
              <Typography
                style={{ cursor: "pointer" }}
                variant="body2"
                color="textPrimary"
              >
                Delete
              </Typography>
            </div>
          </div>
        </div>
      </Popover>
      <div className={classes.stepDivParent}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <div style={{ display: "flex" }}>
            <img
              style={{ marginTop: "3px" }}
              height="11px"
              src={Stepicon}
              alt="Stepicon"
            />

            <Typography variant="subtitle1" className={classes.step}>
              Functions
            </Typography>
          </div>
          <div className={classes.addNewBtnDiv}>
            <Button
              variant="text"
              color="default"
              className={classes.newBtn}
              disableElevation
              startIcon={<AddIcon style={{ fontSize: 16 }} />}
              onClick={(e) => createStep(props?.selectedScene)}
            >
              New Function
            </Button>
          </div>
        </div>
        <DragDropContext onDragEnd={(e) => stepsfun(e)}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={classes.stepDiv + " scroll-part"}
                onScroll={(e) => scrollFun(e)}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {props?.steps?.map((step, index) => {
                  if (step.activestatus) {
                    return (
                      <Draggable
                        draggableId={JSON.stringify(index)}
                        index={index}
                        key={JSON.stringify(index)}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle2(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div>
                              <Input
                                steps={true}
                                step={step}
                                editText={editText}
                                doubleClick={editStepName}
                                onBlurstep={onBlurstep}
                                onkeypressDown={(e) => {
                                  onkeypressDown(e, "steps", index);
                                }}
                                // keyPressStep={keyPressStep}
                                handleDelete={() =>
                                  handleDeleteStep(step, index)
                                }
                                key={index}
                                value={step?.stepname}
                                borderColor={color[index]?.color_name}
                                //borderColor={'#59b191'}
                                Borderindex={index}
                                onChange={onChangeVal}
                                colorChange={colorChange}
                                selectedarrIndex={selectedarrIndex}
                                index={index}
                                savefunc={props.savefunc}
                                // {...Data.scene[selectedarrIndex].scene[
                                //   props.parentid
                                // ].steps[index].label}
                                parentid={props.parentid}
                                checkboxValue={(val, key) =>
                                  checkboxValue(val, key, index)
                                }
                                digitalEndPoint={step?.digitalEndPoint ?? []}
                                uxChannel={step?.uxChannel ?? []}
                              />
                            </div>{" "}
                          </div>
                        )}
                      </Draggable>
                    );
                  } else {
                    return false;
                  }
                })}
                {/* </div> */}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div className="arrowsssss dotteded" style={{ width: "100%" }}></div>
      <DragDropContext onDragEnd={(e) => sectiondropped(e)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {props?.personas?.map((item, personaIndex) => {
                if (item.activestatus) {
                  return (
                    <Draggable
                      draggableId={JSON.stringify(personaIndex)}
                      index={personaIndex}
                      key={JSON.stringify(personaIndex)}
                    >
                      {(provided, snapshot) => (
                        <div
                          style={{ outline: "none" }}
                          // style={getItemStyle2(
                          //   snapshot.isDragging,
                          //   provided.draggableProps.style
                          // )}
                        >
                          <>
                            {item?.arrows?.map((ar, i) => {
                              if (opene.includes(personaIndex)) {
                                return (
                                  <Xarrow
                                    start={ar.start}
                                    end={ar.end}
                                    // key={ar.start + '-.' + ar.start}
                                    path={"smooth"}
                                    strokeWidth={
                                      selectArrow?.val?.start === ar.start &&
                                      selectArrow?.val?.end === ar.end
                                        ? 3
                                        : 2.5
                                    }
                                    lineColor={
                                      selectArrow?.val?.start === ar.start &&
                                      selectArrow?.val?.end === ar.end
                                        ? "#00008b82"
                                        : "#007aff"
                                    }
                                    color={
                                      selectArrow?.val?.start === ar.start &&
                                      selectArrow?.val?.end === ar.end
                                        ? "#00008b82"
                                        : "#007aff"
                                    }
                                    headColor={
                                      selectArrow?.val?.start === ar.start &&
                                      selectArrow?.val?.end === ar.end
                                        ? "#00008b82"
                                        : "#007aff"
                                    }
                                    style={{ position: "relative" }}
                                    // passProps={{ onClick: () => dltarrowfun(ar, i) }}
                                    passProps={{
                                      onClick: (e) =>
                                        MorePop(e, personaIndex, ar),
                                    }}
                                  />
                                );
                              } else {
                                return false;
                              }
                            })}
                            <div className={classes.stepDivParent1}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",

                                    width: "20%",
                                    paddingLeft: "20px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <Avatar
                                    onClick={(e) =>
                                      handleClickc(e, item, personaIndex)
                                    }
                                    alt={item.name}
                                    src={item.imageurl}
                                    // className={classes.avatar}
                                    className={
                                      personaIndex % 3 === 0
                                        ? classes.avtr
                                        : personaIndex % 2 === 0
                                        ? classes.avtr1
                                        : classes.avtr2
                                    }
                                  >
                                    {item?.name?.[0]}
                                  </Avatar>
                                  <Typography
                                    variant="h3"
                                    component="span"
                                    className={classes.step}
                                    onClick={(e) =>
                                      handleClickc(e, item, personaIndex)
                                    }
                                  >
                                    {item.name}
                                  </Typography>
                                  <div>
                                    <IconButton
                                      onClick={(e) =>
                                        PersonaPop(
                                          e,
                                          item,
                                          personaIndex,
                                          item.levels
                                        )
                                      }
                                      aria-label="toggle visibility"
                                      edge="end"
                                    >
                                      <MoreHorizIcon
                                        className={classes.moreIcon}
                                      />
                                    </IconButton>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <FlipToFrontIcon
                                  style={{
                                    padding: "0px 8px 1px",
                                    fontSize: 19,
                                    color: "#909090",
                                    fontSize: 18,
                                    marginTop: 6,
                                  }}
                                />
                              </div> */}
                                  {opene.includes(personaIndex) ? (
                                    <IconButton
                                      onClick={(e) =>
                                        CollapsePersona(
                                          e,
                                          personaIndex,
                                          "close",
                                          item.levels
                                        )
                                      }
                                      aria-label="toggle visibility"
                                      edge="end"
                                    >
                                      <ExpandLessIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      onClick={(e) =>
                                        CollapsePersona(
                                          e,
                                          personaIndex,
                                          "open",
                                          item.levels
                                        )
                                      }
                                      aria-label="toggle visibility"
                                      edge="end"
                                    >
                                      <ExpandMoreIcon />
                                    </IconButton>
                                  )}
                                </div>
                              </div>
                              <Collapse
                                in={opene.includes(personaIndex)}
                                timeout="auto"
                                unmountOnExit
                              >
                                <div
                                  className={classes.stepDiv + "  scroll-part"}
                                  onScroll={(e) => scrollFun(e)}
                                >
                                  {item.levels?.map((item, levelIndex) => {
                                    var personaParentId = item.personaLevelId;
                                    var childId = item.childId;
                                    return (
                                      <div
                                        key={levelIndex}
                                        className={classes.drop}
                                      >
                                        <DragDropContext
                                          onDragEnd={(e) =>
                                            dropped(e, personaIndex, levelIndex)
                                          }
                                        >
                                          <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                style={getListStyle(
                                                  snapshot.isDraggingOver
                                                )}
                                              >
                                                {item?.level?.map(
                                                  (levelitem, index) => (
                                                    <Draggable
                                                      draggableId={levelitem.id}
                                                      key={levelitem.id}
                                                      index={index}
                                                    >
                                                      {(provided, snapshot) => (
                                                        <div
                                                          id={levelitem.id}
                                                          style={boxStyle}
                                                          onDragOver={(e) =>
                                                            e.preventDefault()
                                                          }
                                                          onDrop={(e) => {
                                                            if (
                                                              e.dataTransfer.getData(
                                                                "arrow"
                                                              ) === levelitem.id
                                                            ) {
                                                              return;
                                                            } else {
                                                              const refs = {
                                                                start:
                                                                  e.dataTransfer.getData(
                                                                    "arrow"
                                                                  ),
                                                                end: levelitem.id,
                                                                personaIndex,
                                                              };
                                                              addArrow(refs);
                                                            }
                                                          }}
                                                        >
                                                          {!snapshot.isDragging && (
                                                            <div
                                                              onMouseOver={() =>
                                                                setShow(
                                                                  levelitem.id
                                                                )
                                                              }
                                                              onMouseOut={() =>
                                                                setShow(false)
                                                              }
                                                              ref={ref1}
                                                              style={{
                                                                ...connectPointStyle,
                                                                // border: borderfun(levelitem.id)
                                                                //   ? ".225rem solid" +
                                                                //     borderColor[levelIndex]
                                                                //   : show === levelitem.id
                                                                //   ? ".225rem solid" +
                                                                //     borderColor[levelIndex]
                                                                //   : ".225rem solid transparent",

                                                                border:
                                                                  show ===
                                                                  levelitem.id
                                                                    ? ".225rem solid" +
                                                                      color[
                                                                        levelIndex
                                                                      ]
                                                                        ?.color_name
                                                                    : //"#59b191"
                                                                      ".225rem solid transparent",
                                                                // display:
                                                                //   show === levelIndex
                                                                //     ? "block"
                                                                //     : "none",
                                                              }}
                                                              draggable
                                                              onDragStart={(
                                                                e
                                                              ) => {
                                                                e.dataTransfer.setData(
                                                                  "arrow",
                                                                  levelitem.id
                                                                );
                                                              }}
                                                            />
                                                          )}
                                                          <div
                                                            onMouseOver={() =>
                                                              setShow(
                                                                levelitem.id
                                                              )
                                                            }
                                                            onMouseOut={() =>
                                                              setShow(false)
                                                            }
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            // className={classes.InputDiv}
                                                            style={getItemStyle1(
                                                              snapshot.isDragging,
                                                              provided
                                                                .draggableProps
                                                                .style
                                                            )}
                                                          >
                                                            <Input
                                                              {...provided}
                                                              key={index}
                                                              index={index}
                                                              editText={
                                                                editText
                                                              }
                                                              doubleClick={
                                                                editStepName
                                                              }
                                                              onBlurstep={
                                                                onBlurstep
                                                              }
                                                              onkeypressDown={(
                                                                e
                                                              ) => {
                                                                onkeypressDown(
                                                                  e,
                                                                  "persona",
                                                                  personaIndex,
                                                                  levelIndex,
                                                                  index
                                                                );
                                                              }}
                                                              value={
                                                                levelitem?.value
                                                              }
                                                              level={levelitem}
                                                              onChange={
                                                                onChangeVal
                                                              }
                                                              handleDelete={() =>
                                                                handleDeleteAction(
                                                                  levelitem,
                                                                  personaIndex,
                                                                  levelIndex,
                                                                  index
                                                                )
                                                              }
                                                              colorChange={
                                                                colorChange
                                                              }
                                                              Borderindex={
                                                                levelIndex
                                                              }
                                                              borderColor={
                                                                color[
                                                                  levelIndex
                                                                ]?.color_name
                                                              }
                                                              // borderColor={
                                                              //   "#59b191"
                                                              // }
                                                              savefunc={
                                                                props.savefunc
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}{" "}
                                              </div>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                        <Button
                                          variant="text"
                                          color="default"
                                          className={classes.newAct}
                                          disableElevation
                                          startIcon={"+"}
                                          onClick={() =>
                                            newActionBtn(
                                              personaIndex,
                                              levelIndex
                                            )
                                          }
                                        >
                                          New Action
                                        </Button>
                                      </div>
                                    );
                                  })}
                                </div>
                              </Collapse>
                            </div>

                            {/* <div className={classes.divderDiv}>
              <Divder />
            </div> */}
                            <div
                              className="arrowsssss dotteded"
                              style={{ width: "100%" }}
                            ></div>
                          </>
                        </div>
                      )}
                    </Draggable>
                  );
                } else {
                  return false;
                }
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className={classes.personaDiv}>
        <Button
          variant="text"
          color="secondary"
          className={classes.newPersona}
          disableElevation
          startIcon={"+"}
          onClick={handleClickpersona}
          aria-describedby={id1}
        >
          ADD PERSONA
        </Button>
        <Popover
          id={id1}
          open={openp}
          anchorEl={anchorep}
          onClose={handleCloseper}
          // anchorReference="anchorPosition"
          // anchorPosition={{ top: 355, left: 250 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className={classes.popover}
        >
          <Personapop main handleChange={addPersona} />
        </Popover>
        <Popover
          id={idc}
          open={openc}
          anchorEl={anchorec}
          onClose={handleClosec}
          // anchorReference="anchorPosition"
          // anchorPosition={{ top: 265, left: 250 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className={classes.popover}
        >
          <Personapop
            main
            value={selectPersona?.value}
            handleChange={editPersonasave}
          />
        </Popover>
      </div>
    </div>
  );
}
export default withAllContexts(Body);

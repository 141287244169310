import React from "react";

export default function Body() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 16 16"
    >
      <defs>
        <filter id="i8fg95h0ra">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.329412 0 0 0 0 0.329412 0 0 0 0 0.396078 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none">
        <g>
          <g>
            <g
              filter="url(#i8fg95h0ra)"
              transform="translate(-539 -485) translate(448 474) translate(16 11)"
            >
              <g>
                <path
                  fill="#000"
                  d="M6.154 0c-.201-.003-.391.093-.508.257-.117.163-.147.374-.08.564H3.75c-.754 0-1.46.377-1.877 1.004L.9 3.282h-.08c-.222-.003-.429.114-.54.305-.113.192-.113.429 0 .62.111.192.318.309.54.306h.313c.053.008.106.009.16.003l1.014 9.468C2.43 15.125 3.403 16 4.55 16h6.076c1.148 0 2.122-.875 2.244-2.016l1.015-9.468c.052.006.105.005.156-.003h.317c.222.003.428-.114.54-.305.112-.192.112-.43 0-.62-.112-.192-.318-.31-.54-.306h-.081l-.971-1.457c-.418-.627-1.124-1.004-1.878-1.004H9.614c.067-.19.037-.4-.08-.564-.117-.164-.307-.26-.508-.257H6.154zM3.75 2.05h7.68c.343 0 .662.171.853.457l.516.774H2.38l.516-.774c.19-.286.509-.457.853-.457zM2.531 4.513h10.117l-1 9.34c-.057.526-.491.916-1.02.916H4.55c-.529 0-.962-.39-1.019-.916l-1-9.34z"
                  transform="translate(75)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core";
// import DataJson from "../../../../data.json";
import TouchAppOutlinedIcon from '@material-ui/icons/TouchAppOutlined';
import AcUnitOutlinedIcon from '@material-ui/icons/AcUnitOutlined';




const useStyles = makeStyles((theme) => ({
  icon: {
    color: "grey",
    fontSize: 20,
    margin: "12px",
  },
  textwidth: {
    borderRadius: 0,
    padding: "8px 0px 8px 8px",
  },
  headtext: {
    fontSize: 13,
    color: "black",
    fontWeight: 700,
    display: "inline-flex",
    paddingLeft: "10px",
  },
  search: {
    color: "grey",
    fontSize: 19,
    justifyContent: "flex-end",
    textAlign: "right!important",
    marginLeft: 149,
  },
}));


export default function Iconlist() {
  const classes = useStyles();
 

  

  return (
    
    <div className={classes.textwidth}>
      {/* {DataJson.library.Icons.map(({ id, icon}, i) => {
        return (
          <div key={i}>
            <div>
              <p className={classes.icon}>{icon}</p>
            </div>
          </div>
        );
      })} */}
      <TouchAppOutlinedIcon className={classes.icon} />
      <AcUnitOutlinedIcon  className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
      <TouchAppOutlinedIcon className={classes.icon} />
    </div>
  );
}

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import CreatPlay from "./creatPlay";
import "./accordionstyle.css";
// import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
// import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import TextsmsIcon from "@material-ui/icons/Textsms";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
// import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "../../../components/icons/delete";
// import DataJson from "../../../data.json";
import { Popover, List, ListItem, ListItemText } from "@material-ui/core";
import { withAllContexts } from "../../../HOCs";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import { PopoverComponent } from "../../../components/popover";
import Alerts from "../../../components/alert/index";
import {
  getEnterpriceModuler,
  SaveEnterPriseModuler,
  SaveSchema,
} from "../../../components/functions/commonapi";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiAccordion-root::before": {
      background: "none",
    },
    marginTop: 35,
  },
  Accordion: {
    boxShadow: "none",
    borderRadius: "6px",
    border: "1px solid  rgba(17, 15, 71, 0.08)",
    // marginBottom: 10,
    "&:hover": {
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
      "& $dragdropIcon": {
        display: "block",
      },
    },
    // height: '58px',
    "& .MuiAccordionSummary-root": {
      minHeight: "58px",
      padding: "0px 24px",
    },
  },
  dragdropIcon: {
    padding: 0,
    marginRight: 10,
    display: "none",
  },
  line: {
    padding: "0px 16px",
    borderRight: "2px solid  rgba(17, 15, 71, 0.08)",
    height: "25px",
  },
  heading: {
    padding: "0px 16px",
    color: "#110f47",
    fontFamily: "fontBoldItalic",
    fontSize: "16px",
    fontWeight: "bold",
    // fontStyle: "italic",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginTop: "auto",
    marginBottom: "auto",
  },
  Chip: {
    padding: "11px 5px",
    borderRadius: "6px",
    backgroundColor: "#110f47",
    color: "#ffff",
    fontSize: "12px",
    fontFamily: "fontRegular",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.5px",
    "& .MuiChip-labelSmall": {
      paddingLeft: "4px",
      paddingRight: "4px",
    },
    "&:hover": {
      backgroundColor: "#110f47",
    },
  },
  AccordionDetails: {
    padding: "0px",
    // borderBottom: '1px solid rgba(17, 15, 71, 0.08)',
  },
  more: {
    padding: "0px",
    // float: "right",
  },
  morehovers: {
    display: "block",
  },
  addplay: {
    marginLeft: "17px",
    padding: "6px 16px",
    color: "#e34d65",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    letterSpacing: "normal",
    marginTop: "auto",
    marginBottom: "auto",
    cursor: "pointer",
  },
  deleteDiv: {
    height: "fit-content",
    width: 100,
    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
    "& .MuiPaper-elevation8": {
      boxShadow: "#959da533 0px 8px 24px",
      border: "1px solid #eee",
    },
    "& .MuiList-padding": {
      padding: 0,
    },
  },

  textfield: {
    " & .MuiInputBase-input": {
      backgroundColor: "whitesmoke !important",
    },
    "& textarea": {
      fontWeight: 500,
    },
  },
  input: {
    "& textarea": {
      fontWeight: 500,
    },
  },
  flexRowEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

//  Accordionsummary Style
const AccordionSummary = withStyles({
  root: {
    "&:hover": {
      "& .MuiAccordionSummary-content": {
        "& div": {
          "& .makeStyles-morehovers-24": {
            display: "block",
          },
        },
      },
    },
  },
  expandIcon: {
    order: -1,
    width: "10px",
    height: "10px",
    border: "1px solid rgba(17, 15, 71, 0.08)",

    color: "black",
    backgroundColor: "white !important",
    "&$expanded": {
      transform: "rotate(90deg)",
    },
  },
  expanded: {
    background: "whitesmoke",
  },
})(MuiAccordionSummary);

// main component
const ControlAccordions = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const { Data, setStories } = props.stories;
  const { allkpi } = props.kpi;

  const [err, setErr] = React.useState(false);
  const [text, setText] = React.useState("");
  // const [edit, setEdit] = React.useState(false);
  const [editPlay, setEditPlay] = React.useState({
    id: "",
    name: "",
  });
  const [anchorDel, setAnchorDel] = React.useState(null);
  const [togglePlay, setTogglePlay] = React.useState(false);
  const [storyId, setStoryId] = useState({});

  //accordion onchange event
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // onkeypress to save edited  play text
  const onKeyPress = (storyIndex, playIndex) => {
    if (editPlay?.name?.trim() === "") {
      setEditPlay({
        ...editPlay,
        name: "",
      });
      setDuplicate(true);
      setDupmsg("Textfield should not be empty!");
    } else {
      const checkPlayName = Data?.[storyIndex]?.plays?.some(
        (_) =>
          _?.playname?.replace(/\n/g, "")?.toLowerCase()?.trim() ===
            editPlay?.name?.replace(/\n/g, "")?.toLowerCase()?.trim() &&
          _?.playid !== editPlay?.id
      );
      if (checkPlayName) {
        setDuplicate(true);
        setDupmsg("The play already exists in this storyline!");
        setEditPlay({
          ...editPlay,
          name: editPlay?.name?.replace(/\n/g, "")?.trim(),
        });
      } else {
        Data[storyIndex]["plays"][playIndex].playname = editPlay?.name;
        setStories({
          ...props.stories,
          Data,
        });
        onBlurPlay();
      }
    }
  };

  const onBlurPlay = () => {
    setEditPlay({
      id: "",
      name: "",
    });
    if (duplicate) {
      setDuplicate(false);
      setDupmsg("");
    }
  };

  //  handle submit to show create play
  // const [answerType2 , setAnswerType2] = React.useState(null);
  const onChangeAnswerType2 = (dataValue, id, index) => {
    let data = Data;
    let dupbool = data[index]["plays"][id].addKPI.filter(
      (v) => v.value === dataValue.value
    );

    // for tag api add and remove
    if (dupbool.length > 0) {
      let dup = data[index]["plays"][id].addKPI.filter(
        (v) => v.value !== dataValue.value
      );
      data[index]["plays"][id].addKPI = dup;
      props.savefunc();
    } else {
      props.savefunc();

      data[index]["plays"][id].addKPI.push(dataValue);
    }
    setStories({ ...props.datajson, Data: data });
  };
  const onChangeAct = (dataValue, id, index, actIndex) => {
    debugger;
    let data = Data;
    let dupbool = data[index]["plays"][id].acts[actIndex].actkpi?.filter(
      (v) => v.value === dataValue.value
    );
    if (dupbool.length > 0) {
      let dup = data[index]["plays"][id].acts[actIndex].actkpi.filter(
        (v) => v.value !== dataValue.value
      );
      data[index]["plays"][id].acts[actIndex].actkpi = dup;
      props.savefunc();
    } else {
      props.savefunc();
      console.log(dataValue, id, index, actIndex);

      data[index]["plays"][id].acts[actIndex].actkpi.push(dataValue);
    }
    setStories({ ...props.datajson, Data: data });
  };

  const handlePlay = (story, index) => {
    if (text?.trim() === "") {
      setErr(true);
      setText("");
    } else {
      const checkPlayName = Data?.[index]?.plays?.some(
        (_) =>
          _?.playname?.replace(/\n/g, "")?.toLowerCase()?.trim() ===
            text?.replace(/\n/g, "")?.toLowerCase()?.trim() && _?.activestatus
      );
      if (checkPlayName) {
        setDuplicate(true);
        setDupmsg("The play already exists in this storyline!");
        setText(text?.replace(/\n/g, "")?.trim());
      } else {
        var newPlay = {
          storyid: story?.storyid,
          playid: uuidv4(),
          playname: text,
          actLength: "0 Act",
          acts: [],
          addKPI: [],
          activestatus: true,
        };

        Data[index]["plays"].push(newPlay);
        Data[index].playsLength = `${Data?.[index]?.plays?.length} ${
          Data?.[index]?.plays?.length > 1 ? "Plays" : "Play"
        }`;

        setStories({
          ...props.stories,
          Data,
        });
        props.savefunc();
        escapePlay();
      }
    }
  };

  const escapePlay = () => {
    text && setText("");
    err && setErr(false);
    togglePlay && setTogglePlay(false);
    duplicate && setDuplicate(false);
    setDupmsg("");
  };

  const playBlurEvent = (e) => {
    if (!e.relatedTarget) {
      escapePlay();
    }
  };

  // onkeypress to show create play

  const onkeypressplay = (e, story, index) => {
    if (e.keyCode === 13) {
      handlePlay(story, index);
    } else if (e.keyCode === 27) {
      escapePlay();
    }
  };

  // handledoubleclick to enable edit

  const onDoubleClick = (play) => {
    setEditPlay({
      id: play?.playid,
      name: play?.playname,
    });
  };

  // handleclick to add new play textfield
  const handleClick = (index) => {
    setTogglePlay(true);
  };

  const keydownPlay = (e, storyIndex, playIndex) => {
    if (e.keyCode === 27) {
      onBlurPlay();
    } else if (e.keyCode === 13) {
      onKeyPress(storyIndex, playIndex);
    }
  };

  // create story functions
  const [editstory, setEditstory] = React.useState({
    id: "",
    name: "",
  });

  const [expanded1, setExpanded1] = React.useState(false);
  const [duplicate, setDuplicate] = React.useState(false);
  const [dupmsg, setDupmsg] = React.useState(false);

  // double click story edit
  const onDoubleClickstory = (storyid, storyname) => {
    setEditstory({
      id: storyid,
      name: storyname,
    });
  };

  // key press save edit story
  const onKeyPressstory = (index, storyid) => {
    if (
      !editstory?.name ||
      editstory?.name.length <= 0 ||
      !/([A-Za-z]{1}[\w\-]+)$/y.test(editstory?.name)
    ) {
      setDuplicate(true);
      setDupmsg("Please provide valid story name !");
    } else {
      const checkStoryName = Data?.some(
        (_) =>
          _?.storyname?.toLowerCase() === editstory?.name?.toLowerCase() &&
          _?.activestatus &&
          _?.storyid !== storyid
      );
      if (checkStoryName) {
        setDuplicate(true);
        setDupmsg("The storyline already exists !");
      } else {
        if (Data?.[index]) {
          Data[index].storyname = editstory?.name;
          setStories({ ...props.stories, Data });
          onBlurStory();
        }
      }
    }
  };

  const onBlurStory = () => {
    setEditstory({
      id: "",
      name: "",
    });
    setDuplicate(false);
    setDupmsg("");
  };

  const keydownStory = (e, index, storyid) => {
    if (e.keyCode === 27) {
      setEditstory({
        id: "",
        name: "",
      });
    } else if (e.keyCode === 13) {
      onKeyPressstory(index, storyid);
    }
  };

  // Story accordion open function
  const handleChange1 = (panel) => (event, isExpanded) => {
    setExpanded1(isExpanded ? panel : false);
    setExpanded(false);
  };

  // act create

  const onKeyPressact = (
    value,
    story,
    storyIndex,
    play,
    playIndex,
    callBack
  ) => {
    if (value?.trim() === "") {
      setDuplicate(true);
      setDupmsg("Textfield should not be empty!");
    } else {
      const checkPlayName = Data?.[storyIndex]?.plays?.[playIndex]?.acts?.some(
        (_) =>
          _?.actname?.replace(/\n/g, "")?.toLowerCase()?.trim() ===
            value?.replace(/\n/g, "")?.toLowerCase()?.trim() && _?.activestatus
      );
      if (checkPlayName) {
        setDuplicate(true);
        setDupmsg("The scene already exists in this act!");
      } else {
        var newAct = {
          storyid: story?.storyid,
          playid: play?.playid,
          actid: uuidv4(),
          actname: value,
          actkpi: [],
          activestatus: true,
        };

        if (Data?.[storyIndex]?.plays?.[playIndex]?.acts) {
          Data[storyIndex].plays[playIndex].acts.push(newAct);
          Data[storyIndex].plays[playIndex].actLength = `${
            Data?.[storyIndex]?.plays?.[playIndex]?.acts?.length
          } ${
            Data?.[storyIndex]?.plays?.[playIndex]?.acts?.length > 1
              ? "Acts"
              : "Act"
          }`;
        }

        setStories({
          ...props.stories,
          Data,
        });
        callBack();
        props.savefunc();
      }
    }
  };

  const open2 = Boolean(anchorDel);
  const id2 = open2 ? "Delete" : undefined;
  const MorePop = (e, item, i) => {
    e.stopPropagation();
    item.index = i;
    setStoryId(item);
    setAnchorDel(e.currentTarget);
  };

  const handleClose2 = () => {
    setStoryId({});
    setAnchorDel(null);
  };

  // handelDelete for story
  const handleDeletestory = () => {
    //const filteredStory = Data?.filter((_) => _?.storyid !== storyId?.storyid);
    Data[storyId?.index].activestatus = false;
    Data[storyId?.index].plays.forEach((play) => {
      play.activestatus = false;
      play.acts.forEach((act) => {
        act.activestatus = false;
      });
    });
    setStories({ ...props.stories });
    setAnchorDel(null);
    props.savefunc();
  };

  // handelDelete play
  const handleDeletePlay = (storyIndex, playIndex) => {
    // const filteredPlays = Data[storyIndex]?.plays?.filter(
    //   (_, i) => i !== playIndex
    // );
    // Data[storyIndex].plays = filteredPlays;
    let count = 0;
    Data[storyIndex].plays[playIndex].activestatus = false;
    Data[storyIndex].plays[playIndex].acts.forEach((act) => {
      act.activestatus = false;
    });
    Data[storyIndex].plays.forEach((play) => {
      if (play.activestatus) {
        count += 1;
      }
    });
    Data[storyIndex].playsLength = `${count} ${count > 1 ? "Plays" : "Play"}`;

    setStories({ ...props.stories, Data });
    props.savefunc();
  };

  // handledelete act card
  const handleDeleteAct = (storyIndex, playIndex, actIndex) => {
    // const filteredActs = Data?.[storyIndex]?.plays?.[playIndex]?.acts?.filter(
    //   (_, i) => i !== actIndex
    // );
    // Data[storyIndex].plays[playIndex].acts = filteredActs;
    let count = 0;
    Data[storyIndex].plays[playIndex].acts[actIndex].activestatus = false;
    Data[storyIndex].plays[playIndex].acts.forEach((act) => {
      if (act.activestatus) {
        count += 1;
      }
    });
    Data[storyIndex].plays[playIndex].actLength = `${count} ${
      count > 1 ? "Acts" : "Act"
    }`;

    setStories({ ...props.datajson, Data });
    props.savefunc();
  };

  const dragEnd = (e) => {
    if (e?.destination) {
      let dragedvalue = Data?.splice(e.source.index, 1)[0];
      Data.splice(e.destination.index, 0, dragedvalue);
      setStories({ ...props.stories, Data });
      // props.savefunc();
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    marginBottom: 10,
    ...draggableStyle,
  });

  return (
    <Grid container>
      {/* {console.log('DataData',Data)} */}

      <Grid item xs={12} sm={12} md={12} xl={12} className={classes.root}>
        <DragDropContext onDragEnd={(e) => dragEnd(e)}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                // style={getListStyle(snapshot.isDraggingOver)}
              >
                {Data &&
                  Data?.map((story, index) => {
                    if (story?.activestatus) {
                      return (
                        <Draggable
                          draggableId={JSON.stringify(index)}
                          index={index}
                          key={JSON.stringify(index)}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Accordion
                                key={index}
                                expanded={
                                  expanded1?.storyid === story?.storyid
                                    ? true
                                    : false
                                }
                                onChange={handleChange1(story)}
                                className={classes.Accordion}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ArrowRightIcon fontSize="large" />
                                  }
                                  className={classes.Accordioncontent}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Grid container>
                                    <Grid item xs={10} sm={10} md={10} xl={10}>
                                      <div style={{ display: "flex" }}>
                                        <div className={classes.line}></div>

                                        {story.storyid === editstory?.id ? (
                                          <TextField
                                            id={story.storyid}
                                            value={editstory?.name}
                                            style={{ padding: "0px 16px" }}
                                            className={
                                              expanded1
                                                ? classes.textfield
                                                : classes.input
                                            }
                                            onClick={(event) =>
                                              event.stopPropagation()
                                            }
                                            onFocus={(event) =>
                                              event.stopPropagation()
                                            }
                                            onKeyDown={(e) =>
                                              keydownStory(
                                                e,
                                                index,
                                                story.storyid
                                              )
                                            }
                                            fullWidth
                                            multiline
                                            autoFocus={true}
                                            autoComplete="off"
                                            onChange={(event) =>
                                              setEditstory({
                                                ...editstory,
                                                name: event.target.value.trim(),
                                              })
                                            }
                                            onBlur={(e) => onBlurStory()}
                                          />
                                        ) : (
                                          <Typography
                                            onClick={(event) =>
                                              event.stopPropagation()
                                            }
                                            onFocus={(event) =>
                                              event.stopPropagation()
                                            }
                                            className={classes.heading}
                                            onDoubleClick={(e) =>
                                              onDoubleClickstory(
                                                story.storyid,
                                                story.storyname
                                              )
                                            }
                                            id={story.storyid}
                                          >
                                            {story.storyname}
                                          </Typography>
                                        )}

                                        <div>
                                          <Chip
                                            size="small"
                                            onClick={(event) =>
                                              event.stopPropagation()
                                            }
                                            onFocus={(event) =>
                                              event.stopPropagation()
                                            }
                                            className={classes.Chip}
                                            label={story.playsLength}
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      xl={2}
                                      className={classes.morehovers}
                                    >
                                      <div className={classes.flexRowEnd}>
                                        <IconButton
                                          {...provided.dragHandleProps}
                                          className={classes.dragdropIcon}
                                        >
                                          <FlipToFrontIcon
                                            style={{
                                              // padding: "0px 8px 1px",
                                              // fontSize: 19,
                                              color: "#909090",
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton
                                          aria-label="settings"
                                          onClick={(e) =>
                                            MorePop(e, story, index)
                                          }
                                          onFocus={(event) =>
                                            event.stopPropagation()
                                          }
                                          className={classes.more}
                                        >
                                          <MoreVertIcon
                                            style={{ color: "#b1a7a7bd" }}
                                          />
                                        </IconButton>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </AccordionSummary>

                                {story?.plays &&
                                  story?.plays?.map((play, j) => {
                                    if (play.activestatus) {
                                      return (
                                        <AccordionDetails
                                          key={j}
                                          className={classes.AccordionDetails}
                                        >
                                          <CreatPlay
                                            onChangePlayName={(val) => {
                                              setEditPlay({
                                                ...editPlay,
                                                name: val,
                                              });
                                            }}
                                            onDoubleClick={() =>
                                              onDoubleClick(play)
                                            }
                                            keydownPlay={(e) =>
                                              keydownPlay(e, index, j)
                                            }
                                            edit={editPlay}
                                            story_id={play.storyid}
                                            story_title={play.storyname}
                                            onBlurPlay={(e) => onBlurPlay()}
                                            expanded={
                                              expanded?.playid === play?.playid
                                                ? true
                                                : false
                                            }
                                            onClick={handleChange(play)}
                                            id={j}
                                            playname={play.playname}
                                            actLength={play.actLength}
                                            acts={play.acts}
                                            play={play}
                                            parentid={index}
                                            handleDeletePlay={() =>
                                              handleDeletePlay(index, j)
                                            }
                                            handleDeleteAct={(actIndex) =>
                                              handleDeleteAct(
                                                index,
                                                j,
                                                actIndex
                                              )
                                            }
                                            onKeyPressact={(value, callBack) =>
                                              onKeyPressact(
                                                value,
                                                story,
                                                index,
                                                play,
                                                j,
                                                callBack
                                              )
                                            }
                                            KPI={allkpi}
                                            addKPI={play.addKPI}
                                            onChangeAct={onChangeAct}
                                            onChangeAnswerType2={
                                              onChangeAnswerType2
                                            }
                                            setDuplicate={setDuplicate}
                                            setDupmsg={setDupmsg}
                                            icon={
                                              <TextsmsIcon
                                                style={{ fontSize: "1rem" }}
                                              />
                                            }
                                          />
                                        </AccordionDetails>
                                      );
                                    } else {
                                      return false;
                                    }
                                  })}

                                <AccordionDetails
                                  style={{ padding: "10px 0px" }}
                                >
                                  <div>
                                    {togglePlay ? (
                                      <div style={{ padding: "20px 33px" }}>
                                        <TextField
                                          id="standard-basic"
                                          value={text}
                                          fullWidth
                                          style={{ width: "180%" }}
                                          multiline
                                          autoComplete="off"
                                          autoFocus={true}
                                          onChange={(e) =>
                                            setText(e.target.value)
                                          }
                                          className={classes.input}
                                          onKeyDown={(e) =>
                                            onkeypressplay(e, story, index)
                                          }
                                          onBlur={(e) => playBlurEvent(e)}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton
                                                  onClick={(e) =>
                                                    handlePlay(story, index)
                                                  }
                                                >
                                                  <AddIcon />
                                                </IconButton>
                                                <IconButton
                                                  onClick={(e) => escapePlay()}
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }}
                                          placeholder="Create New Play"
                                          error={err}
                                          helperText={
                                            err
                                              ? "Textfield should not be empty"
                                              : ""
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        onClick={() => handleClick(index)}
                                        className={classes.addplay}
                                      >
                                        + Add New Play
                                      </div>
                                    )}
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          )}
                        </Draggable>
                      );
                    } else {
                      return false;
                    }
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Popover
          id={id2}
          open={open2}
          anchorEl={anchorDel}
          onClose={handleClose2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.deleteDiv}>
            <List component="nav" aria-labelledby="nested-list-subheader">
              <ListItem button onClick={() => handleDeletestory()}>
                <DeleteIcon />
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ paddingLeft: 8 }}
                        color="textPrimary"
                      >
                        Delete
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </div>
        </Popover>
        {duplicate && (
          <Alerts
            open={duplicate}
            closeAlert={() => {
              setDuplicate(false);
            }}
            vertical={"top"}
            horizontal={"right"}
            msg={dupmsg}
            severity="error"
          />
        )}
      </Grid>
    </Grid>
  );
};
export default withAllContexts(ControlAccordions);

/*
 * @authored by porkalanchiyam
 */

const getScene = ({
  story_title,
  story_id,
  play_title,
  play_id,
  act_id,
  act_name,
  arrow,
}) => {
  return {
    storyName: story_title,
    storyId: story_id,
    playName: play_title,
    playid: play_id,
    actid: act_id,
    actName: act_name,
    scene: [
      {
        id: 1,
        arrow: arrow,

        value: "Scene 1",
        title: {
          label: {
            Browser: false,
            Mobile_App: false,
            Desktop_App: false,
            Plugin: false,
            channel1: false,
            channel2: false,
            channel3: false,
            channel4: false,
          },
          projectName: [],
          projectChips: [],
          projectChips1: [],
        },
        properties: [],
        steps: [
          {
            name: "step",
            addproptextfield: [],
            label: {
              Browser: false,
              Mobile_App: false,
              Desktop_App: false,
              Plugin: false,
              channel1: false,
              channel2: false,
              channel3: false,
              channel4: false,
            },
            propval: {
              id: 0,
              value: "",
              properties: [
                {
                  id: 1,
                  value: "CONFIGURATION",
                  count: "0",
                  addprop: [],
                  prop: [],
                },
                {
                  id: 2,
                  value: "INTELLIGENCE",
                  count: "0",
                  addprop: [],
                  prop: [],
                },
                {
                  id: 3,
                  value: "SMART ASSIST",
                  count: "0",
                  addprop: [],
                  prop: [],
                },
                {
                  id: 4,
                  value: "DIGITAL WALL",
                  count: "0",
                  addprop: [],
                  prop: [],
                },
                {
                  id: 5,
                  value: "RULES",
                  count: "0",
                  addprop: [],
                  prop: [],
                },
                {
                  id: 6,
                  value: "INSIGHTS",
                  count: "0",
                  addprop: [],
                  prop: [],
                },
                {
                  id: 7,
                  value: "OUTCOME",
                  count: "0",
                  addprop: [],
                  prop: [],
                },
                {
                  id: 8,
                  value: "EVENT ACTIONS",
                  count: "0",
                  addprop: [],
                  prop: [],
                },
                {
                  id: 9,
                  value: "VALIDATION",
                  count: "0",
                  addprop: [],
                  prop: [],
                },
              ],
              addfuntextfield: [],
              addproptextfield: [],
              function: [],
              document: [],
              chips: [],
              chips1: [],
            },
          },
        ],
        persona: [],
      },
    ],
  };
};

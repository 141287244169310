import React from "react";
import {
  makeStyles,
  Popover,
  List as MUList,
  ListItem,
  ListItemText,
  Button,
  ListItemIcon,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  root: {},
  btn: {
    padding: 0,
    fontSize: 12,
    "& .MuiButton-label": {
      textTransform: "uppercase !important",
      whiteSpace: "nowrap",
    },
  },
  muList: {
    "& .MuiListItem-gutters": {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
}));

export const PopoverComponent = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const changeLabel = (data) => {
    // handleClose();
    props.onChange(data);
  };
  // const checkBox = (data) => {
  //   debugger;
  //   let len = props.selectedValue.length;
  //   for (let i = 0; i < len; i++) {
  //     if (data.value === props.selectedValue[i].value) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  return (
    <div className={classes.root}>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        className={classes.btn}
        style={{
          color: props?.selectedValue?.length > 0 ? "#007AFF" : "#7B7B84",
        }}
      >
        {props?.selectedValue?.length > 0 ? (
          <span>
            {/* {props?.selectedValue?.map((v) => {
              return <>{v.label}</>;
            })} */}
            {props?.selectedValue?.[0].label}
            {props?.selectedValue?.length > 1 && (
              <>&nbsp;+ {props?.selectedValue?.length - 1} </>
            )}
          </span>
        ) : (
          props?.label
        )}

        <ExpandMoreIcon fontSize="small" />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MUList dense className={classes.muList}>
          {props?.data &&
            props?.data.map((_, i) => {
              return (
                <ListItem
                  key={_.value}
                  dense
                  button
                  onClick={() => {
                    changeLabel(_);
                  }}
                >
                  <ListItemText primary={_.label} />
                  {props?.selectedValue?.filter((v) => v.value === _.value)
                    .length > 0 && (
                    <ListItemIcon
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <CheckIcon fontSize="small" htmlColor="green" />
                    </ListItemIcon>
                  )}
                </ListItem>
              );
            })}
        </MUList>
      </Popover>
    </div>
  );
};

PopoverComponent.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array, //[{value:'',label:''}]
  selectedValue: PropTypes.array,
  onChange: PropTypes.func,
};

PopoverComponent.defaultProps = {
  label: "Select",
};

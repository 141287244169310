import React, { useRef } from "react";
// import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  IconButton,
  // FormControl,
  // InputAdornment,
  // OutlinedInput,
} from "@material-ui/core";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import MoreVertIcon from "@material-ui/icons/MoreVert";

// import DataJson from "../../../data.json";
const useStyles = makeStyles((theme) => ({
  parent: {
    backgroundColor: "#F1F0F5",
    minHeight: "6.6vh",
    marginTop: "1%",
    border: "1px solid #d8d8d8",
    borderRadius: 6,
    // marginLeft: '2%',
    padding: "4px 4px",
    alignItems: "center",
    scrollBehavior: "smooth",
    display: "flex",
    justifyContent: "space-between",
  },
  newBtn: {
    backgroundColor: "white",
    color: "#f50057",
    // padding: '10px 18px',
    fontSize: 15,
    textTransform: "none",
    marginRight: "1%",
    borderRadius: 6,
    border: "1px solid #cecece",
    fontWeight: 400,
    minWidth: "105px",
    height: "35px",
    marginTop: 1,
    boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
    "&:hover": {
      color: "#fff",
    },
  },
  newBtn1: {
    // backgroundColor: "white",
    backgroundColor: "#007AFF",
    color: "#fff",
    // padding: "0px 15px",
    fontSize: 15,
    textTransform: "none",
    fontWeight: 600,
    marginRight: "1%",
    borderRadius: 6,
    // border: "1px solid #cecece",
    minWidth: "105px",
    // height: "42px",
    marginTop: 1,
    boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
    border: "none",
    height: "36px",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#007AFF",
    },
  },
  unselectedbtn: {
    backgroundColor: "white",
    color: "black",
    // padding: "10px 18px",
    fontWeight: 300,
    fontSize: 15,
    textTransform: "none",
    marginRight: "2%",
    borderRadius: 6,
    border: "1px solid #cececeb5",
    minWidth: "105px",
    // height: "42px",
    marginTop: 1,
    height: "36px",
    boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
    "&:hover": {
      backgroundColor: "white",
      color: "#000",
    },
  },
  iconSize: {
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.87)",
  },
  screen: {
    textAlign: "left",
    display: "flex",
    overflowX: "auto",
    width: "100%",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      // width: "1px",
      display: "none",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  inputField: {
    width: "22ch",
    backgroundColor: "white",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 122 255)",
      borderWidth: "2px",
    },
    "& input": {
      backgroundColor: "white",
      padding: "14px 0px 14px 12px",
      fontSize: "14px",
      fontWeight: 600,
    },
    "& button": {
      padding: 6,
    },
  },
}));

export default function Body(props) {
  const classes = useStyles();
  const ref = useRef(null);
  // const handleBack = () => {
  //   props.history.push("/");
  // };

  const scroll = (scrollOffset) => {
    debugger;
    ref.current.scrollLeft += scrollOffset;
  };

  return (
    <Grid className={classes.parent}>
      <Grid>
        <IconButton onClick={() => scroll(-800)}>
          <ArrowBackIosIcon className={classes.iconSize} />
        </IconButton>
      </Grid>
      <Grid className={classes.screen} ref={ref}>
        {props.scenes?.map((val, i) => (
          <Button
            key={i}
            variant="contained"
            color={props.selectedScene === val?.sceneid ? "primary" : ""}
            className={
              props.selectedScene?.sceneid === val?.sceneid
                ? classes.newBtn1
                : classes.unselectedbtn
            }
            disableElevation
            onClick={
              props.selectedScene?.sceneid !== val?.sceneid ? () => props.selectBtn(val) : null
            }
          >
            Scene {i+1}
          </Button>
        ))}
        <Button
          style={{ minWidth: "127px" }}
          variant="contained"
          color="secondary"
          onClick={() => props.createScene()}
          className={classes.newBtn}
          disabled={props.disabled}
          disableElevation
        >
          + New Scene
        </Button>
      </Grid>
      <Grid>
        <IconButton onClick={() => scroll(800)}>
          <ArrowForwardIosIcon className={classes.iconSize} />
        </IconButton>
      </Grid>
    </Grid>
  );
}

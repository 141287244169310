import React from "react";
import {
  makeStyles,
  Typography,
  Divider,
  Collapse,
  ListItemAvatar,
  Button,
  Avatar,
  ListSubheader,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Delete } from "@material-ui/icons";
import { withAllContexts } from "../../../../HOCs";
import Text from "../components/textfield";
import EditIcon from "@material-ui/icons/Edit";
const useStyles = makeStyles((theme) => ({
  parentDiv: {
    height: "100%",
    width: 300,
  },
  title: {
    backgroundColor: "#F8F7F9",
  },
  titleText: {
    fontSize: "14px",
    color: "#110f47",
    fontFamily: "fontSemiBold",
    margin: 0,
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },

  dotIcon: {
    minWidth: 28,
    marginTop: 6,
  },

  closeIcon: {
    fontSize: 16,
  },
  func: {
    color: "#110f47",
    fontSize: "14px",
    fontFamily: "fontSemiBold",
    fontWeight: "800px",
  },
  purple: {
    width: "21px",
    height: "21px",
    backgroundColor: "#110f47",
    fontFamily: "fontRegular",
    fontSize: "12px",
  },
  funcbtn: {
    fontSize: "14px",
    fontFamily: "fontRegular",
    color: "#e34d65",
  },
  add: {
    color: "#e34d65",
    fontSize: "11px",
    fontWeight: 500,
    fontStretch: "normal",
    lineHeight: "1.71",
    letterSpacing: "normal",
    "&:hover": {
      backgroundColor: "#fff",
    },
    padding: "10px 19px 10px",
  },
}));

function Function(props) {
  const classes = useStyles();
  // const { Data, setData } = props.datajson;

  const [opene, setopene] = React.useState(false);

  const handleClick = (f) => {
    if (f === opene) {
      setopene(false);
      commanFunctionClearInput();
    }
    else{
      setopene(f);
    }
  };

  const [addNewFunction,setAddNewFunction] = React.useState({
    open:false,
    value:'',
    error:{
      value:false,
      message:''
    }
  })

  const [addNewSubFunction,setAddSubNewFunction] = React.useState({
    open:false,
    value:'',
    error:{
      value:false,
      message:''
    }
  })

  const [editFunction,setEditFunction] = React.useState({
    open:false,
    value:'',
    id:'',
    error:{
      value:false,
      message:''
    }
  })

  const [editSubFunction,setEditSubFunction] = React.useState({
    open:false,
    value:'',
    id:'',
    error:{
      value:false,
      message:''
    }
  })

  
  //add functionalities start

  const onChangeFunctionText = (e) =>{
    setAddNewFunction({
      ...addNewFunction,
      value:e.target.value
    })
  }

  const clearAddFunction = (open=false,value="",error=false,message="") =>{
    setAddNewFunction({
      ...addNewFunction,
      open,
      value,
      error:{
        value:error,
        message
      }
    })
  }

  const onAddNewFunctionKeyDown = (e) =>{
    if (e.keyCode === 13) {
      checkAddFunctionText()
    }
    else if (e.keyCode === 27) {
      clearAddFunction();
    }
  }
  
  const checkAddFunctionText = () =>{
    if(addNewFunction?.value?.trim() === "" || props?.function?.some(_=>_?.name?.toLowerCase()?.trim() === addNewFunction?.value?.toLowerCase()?.trim())){
      setAddNewFunction({
        ...addNewFunction,
        value:addNewFunction?.value?.trim(),
        error:{
          value:true,
          message:addNewFunction?.value?.trim() === "" ? 'Please provide valid input' : 'Function name already exists'
        }
      })
    }
    else{
      props.keypressfun(addNewFunction?.value);
      clearAddFunction();
    }
  }
  //add functionalities end

  //edit functionalities start

  const onChangeEditFunctionText = (e) =>{
    setEditFunction({
      ...editFunction,
      value:e.target.value
    })
  }

  const clearEditFunction = (open=false,value,error=false,message="") =>{
    setEditFunction({
      ...editFunction,
      open,
      value:value?.name,
      id:value?.id,
      error:{
        value:error,
        message
      }
    })
  }

  const onEditNewFunctionKeyDown = (e,index) =>{
    if (e.keyCode === 13) {
      checkEditFunctionText(index)
    }
    else if (e.keyCode === 27) {
      clearEditFunction();
    }
  }

  const checkEditFunctionText = (index) =>{
    if(
      editFunction?.value?.trim() === "" || 
      props?.function?.some(_=>_?.name?.toLowerCase()?.trim() === editFunction?.value?.toLowerCase()?.trim() && 
      editFunction?.id !== _?.id
    )){
      setEditFunction({
        ...editFunction,
        value:editFunction?.value?.trim(),
        error:{
          value:true,
          message:editFunction?.value?.trim() === "" ? 'Please provide valid input' : 'Function name already exists'
        }
      })
    }
    else{
      props.handleKeyPressEditFunction(editFunction?.value,index);
      clearEditFunction();
    }
  }  

  //edit functionalities end

  //add sub functionalities start

  const onChangeSubFunctionText = (e) =>{
    setAddSubNewFunction({
      ...addNewSubFunction,
      value:e.target.value
    })
  }

  const clearAddSubFunction = (open=false,value="",error=false,message="") =>{
    setAddSubNewFunction({
      ...addNewSubFunction,
      open,
      value,
      error:{
        value:error,
        message
      }
    })
  }

  const onAddNewSubFunctionKeyDown = (e,functionIndex) =>{
    if (e.keyCode === 13) {
      checkAddSubFunctionText(functionIndex)
    }
    else if (e.keyCode === 27) {
      clearAddSubFunction();
    }
  }

  const checkAddSubFunctionText = (functionIndex) =>{
    if(addNewSubFunction?.value?.trim() === "" || props?.function?.[functionIndex]?.subFunctions?.some(_=>_?.name?.toLowerCase()?.trim() === addNewSubFunction?.value?.toLowerCase()?.trim())){
      setAddSubNewFunction({
        ...addNewSubFunction,
        value:addNewSubFunction?.value?.trim(),
        error:{
          value:true,
          message:addNewSubFunction?.value?.trim() === "" ? 'Please provide valid input' : 'Sub function name already exists'
        }
      })
    }
    else{
      props.keypresssubfun(addNewSubFunction?.value,functionIndex);
      clearAddSubFunction();
    }
  }

   //add sub functionalities end

    //edit sub functionalities start

  const onChangeEditSubFunctionText = (e) =>{
    setEditSubFunction({
      ...editSubFunction,
      value:e.target.value
    })
  }

  const clearEditSubFunction = (open=false,value,error=false,message="") =>{
    setEditSubFunction({
      ...editSubFunction,
      open,
      value:value?.name,
      id:value?.id,
      error:{
        value:error,
        message
      }
    })
  }

  const onEditNewSubFunctionKeyDown = (e,funcIndex,subFuncIndex) =>{
    if (e.keyCode === 13) {
      checkEditSubFunctionText(funcIndex,subFuncIndex)
    }
    else if (e.keyCode === 27) {
      clearEditSubFunction();
    }
  }

  const checkEditSubFunctionText = (funcIndex,subFuncIndex) =>{
    if(
      editSubFunction?.value?.trim() === "" || 
      props?.function?.[funcIndex]?.subFunctions?.some(_=>_?.name?.toLowerCase()?.trim() === editSubFunction?.value?.toLowerCase()?.trim() && 
      editSubFunction?.id !== _?.id
    )){
      setEditSubFunction({
        ...editSubFunction,
        value:editSubFunction?.value?.trim(),
        error:{
          value:true,
          message:editSubFunction?.value?.trim() === "" ? 'Please provide valid input' : 'Sub function name already exists'
        }
      })
    }
    else{
      props.keypresseditsubfun(funcIndex,subFuncIndex,editSubFunction?.value)
      clearEditSubFunction();
    }
  }

   //add sub functionalities end

   //comman input field cleared
   const commanFunctionClearInput = (key,item) =>{
    clearAddFunction(key === "addFunction")
    clearEditFunction(key === "editFunction",item)
    clearAddSubFunction(key === "addSubFunction")
    clearEditSubFunction(key === "editSubFunction",item)
}



  return (
    <List
      component="nav"
      style={{ paddingBottom: "0px" }}
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ backgroundColor: "#F8F7F9" }}
        >
          <div className={classes.title}>
            <p className={classes.titleText}>
              {opene ? (
                <>
                  <span>
                    <IconButton
                      component="span"
                      onClick={() => handleClick(opene)}
                    >
                      <ArrowBackIosIcon className={classes.closeIcon} />
                    </IconButton>

                    <span style={{ marginTop: "auto", marginBottom: "auto" }}>
                      Sub Function
                    </span>
                  </span>

                  {/* <IconButton component="span" onClick={props.closeHandle}>
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton> */}
                </>
              ) : (
                <>
                  <span>Functions</span>
                  {props.scene ? (
                    <IconButton component="span" onClick={props.closeHandle}>
                      <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </>
              )}
            </p>
          </div>
        </ListSubheader>
      }
    >
      {props?.function?.map((item, f) => {
        return (
          <>
            {opene && <Divider />}
            <ListItem
              key={f}
              button
              style={{
                paddingLeft: 12,
              }}
            >
              {editFunction?.id === item?.id ? (
                <>
                  <Text
                    placeholder="Add Function"
                    value={editFunction?.value}
                    onChange={onChangeEditFunctionText}
                    onKeyDown={(e) => onEditNewFunctionKeyDown(e,f)}
                    onClickadd={(e) => checkEditFunctionText(f)}
                    onClickdelete={() => clearEditFunction(false)}
                    error={editFunction?.error?.value}
                    helperText={editFunction?.error?.message}
                  />
                </>
              ) : (
                <ListItemText
                  onClick={() => handleClick(item.id)}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.func}
                        color="textPrimary"
                      >
                        {item.name}
                      </Typography>
                    </React.Fragment>
                  }
                />
              )}

              <div
                // style={{ marginTop: "auto" }}
                onClick={(e) => commanFunctionClearInput('editFunction',item)}
              >
                <EditIcon style={{ fontSize: "17px", color: "#34b69c" }} />
              </div>
              <div
                // style={{ marginTop: "auto" }}
                onClick={(e) => props.deleteFunction(item)}
              >
                <Delete style={{ fontSize: "17px", color: "#34b69c" }} />
              </div>
              {item.id === opene ? (
                ""
              ) : (
                <Avatar className={classes.purple}>{item.subFunctions?.length}</Avatar>
              )}
            </ListItem>
            <Divider />
              {opene && 
            <Collapse in={item.id === opene} timeout="auto" unmountOnExit>
              {item?.subFunctions?.map((func, s) => {
                return (
                  <>
                    <List component="div" disablePadding key={s}>
                      {editSubFunction?.id === func?.id ? (
                        <ListItem alignItems="flex-start">
                          <div style={{ paddingBottom: "16px" }}>
                            <Text
                              placeholder="Add Subfunction"
                              value={editSubFunction?.value}
                              onChange={onChangeEditSubFunctionText}
                              onKeyDown={(e) =>onEditNewSubFunctionKeyDown(e,f,s)}
                              onClickadd={() =>checkEditSubFunctionText(f,s)}
                              onClickdelete={() => clearEditSubFunction(false)}
                              error={editSubFunction?.error?.value}
                              helperText={editSubFunction?.error?.message}
                            />
                          </div>
                        </ListItem>
                      ) : (
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar className={classes.dotIcon}>
                            <FiberManualRecordIcon
                              style={{ color: "#34b69c", fontSize: 10 }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            onDoubleClick={() =>
                              commanFunctionClearInput('editSubFunction',func)
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="div"
                                  variant="body2"
                                  color="textPrimary"
                                  style={{ cursor: "pointer" }}
                                >
                                  {func?.name}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                          <div
                            style={{ marginTop: "auto",cursor:'pointer' }}
                            onClick={(e) => props.Deletesubfun(f,func)}
                          >
                            <Delete
                              style={{ fontSize: "17px", color: "#34b69c" }}
                            />
                          </div>
                        </ListItem>
                      )}
                    </List>
                    <Divider />
                  </>
                );
              })}
              {
              addNewSubFunction?.open ? (
                <div style={{ padding: "16px" }}>
                    <Text
                      placeholder="Add Subfunction"
                      value={addNewSubFunction?.value}
                      onChange={onChangeSubFunctionText}
                      onKeyDown={(e) => onAddNewSubFunctionKeyDown(e,f)}
                      onClickadd={(e) => checkAddSubFunctionText(f)}
                      onClickdelete={(e) => clearAddSubFunction(false)}
                      error={addNewSubFunction?.error?.value}
                      helperText={addNewSubFunction?.error?.message}
                    />
                </div>
                ) : (
                  <Button
                    className={classes.add}
                    style={{ paddingLeft: 18 }}
                    onClick={(e) => commanFunctionClearInput('addSubFunction')}
                  >
                    + Add new
                  </Button>
                )
              }
            </Collapse> }
          </>
        );
      })}
      {
        addNewFunction?.open ? (
          <div style={{ padding: "16px" }}>
            <Text
              placeholder="Add Function"
              value={addNewFunction?.value}
              onChange={onChangeFunctionText}
              onKeyDown={(e) => onAddNewFunctionKeyDown(e)}
              onClickadd={(e) => checkAddFunctionText()}
              onClickdelete={()=>{clearAddFunction(false)}}
              error={addNewFunction?.error?.value}
              helperText={addNewFunction?.error?.message}
            />
          </div> ) : (
            <>
            {!opene && 
              <ListItem
              button
              onClick={() => {
                commanFunctionClearInput('addFunction')
              }}
              style={{ padding: "7px 16px 0px" }}
            >
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography component="span" className={classes.funcbtn}>
                      + Add New Function
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          }
          </>
          )
      }
    </List>
  );
}
export default withAllContexts(Function);

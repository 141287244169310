import React from "react";
import { checkError, fetchData } from "../components/functions/commonapi";
import { queries } from "../components/functions/queries";
import environment from "../config";
import { Stories } from "./";

const StoriesProvider = (props) => {
  let [stories, setStories] = React.useState({
    Data: [],
  });

  // React.useEffect(() => {
  //   if (!localStorage.qpasid) {
  //     if (window.location.search && window.location.search.length > 0) {
  //       let params = new URLSearchParams(window.location.search);
  //       // localStorage.setItem("metadataId", params.get("metaDataId"));
  //       localStorage.setItem("qpasid", params.get("qpasid"));
  //       localStorage.setItem("projectName", params.get("projectName"));
  //     }
  //   }
  // }, []);

  // React.useEffect(() => {
  //   initail();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [localStorage.qpasid]);
  // async function initail() {
  //   if (!localStorage.qpasid) {
  //     return false;
  //   }
  //   let value = localStorage.qpasid;
  //   try {
  //     const data = await fetchData(
  //       { body: JSON.stringify(queries.story_read(value)) },
  //       `${environment.api_url}/api/read_documents`
  //     );
  //     const { isError, errMsg } = checkError(data);
  //     if (isError) {
  //       alert(errMsg);
  //     } else {
  //       console.log(data.result);
  //       setStories({
  //         Data: data.result,
  //       });
  //     }
  //   } catch (error) {
  //     alert("error");
  //   }
  // }
  return (
    <Stories.Provider value={{ ...stories, setStories }}>
      {props.children}
    </Stories.Provider>
  );
};

export default StoriesProvider;

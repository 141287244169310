import React from "react";
import { Grid, makeStyles, withStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import WebIcon from "@material-ui/icons/Web";
import SearchIcon from "@material-ui/icons/Search";

import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Iconlist from "./librarylist/Iconlist";
// import DataJson from "../../../data.json";
import { withAllContexts } from "../../../HOCs";

const useStyles = makeStyles((theme) => ({
  head: {
    boxShadow: "none",
    width: 210,
    borderTop: "solid 1px rgba(17, 15, 71, 0.08)",
    borderRadius: 0 + " !important",
    margin: 0 + " !important",
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "14px 0px",
    },
    "& .MuiAccordionSummary-content": {
      display: "inline-block",
    },
  },
  accro: {
    marginTop: -5 + " !important",
    marginBottom: -5 + " !important",
    minHeight: "0!important",
  },
  accroexp: {
    margin: "0!important",
    minHeight: "0!important",
  },
  headtext: {
    paddingLeft: 10,
    fontSize: 12,
    color: "#9696a9",
    fontWeight: 500,
    textTransform: "uppercase",
  },
  webicons: {
    fontSize: 17,
    color: "grey",
  },
  imges: {
    width: 200,

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    // display: "flex",
  },
  textwidth: {
    borderRadius: 0,
    padding: "8px 5px 8px 5px",
  },
  search: {
    color: "grey",
    fontSize: 16,
    justifyContent: "flex-end",
    textAlign: "right!important",
  },
  icon: {
    color: "grey",
    fontSize: 20,
    margin: "12px",
  },
  arrowdown: {
    color: "black",
    marginTop: -5,
  },
  expmore: {
    display: "none",
  },
  gridadd: {
    marginLeft: "38px",
  },
}));

const AccordionSummary = withStyles({
  root: {
    "& .MuiAccordionSummary-content": {
      margin: "9px 0px",
    },
  },
  expandIcon: {
    order: -1,
    width: "10px",
    height: "10px",
    // border: "1px solid rgba(17, 15, 71, 0.08)",

    color: "black",
    margin: "0px",
    backgroundColor: "white !important",
    "&$expanded": {
      transform: "rotate(90deg)",
    },
  },
  expanded: {
    // background: "whitesmoke",
  },
})(MuiAccordionSummary);

function Icon(props) {
  const classes = useStyles();
  const { Data } = props.datajson;
  // const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  return (
    <Accordion
      className={classes.head}
      onChange={() => props.handleClickA("icon")}
      expanded={props.openA === "icon"}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        expandIcon={<ArrowRightIcon fontSize="small" />}
        className={classes.accro}
      >
        <Grid container justify="space-between" style={{ marginTop: "2px" }}>
          <Grid item style={{ marginTop: "auto", marginBottom: "auto" }}>
            <div style={{ display: "flex" }}>
              <div>
                <WebIcon className={classes.webicons} />
              </div>
              <div className={classes.headtext}>Icon</div>
            </div>
          </Grid>
          <Grid item>
            <div>
              <SearchIcon className={classes.search} />
            </div>
          </Grid>
        </Grid>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={classes.textwidth}>
        <Iconlist />
      </AccordionDetails>
    </Accordion>
  );
}

export default withAllContexts(Icon);

import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import ControlAccordions from "./accordion/accordion";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from "@material-ui/icons/Add";
// import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
import { withAllContexts } from "../../HOCs";
import { v4 as uuidv4 } from "uuid";
import Alerts from "../../components/alert/index";
import { checkError, fetchData } from "../../components/functions/commonapi";
import { queries } from "../../components/functions/queries";
import environment from "../../config";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  addplay: {
    color: "#e34d65",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    lineHeight: "34px",
    letterSpacing: "normal",
    marginTop: "auto",
    marginBottom: "auto",
    cursor: "pointer",
    padding: "4px 28px",
  },
  paper: {
    // width: "150px",
    // display: "flex",
    padding: "6px",
    border: "1px solid #eee",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #eee",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
    },
  },
}));

function Body(props) {
  const classes = useStyles();
  const { Data, setStories } = props.stories;
  const { setKpi } = props.kpi;
  const [text, setText] = React.useState("");
  const [err, setErr] = React.useState(false);
  const [duplicate, setDuplicate] = React.useState(false);

  const [toggle, setToggle] = React.useState(false);

  // textchnage to get change value and send to story json
  const textChange = (event) => {
    setText(event.target.value.trim());
    //setErr(false);
  };

  const handleSubmit = (e) => {
    if (!text || text.length <= 0 || !/([A-Za-z]{1}[\w\-]+)$/y.test(text)) {
      setErr(true);
    } else {
      const checkStoryName = Data?.some(
        (_) =>
          _?.storyname?.toLowerCase() === text?.toLowerCase() && _?.activestatus
      );
      if (checkStoryName) {
        setDuplicate(true);
      } else {
        var newStory = {
          storyid: uuidv4(),
          storyname: text,
          playsLength: "0 Play",
          plays: [],
          activestatus: true,
        };

        Data.push(newStory);

        setStories({
          ...props.stories,
          Data,
        });

        escapeKey();
        props.savefunc();
      }
    }
  };
  // keypress to create new story
  const onkeypressstory = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    } else if (e.keyCode === 27) {
      escapeKey();
    }
  };

  const escapeKey = () => {
    text && setText("");
    err && setErr(false);
    toggle && setToggle(false);
  };

  const storyBlurEvent = (e) => {
    if (!e.relatedTarget) {
      escapeKey();
    }
  };

  // handleDelete to remove text after keypress
  const handleDelete = (data) => {
    escapeKey();
  };

  // handleClick to create text field
  const handleClick = () => {
    setToggle(true);
  };

  const [loader, setLoader] = React.useState(false);
  // qpasid
  const loaderfun = () => {
    setLoader(true);
    setTimeout(function () {
      setLoader(false);
    }, 2000);
  };
  React.useEffect(() => {
    loaderfun();
  }, []);
  React.useEffect(() => {
    initailMaster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function initailMaster() {
    try {
      //get all the kpi
      const data = await fetchData(
        {
          body: JSON.stringify(queries.kpi_read()),
        },
        `${environment.api_url}/api/read_documents`
      );

      const { isError, errMsg } = checkError(data);
      if (isError) {
        alert(errMsg);
      } else {
        let arr = data?.result?.map((val) => {
          return {
            label: val?.kpi_name,
            value: val?.kpi_id,
          };
        });
        setKpi({
          ...props.kpi,
          allkpi: arr,
        });
      }
    } catch (error) {
      alert("error");
    }
  }

  React.useEffect(() => {
    getQpasIDandMetaId();
  }, []);

  const getQpasIDandMetaId = async () => {
    if (window.location.search && window.location.search.length > 0) {
      let params = new URLSearchParams(window.location.search);
      sessionStorage.setItem("metadata_id", params.get("metadata_id"));
      localStorage.setItem("token", params.get("token"));
      let metadataId = params.get("metadata_id");
      //project details
      let project_details = {};
      const projectDetails = await fetchData(
        {
          body: JSON.stringify(queries.projVsToo(metadataId)),
        },
        `${environment.api_url}/api/read_documents`
      );
      const { isError, errMsg } = checkError(projectDetails);
      if (!isError) {
        project_details = projectDetails?.result[0]?.projectvstools;
      } else {
        alert(errMsg);
      }
      // let project_info = {};
      let tool_id = "";
      if (project_details?.projectid) {
        //to get Entity Tool
        const entity_tool = await fetchData(
          {
            body: JSON.stringify(queries.entityTool()),
          },
          `${environment.api_url}/api/read_documents`
        );
        const { isError, errMsg } = checkError(entity_tool);
        if (!isError) {
          tool_id = entity_tool?.result[0]?.tools?._id;
        } else {
          alert(errMsg);
        }
        //to get metaID
        const metadataparams = await fetchData(
          {
            body: JSON.stringify(
              queries.metaDataId(project_details?.projectid, tool_id)
            ),
          },
          `${environment.api_url}/api/read_documents`
        );
        if (!isError) {
          sessionStorage.setItem(
            "metadataId",
            metadataparams?.result[0]?.projectvstools?.metadataid
          );
          localStorage.setItem(
            "metadataId",
            metadataparams?.result[0]?.projectvstools?.metadataid
          );
          localStorage.setItem(
            "qpasid",
            metadataparams?.result[0]?.projectvstools?.metadataid
          );
        } else {
          alert(errMsg);
        }
      }
    }
  };

  React.useEffect(() => {
    initail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.qpasid]);
  async function initail() {
    if (!localStorage.qpasid) {
      return false;
    }
    let value = localStorage.qpasid;
    try {
      const data = await fetchData(
        { body: JSON.stringify(queries.story_read(value)) },
        `${environment.api_url}/api/read_documents`
      );
      const { isError, errMsg } = checkError(data);
      if (isError) {
        alert(errMsg);
      } else {
        console.log(data.result);
        setStories({
          Data: data.result,
        });
      }
    } catch (error) {
      alert("error");
    }
  }

  return (
    <Grid
    // style={{ paddingRight: "20px" }}
    >
      {loader && (
        <div style={{ textAlign: "center", width: "100%", height: "100vh" }}>
          <Typography
            component="div"
            style={{
              textAlign: "center",
              height: "80vh",
              width: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={25} />
          </Typography>
        </div>
      )}
      {!loader && <ControlAccordions savefunc={props.savefunc} />}

      {!loader && (
        <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
          <Paper className={classes.paper}>
            <div>
              {toggle ? (
                <div style={{ padding: "16px" }}>
                  <TextField
                    id="standard-basic"
                    value={text}
                    style={{ width: "50%" }}
                    autoFocus={true}
                    multiline
                    autoComplete="off"
                    onChange={textChange}
                    onKeyDown={(e) => onkeypressstory(e)}
                    onBlur={(e) => storyBlurEvent(e)}
                    placeholder="Create New Story"
                    error={err}
                    helperText={err ? "Please provide valid story name" : ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={(e) => {
                              handleSubmit(e);
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          <IconButton onClick={(e) => handleDelete(e)}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              ) : (
                <div onClick={() => handleClick()} className={classes.addplay}>
                  + Add New Story
                </div>
              )}
            </div>
          </Paper>
        </div>
      )}
      {duplicate && (
        <Alerts
          open={duplicate}
          closeAlert={() => {
            setDuplicate(false);
          }}
          vertical={"top"}
          horizontal={"right"}
          msg={"The storyline already exists !"}
          severity="error"
        />
      )}
    </Grid>
  );
}

/*
 * @authored by porkalanchiyam
 */

export default withAllContexts(Body);

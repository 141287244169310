import React from "react";

//HOC's
import AppAuth from "./App.auth";
import AppTheme from "./App.theme";
// import AppGQLClient from './App.gqlclient';
import AppAlert from "./App.alert";
import AppErrorBoundary from "./App.error_boundry";
import RouterApp from "./router";
// import ContextProvider from './contexts/contextProvider';
import StoriesProvider from "./contexts/storiesProvider";
import ScenesProvider from "./contexts/scenesProvider";
import LibraryProvider from "./contexts/libraryProvider";
import ColorProvider from "./contexts/colorProvider";
import KpiProvider from "./contexts/kpiProvider";

import "./App.css";

function App() {
  return (
    <AppAuth>
      <AppErrorBoundary>
        <AppTheme>
          {/* <AppGQLClient> */}
          <AppAlert>
            {/* <ContextProvider> */}
            <StoriesProvider>
              <LibraryProvider>
                <ColorProvider>
                  <KpiProvider>
                    <ScenesProvider>
                      <RouterApp />
                    </ScenesProvider>
                  </KpiProvider>
                </ColorProvider>
              </LibraryProvider>
            </StoriesProvider>
            {/* </ContextProvider> */}
          </AppAlert>
          {/* </AppGQLClient> */}
        </AppTheme>
      </AppErrorBoundary>
    </AppAuth>
  );
}

export default App;

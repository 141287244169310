import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PlayHome } from "./../screens";
import { Home } from "./../screens";
import routes from "./routes";
const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={PlayHome} />
        {/* <Route path="/home" component={Home} /> */}
        <Route path={routes.addscene} component={Home} />
        {/* <Route exact path="/" component={Login} /> */}
        {/* <Route path={routes.login} component={Login}/>
        <Route path={routes.signup} component={SignUp}/>
        <PrivateRoute path={routes.profile} component={Profile} /> */}
      </Switch>
    </Router>
  );
};

export default RouterApp;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Typography, Grid, Divider, Button } from "@material-ui/core";

import PersonAdd from "./../../../src/assets/images/group-22.png";
import Chip from "@material-ui/core/Chip";
import SaveIcon from "@material-ui/icons/Save";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Badge from "@material-ui/core/Badge";
import TopNavBar from "../header/navBar";
import { withAllContexts } from "../../HOCs";
import axios from "axios";
import { uuid } from "uuidv4";
import Alerts from "../alert";
import environment from "../../config";
import {
  checkError,
  ConstructUpsert,
  fetchData,
  SaveSchema,
} from "../functions/commonapi";
import { queries } from "../functions/queries";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 95,
  },
  chipdiv1: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  Chip1: {
    padding: "5px 0px 5px 0px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    color: "#110f47 ",

    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",

    lineHeight: "normal",
    letterSpacing: "normal",
    marginTop: "auto",
    marginBottom: "auto",
  },
  subnavbar: {
    minHeight: 54,
    alignItems: "center",
    backgroundColor: "white",
    color: "black",
    // paddingTop: 12,
    // paddingBottom: 12,
    "&.MuiPaper-elevation2": {
      boxShadow: "0 0px 10px 0 #110f4714",
      borderBottom: "1px solid #eee",
    },
  },
  paddingL: {
    paddingLeft: "20px",
  },
  paddingR: {
    paddingRight: "26px",
  },
  hr: {
    marginTop: "auto",
    marginBottom: "auto",
    background: "#e6e5e5cc",
    height: 22,
  },
  hrdiv: {
    paddingLeft: "20px",
    paddingRight: "20px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  title: {
    fontSize: "16px",
    fontFamily: "fontSemiBold",
    fontStretch: "normal",

    lineHeight: "normal",
    letterSpacing: "normal",
    marginTop: "auto",
    marginBottom: "auto",
  },
  small: {
    width: "28px",
    height: "28px",
  },
  medium: {
    width: "34px",
    height: "34px",
  },

  square: {
    borderRadius: 8,
  },
  newBtn: {
    fontSize: 16,
    fontFamily: "fontSemiBold",
    textTransform: "none",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fff",
    width: "fit-content",
    backgroundColor: "#E34D65;",
    "&:hover": {
      backgroundColor: "#E34D65;",
    },
  },
  chipsmall: {
    height: "24px",
    color: "#fff",
    backgroundColor: "#E34D65",
    border: "1px solid #e84e67d1",
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -6,
    top: 15,
    color: "#9696a9",
    fontSize: 13,
  },
}))(Badge);

function MainHeader(props) {
  const classes = useStyles();
  const { Data, setStories } = props.stories;

  const [openAlert, setOpenAlert] = React.useState(false);

  const [isheader, setisheader] = React.useState(true);

  React.useEffect(() => {
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 10) {
        setisheader(false);
      } else {
        setisheader(true);
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);
  const SaveSchemaUpsert = async () => {
    debugger;
    //await SaveSchema(overAllData);
    try {
      props.savefunc("save");
      let overAllData = ConstructUpsert(Data);
      const data = await fetchData(
        { body: JSON.stringify(overAllData) },
        `${environment.api_url}/api/upsert_document`
      );
      const { isError, errMsg } = checkError(data);
      debugger;
      if (isError) {
        alert(errMsg);
      } else {
        localStorage.setItem("qpasid", data.Result[0].properties.doc.qpasid);
        // localStorage.setItem(
        //   "metadataId",
        //   data.Result[0].properties.doc.metadataId
        // );
        let value = data.Result[0].properties.doc.qpasid;
        const dataRead = await fetchData(
          { body: JSON.stringify(queries.story_read(value)) },
          `${environment.api_url}/api/read_documents`
        );
        setStories({
          ...props.stories,
          Data: dataRead.result,
        });
        setOpenAlert(true);
      }
    } catch (error) {
      alert("error");
    }
  };
  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={2} className={`${classes.subnavbar}`}>
        {isheader && (
          <div style={{ width: "100%" }}>
            <TopNavBar />
          </div>
        )}
        <Grid
          alignItems="center"
          justify="space-between"
          direction="row"
          container
          style={{ padding: "8px 10px" }}
        >
          <Grid item xs={12} sm={5}>
            <div style={{ display: "flex" }}>
              <div className={classes.chipdiv1}>
                <Chip variant="outlined" className={classes.Chip1} label="D" />
              </div>
              <div className={classes.hrdiv}>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.hr}
                />
              </div>

              <Typography className={classes.title}>
                {props.header ? props.header : "QPAS"}
              </Typography>

              <div className={classes.hrdiv} style={{ paddingLeft: "70px" }}>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.hr}
                />
              </div>
            </div>
          </Grid>
          <Grid
            // alignItems="center"
            item
            xs={12}
            sm={3}
            style={{ paddingLeft: "18px" }}
          >
            {props.save ? (
              <Chip
                className={classes.chipsmall}
                icon={
                  <SaveIcon
                    style={{ color: "#fff", width: "18px", height: "18px" }}
                  />
                }
                label="Please save your changes"
                color="secondary"
                variant="outlined"
              />
            ) : (
              ""
            )}
          </Grid>

          <Grid
            item
            container
            xs={12}
            sm={4}
            alignItems="center"
            justify="flex-end"
          >
            <div style={{ display: "flex" }}>
              {/* <Button
                color="primary"
                variant="contained"
                onClick={() => SaveSchema()}
                style={{ marginRight: 15 }}
              >
                Save
              </Button> */}
              <div className={classes.chipdiv1}>
                <Avatar
                  className={classes.medium}
                  alt="Remy Sharp"
                  src={PersonAdd}
                />
              </div>
              <div className={classes.hrdiv}>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.hr}
                />
              </div>
              <div className={classes.title}>
                <StyledBadge badgeContent={11} max={4}>
                  <AvatarGroup max={4}>
                    <Avatar
                      alt="Remy Sharp"
                      src="https://picsum.photos/200/300?random=2"
                      className={classes.small}
                    />
                    <Avatar
                      alt="Travis Howard"
                      src="https://picsum.photos/200/300?random=3"
                      className={classes.small}
                    />
                    <Avatar
                      alt="Cindy Baker"
                      src="https://picsum.photos/200/300?random=4"
                      className={classes.small}
                    />
                    <Avatar
                      alt="Agnes Walker"
                      src="https://picsum.photos/200/300?random=5"
                      className={classes.small}
                    />
                  </AvatarGroup>
                </StyledBadge>
              </div>
              <Button
                variant="text"
                color="default"
                className={classes.newBtn}
                style={{ marginLeft: 30 }}
                onClick={() => SaveSchemaUpsert()}
                disableElevation
              >
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </AppBar>
      {openAlert && (
        <Alerts
          open={openAlert}
          closeAlert={() => {
            setOpenAlert(false);
          }}
          vertical={"top"}
          horizontal={"right"}
          msg={"Thanks for saving !"}
        />
      )}
    </div>
  );
}

/*
 * @authored by porkalacchiyam
 */

export default withAllContexts(MainHeader);

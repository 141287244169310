import React from "react";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
// import DataJson from "../../../../data.json";
import { withAllContexts } from "../../../../HOCs";
const useStyles = makeStyles((theme) => ({
  icon: {
    color: "grey",
    fontSize: 20,
    margin: "12px",
  },
  headtext: {
    fontSize: 13,
    color: "black",
    fontWeight: 700,
    display: "inline-flex",
    paddingLeft: "10px",
  },
  avttar: {
    display: "inline-flex",
    margin: "16px 0px 16px 21px",
    borderRadius: "5px",
  },
  // width: {
  //   overflowY: "auto",
  //   height: "45vh",
  // },
}));

function Personalist(props) {
  const classes = useStyles();
  const { Data } = props.library;

  return (
    <div className={classes.width}>
      <div>
        {Data?.mediaLibrary?.map((media, i) => {
          return (
            <Avatar
              key={i}
              alt="Remy Sharp"
              src={media.image_url}
              className={classes.avttar}
            />
          );
        })}
      </div>
    </div>
  );
}

export default withAllContexts(Personalist);

import React from "react";

// import { localStorageKeys } from "../../utils";
// import { withRouter } from "react-router-dom";
// import routes from "../../router/routes";
// import { AppBar } from "@material-ui/core";
import HeaderBar from "../../components/header";
import SubheaderBar from "../../components/subheader";
import Body from "./body";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isheader: true,
      save: false,
    };
  }
  // onUnload = (e) => {
  //   e.preventDefault();
  //   e.returnValue = "";
  // };
  componentDidMount() {
    if (!this?.props?.location?.state) {
      this.props.history.push("/");
      return;
    }
    // window.addEventListener("beforeunload", this.onUnload);
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 10) {
        this.setState({ isheader: false });
      } else {
        this.setState({ isheader: true });
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }

  handleSave = (v) => {
    this.setState({ save: v === "save" ? false : true });
  };

  render() {
    return (
      <div>
        <div
          style={{
            top: 0,
            left: "auto",
            right: 0,
            width: "100%",
            position: "fixed",
            zIndex: 100,
          }}
        >
          {this.state.isheader && (
            <div style={{ width: "100%" }}>
              {" "}
              <HeaderBar
                // title={this.props?.match?.params?.playname?.replace(
                //   /===/g,
                //   "/"
                // )}
                title={this.props?.location?.state?.play?.playname}
              />
            </div>
          )}{" "}
          <div style={{ border: "1px solid #eee" }}>
            <SubheaderBar
              save={this.state.save}
              savefunc={this.handleSave}
              // title={this.props?.match?.params?.actname?.replace(/===/g, "/")}
              title={this?.props?.location?.state?.act?.actname}
              UrlactId={this?.props?.location?.state?.act?.actid}
              UrlplayId={this?.props?.location?.state?.act?.playid}
              UrlstoryId={this?.props?.location?.state?.act?.storyid}
            />
          </div>
        </div>
        <Body
          isheaderhidden={this.state.isheader}
          savefunc={this.handleSave}
          {...this.props}
          UrlactId={this?.props?.location?.state?.act?.actid}
          UrlplayId={this?.props?.location?.state?.act?.playid}
          UrlstoryId={this?.props?.location?.state?.act?.storyid}
        />
      </div>
    );
  }
}

// export default withStyles(styles)(withRouter(Home));
export default Home;

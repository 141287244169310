const routes = {
    login: '/login',
    signup: '/signup',
    home: '/home',
    profile: '/profile',
    playscreen: '/playscreen',
    // addscene: '/addscene/:actname/:actId/:playname/:playId/:storyname/:storyId',
    addscene: '/addscene',
}

export default routes;
import React from "react";
import {
  makeStyles,
  Typography,
  Divider,
  ListSubheader,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  // LinearProgress,
  // Box,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import moment from "moment";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteIcon from "@material-ui/icons/Delete";

import { withAllContexts } from "../../../../HOCs";
import environment from "./../../../../config";

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    height: "100%",
    width: 300,
  },
  title: {
    backgroundColor: "#F8F7F9",
  },
  titleText: {
    fontSize: "14px",
    color: "#110f47",
    fontFamily: "fontSemiBold",
    margin: 0,
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },

  uploadParent: {
    padding: 10,
    paddingBottom: 0,
  },
  uploadListItem: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  uploadText: {
    margin: 0,
    fontSize: 13,
    textAlign: "center",
    color: "gray",
  },
  uploadBorder: {
    border: ".8px solid gray",
    padding: 20,
    borderStyle: "dashed",
    borderRadius: 8,
    backgroundColor: "#FFF",
  },
  uploadIcon: {
    backgroundColor: "#007AFF",
    padding: 16,
    borderRadius: 50,
    color: "#fff",
    fontSize: 32,
    marginBottom: "2%",
  },
  uploadedIcon: {
    padding: 8,
    borderRadius: 50,
    border: "1px solid #ADADBC",
    fontSize: 18,
    marginRight: "6%",
    color: "#ADADBC",
  },
  uploadedDocName: {
    color: "#484671",
    fontSize: 13,
    width: "175px",
  },
  uploadedtimeDate: {
    color: "#BABAC7",
    fontSize: 13,
  },
  closeIcon: {
    fontSize: 16,
  },
  input: {
    opacity: 0,
    position: "absolute",
    padding: "14px",
    height: "120px",
  },
}));

function Document(props) {
  const classes = useStyles();
  // const [uploaddocc, setuploaddocc] = React.useState([]);
  // const [progress, setProgress] = React.useState(0);

  // const handleUpload = (e) => {
  //   e.preventDefault();
  //   let file = e.target.files[0];
  //   const data = {
  //     name: file.name,
  //     lastModified: moment(file.lastModifiedDate).fromNow(true),
  //     load: true,
  //   };
  //   setuploaddocc([...uploaddocc, ...[data]]);
  //   console.log(uploaddocc);
  //   setTimeout(function () {
  //     const data = {
  //       name: file.name,
  //       lastModified: moment(file.lastModifiedDate).fromNow(true),
  //       load: false,
  //     };
  //     setuploaddocc([...uploaddocc, ...[data]]);
  //   }, 2000);
  // };

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 100) {
  //         return 0;
  //       }
  //       const diff = Math.random() * 10;
  //       return Math.min(oldProgress + diff, 100);
  //     });
  //   }, 1000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [uploaddocc]);

  return (
    <List
      style={{ paddingBottom: 0 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ backgroundColor: "#F8F7F9" }}
        >
          <div className={classes.title}>
            <p className={classes.titleText}>
              Documents{" "}
              <IconButton component="span" onClick={props.closeHandle}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </p>
          </div>
        </ListSubheader>
      }
    >
      <div className={classes.uploadParent}>
        <label htmlFor="icon-button-file">
          <input
            accept="application/pdf, application/vnd.ms-excel,application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            className={classes.input}
            name="file"
            // id='icon-button-file'
            type="file"
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={(e) => props.handleUpload(e)}
          />
          <div className={classes.uploadBorder}>
            <div className={classes.uploadText}>
              <DescriptionIcon className={classes.uploadIcon} />

              <p style={{ margin: 0 }}>
                Drag or Upload your documents here (docx, pdf, xlsx)
              </p>
            </div>
          </div>
        </label>
      </div>
      <List className={classes.root} style={{ paddingBottom: 0 }}>
        {props.uploaddocc?.length > 0 && (
          <Divider style={{ background: "#e0e0e096" }} />
        )}
        {props.uploaddocc?.map((_d, index) => {
          return (
            <>
              <ListItem alignItems="center" className={classes.uploadListItem}>
                <InsertDriveFileIcon className={classes.uploadedIcon} />
                <a
                  href={`${environment.downloadUrl}${_d.fileId}`}
                  style={{ textDecoration: "none" }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Tooltip title={_d?.name} aria-label="add">
                          <Typography
                            noWrap
                            component="h6"
                            variant="h6"
                            className={classes.uploadedDocName}
                          >
                            {_d?.name} &nbsp;
                          </Typography>
                        </Tooltip>
                        {
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="h6"
                              className={classes.uploadedtimeDate}
                            >
                              {_d?.lastModified}
                            </Typography>
                            {/* {_d.load && (
                            <Box display="flex" alignItems="center">
                              <Box width="100%" mr={1}>
                                <LinearProgress
                                  variant="determinate"
                                  {...props}
                                  value={props.progress}
                                />
                              </Box>
                              <Box minWidth={35}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {`${Math.round(props.progress)}%`}
                                </Typography>
                              </Box>
                            </Box>
                          )} */}
                          </React.Fragment>
                        }
                      </React.Fragment>
                    }
                  />
                </a>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => props.DeleteDocument(_d, index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>

              <Divider style={{ background: "#e0e0e096" }} />
            </>
          );
        })}
      </List>
    </List>
  );
}
export default withAllContexts(Document);

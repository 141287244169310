import React from 'react';
import { default as PropIcon } from "../components/icons/properties";
import { default as FuncIcon } from "../components/icons/function";
import { default as DocIcon } from "../components/icons/documents";
import DeleteIcon from "../components/icons/delete";

export const themes = {
    default:'default',
    dark:'dark'
} 

export const alertProps = {
    vertical:{
        top:'top',
        bottom:'bottom',
    },
    horizontal:{
        left:'left',
        right:'right',
        center:'center'
    },
    severity:{
        success:'success',
        error:'error',
        warning:'warning',
        info:'info',
    }
}

export const localStorageKeys = {
    auth_token:'auth_token'
}

export const netWorkCallMethods = {
    get:'GET',
    post:'POST',
    put:'PUT',
    delete:'DELETE',
    update:'UPDATE'
}

export const digitalEndPointsOptions = [
    {label:'Mobile',value:'Mobile'},
    {label:'Desktop',value:'Desktop'},
    {label:'Digital Wall',value:'Digital Wall'},
    {label:'TV',value:'TV'},
    {label:'Smart Watch',value:'Smart Watch'},
    {label:'Kiosk',value:'Kiosk'},
  ]

  export const uxChannelOptions = [
    {label:'Browser',value:'Browser'},
    {label:'App',value:'App'},
  ]

  export const stepsSettings = [
      {
          label:'Properties',
          value:'Properties',
          icon:<PropIcon width={18} height={18} color={'rgba(0, 0, 0, 0.54)'} />,
      },
      {
          label:'Function',
          value:'Function',
          icon:<FuncIcon width={18} height={18} color={'rgba(0, 0, 0, 0.54)'} />,
      },
      {
          label:'Document',
          value:'Document',
          icon:<DocIcon width={18} height={18} color={'rgba(0, 0, 0, 0.54)'} />,
      },
      {
          label:'Delete',
          value:'Delete',
          icon:<DeleteIcon />,
      },
  ]

  export const propertiesList = [
    {
      id: 1,
      value: "CONFIGURATION",
    },
    {
      id: 2,
      value: "INTELLIGENCE",
    },
    {
      id: 3,
      value: "SMART ASSIST",
    },
    {
      id: 4,
      value: "DIGITAL WALL",
    },
    {
      id: 5,
      value: "RULES",
    },
    {
      id: 6,
      value: "INSIGHTS",
    },
    {
      id: 7,
      value: "OUTCOME",
    },
    {
      id: 8,
      value: "EVENT ACTIONS",
    },
    {
      id: 9,
      value: "VALIDATION",
    },
  ]
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconsList from "./rightSection/rightIcons";
import Libaray from "./leftSction/library";
import Workspace from "./mainworkspace";
import BackgroundImage from "../../assets/images/background.svg";
import { withAllContexts } from "../../HOCs";
import { checkError, fetchData } from "../../components/functions/commonapi";
import { queries } from "../../components/functions/queries";
import environment from "../../config";

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: "#F8F7F9",
    paddingTop: 18,
    backgroundImage: `url(${BackgroundImage})`,
    marginTop: 90,
  },
  scroll: {
    // overflow: 'auto',
    // height: '82vh',
    // '&::-webkit-scrollbar': {
    //   width: '4px !important',
    //   background: 'whitesmoke'
    // },
    // '&::-webkit-scrollbar-track': {
    //   '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   background: '#d8d8d8 !important',
    //   cursor: 'pointer'
    // }
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "whitesmoke",
    // },
  },
}));

function Body(props) {
  const classes = useStyles();
  //const { Data } = props.datajson;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [zooming, setzooming] = React.useState(100);
  const [selectedarrIndex, setSceneIndex] = React.useState(0);
  const { Data, selectedSceneId, setScenes } = props.scenes;
  const { setColor } = props.color;
  const { setLibrary } = props.library;
  const onUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  React.useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //get getselectedScene
  React.useEffect(() => {
    Data.forEach((val, i) => {
      if (val.sceneid === selectedSceneId) {
        setSceneIndex(i);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSceneId]);

  React.useEffect(() => {
    initail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.qpasid]);
  async function initail() {
    if (!localStorage.qpasid) {
      return false;
    }

    try {
      let qpasid = localStorage.qpasid;
      let storyid = props.UrlstoryId;
      let playid = props.UrlplayId;
      let actid = props.UrlactId;

      //get all the scenes
      const data = await fetchData(
        {
          body: JSON.stringify(
            queries.scene_read(qpasid, storyid, playid, actid)
          ),
        },
        `${environment.api_url}/api/read_documents`
      );

      //get all the color
      const color = await fetchData(
        { body: JSON.stringify(queries.color_read()) },
        `${environment.api_url}/api/read_documents`
      );

      //get library
      const library = await fetchData(
        { body: JSON.stringify(queries.library_read()) },
        `${environment.api_url}/api/read_documents`
      );
      //get persona
      const persona = await fetchData(
        { body: JSON.stringify(queries.persona_read()) },
        `${environment.api_url}/api/read_documents`
      );
      const { isError, errMsg } = checkError(data);
      if (isError) {
        alert(errMsg);
      } else {
        console.log(data.result);
        setColor({
          ...props.color,
          color: color?.result,
        });
        setLibrary({
          ...props.library,
          Data: {
            ...props.library.Data,
            mediaLibrary: library.result,
            personas: persona.result,
          },
        });
        setScenes({
          ...props.scenes,
          Data: data.result,
        });
      }
    } catch (error) {
      alert("error");
    }
  }

  const [isopen, setisopen] = React.useState(false);
  const handlesetzooming = (zoom) => {
    const zom = zoom === "zoomin" ? zooming + 20 : zooming - 20;
    setzooming(zom);
  };
  return (
    <Grid container className={classes.body} justify="center">
      <Grid item sm={isopen ? 2 : 1} style={{ width: !isopen && "72px" }}>
        <Libaray
          savefunc={props.savefunc}
          isopen={isopen}
          isheaderhidden={props.isheaderhidden}
          // selectedArr={selectedarrIndex}
          // UrlactId={props.UrlactId}
          // UrlplayId={props.UrlplayId}
          // UrlstoryId={props.UrlstoryId}
          setisopen={setisopen}
        />
      </Grid>
      <Grid
        xs={isopen ? 9 : 10}
        item
        style={{ zIndex: 10, overflow: "hidden" }}
        className={classes.scroll}
      >
        <Workspace
          selectedarrIndex={selectedarrIndex}
          savefunc={props.savefunc}
          zooming={zooming}
          isopen={isopen}
          UrlactId={props.UrlactId}
          UrlplayId={props.UrlplayId}
          UrlstoryId={props.UrlstoryId}
          {...props}
        />
      </Grid>
      <Grid item xs={isopen ? 1 : 1}>
        <IconsList
          isopen={isopen}
          selectedArr={selectedarrIndex}
          isheaderhidden={props.isheaderhidden}
          handlesetzooming={handlesetzooming}
          UrlactId={props.UrlactId}
          zooming={zooming}
          UrlplayId={props.UrlplayId}
          UrlstoryId={props.UrlstoryId}
          savefunc={props.savefunc}
        />
      </Grid>
    </Grid>
  );
}

export default withAllContexts(Body);

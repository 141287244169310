import React from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  IconButton,
  Typography,
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextField,
  Popover,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormGroup,
  FormControlLabel,
  // Collapse,
} from "@material-ui/core";
import Tick from "../../../components/icons/tick";
// import AddDoc from "../../../components/icons/adddoc";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import TabIcon from "../../../components/icons/tabIcon";
import {digitalEndPointsOptions,uxChannelOptions} from '../../../utils';
const useStyles = makeStyles((theme) => ({
  parent: {
    minHeight: "6.6vh",
    marginTop: ".9%",
    // marginLeft: '2%',
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  newBtn: {
    backgroundColor: "white",
    color: "#e34d65",
    fontFamily: "fontSemiBold",
    padding: "6px 18px",
    fontSize: 14,
    textTransform: "none",
    borderRadius: 6,
    border: "1px solid #cecece",
    "&:hover": {
      color: "#fff",
    },
  },
  iconSize: {
    fontSize: 18,
    border: "1px solid #cecece",
    padding: 6,
    backgroundColor: "white",
    borderRadius: 50,
  },
  screen: {
    textAlign: "left",
    flex: 1,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  screenTitle: {
    fontSize: 16,
    fontFamily: "fontSemiBold",
    color: "#110f47",
    borderLeft: "1px solid #e0e0e0",
    borderRight: "1px solid #e0e0e0",
    paddingLeft: "2%",
    paddingRight: "2%",
    cursor: "pointer",
    marginRight: "10px",
    paddingTop: 4,
  },
  uiMentionBtn: {
    fontFamily: "fontRegular",

    fontSize: "12px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    padding: "0px 14px",
    backgroundColor: "white",

    marginRight: "10px",
    marginTop: "5px",
    color: "#545465",

    textTransform: "none",
    borderRadius: 4,
    minWidth: "fit-content",
  },
  uibtnselect: {
    fontFamily: "fontRegular",
    height: "32px",
    fontSize: "12px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    padding: "0px 14px",
    backgroundColor: "aliceblue",

    marginRight: "10px",
    marginTop: "5px",
    color: "#545465",

    textTransform: "none",
    borderRadius: 4,
    minWidth: "fit-content",
  },
  flex: {
    display: "flex",
    flexWrap: "wrap",
    // marginTop: "10px",
  },
  CommentIconBtn: {
    padding: "4px 8px",
    fontSize: 12,
    backgroundColor: "white",
    // marginLeft: "4%",
    marginTop: "5px",
    border: "1px solid #cecece",
    borderRadius: 30,
  },
  CommentIcon: {
    fontSize: 12,
  },
  MoreHorizIcon: {
    padding: 0,
    marginLeft: "1px",
    marginTop: "5px",
  },
  inputField: {
    width: "40ch",
    marginRight: "10px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 122 255)",
      borderWidth: "2px",
    },
    "& input": {
      backgroundColor: "white",
      padding: "12px 0px 10px 12px",
      fontSize: "14px",
      fontFamily: "fontRegular",
      fontWeight: 800,
    },
    "& button": {
      padding: 6,
    },
  },
  ListItemTitle: {
    paddingLeft: 6,
    borderBottom: "1px solid #d2d2d2",
  },
  checkboxList: {
    userSelect: "none",
    justifyContent: "space-between",
    marginLeft: 0,
    height: 36,
    "& .MuiIconButton-label": {
      padding: 6,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      color: "#110f47",
      fontFamily: "fontRegular",
      textTransform: "none",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
  },
  checklistselect: {
    userSelect: "none",
    justifyContent: "space-between",
    marginLeft: 0,
    height: 36,
    "& .MuiIconButton-label": {
      padding: 6,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      color: "#007aff",
      fontFamily: "fontSemiBold",
      textTransform: "none",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
  },
  collapseDiv: {
    "& .MuiListItem-gutters": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  // parentDiv: {
  //   height: "fit-content",
  //   width: 270,
  // },
  checkhead: {
    fontSize: 12,
    marginLeft: "2px",
    color: "#9696a9",
    fontFamily: "fontRegular",
    textTransform: "none",
    fontWeight: "800",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  inputFieldChips: {
    fontFamily: "fontRegular",

    fontSize: "12px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    padding: "4px 10px",
    backgroundColor: "white",
    border: ".8px solid rgba(17, 15, 71, 0.08)",
    marginRight: "1%",
    borderRadius: 4,
    color: "#545465",

    marginBottom: "2%",
    "&:focus": {
      border: ".8px solid gray",
      outline: "none",
    },
    "&:active": {
      border: ".8px solid gray",
    },
    "&:hover": {
      border: ".8px solid gray",
    },
  },
  textfield: {
    " & .MuiInputBase-input": {
      backgroundColor: "whitesmoke !important",
    },
  },
}));

export default function Body(props) {
  const classes = useStyles();
  const [eidtvalue, setEidtvalue] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [expanded] = React.useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  // edit DoubleCLick
  const onDoubleClick = (e) => {
    props.setEdit(true);
    setEidtvalue(props.projectName);
  };


  const open = Boolean(anchorEl);
  const id = open ? "list-popover" : undefined;

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "list-popover" : undefined;

  return (
    <Grid className={classes.parent}>
      {/* <Grid>
        <IconButton>
          <UpdateIcon className={classes.iconSize} />
        </IconButton>
      </Grid> */}
      <Grid container className={classes.screen}>
        {props?.projectName ? (
          props.edit ? (
            <TextField
              id="outlined-adornment"
              style={{ width: "60ch" }}
              className={classes.textfield}
              type={"text"}
              value={eidtvalue}
              autoFocus={true}
              autoComplete="off"
              onKeyDown={e=>props.keyPressedit(e)}
              onChange={(e) => setEidtvalue(e.target.value)}
              // onKeyPress={(e) => props.keyPressedit(e)}
              onBlur={(e) => props.onBlursave(e)}
            />
          ) : (
            <Typography
              variant="subtitle1"
              component="span"
              onDoubleClick={(e) => onDoubleClick(e)}
              className={classes.screenTitle}
            >
              {props.projectName}
            </Typography>
          )
        ) : (
          <FormControl className={clsx(classes.inputField)} variant="outlined">
            <OutlinedInput
              id="outlined-adornment"
              type={"text"}
              value={props.text}
              disabled={props.disabled}
              autoFocus={true}
              autoComplete="off"
              placeholder={props.disabled ? "Scene-1" : "UNTITLED SCENE"}
              onChange={(e) => props.textChange(e)}
              onKeyPress={(e) => props.keyPressSave(e)}
              endAdornment={
                <div className={classes.moreIcon}>
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle visibility" edge="end">
                      <MoreVertIcon />
                    </IconButton>
                  </InputAdornment>
                </div>
              }
            />
          </FormControl>
        )}
        <Grid className={classes.flex}>
          <div style={{ marginRight: "0px", display: "flex" }}>
            <Button
              style={{
                height: !props?.digitalEndPoint?.length > 0 && "32px",
                marginTop: !props?.digitalEndPoint?.length > 0 && 4,
              }}
              className={
                props?.digitalEndPoint?.length > 0
                  ? classes.uibtnselect
                  : classes.uiMentionBtn
              }
              onClick={handleClick}
              variant="outlined"
            >
              {props?.digitalEndPoint?.length>1 ? `${props?.digitalEndPoint?.[0]?.label} +${props?.digitalEndPoint?.length-1}` : props?.digitalEndPoint?.[0]?.label}
              {props?.digitalEndPoint?.length > 0 ? (
                ""
              ) : (
                <span style={{ fontSize: 12 }}>+ Digital end point</span>
              )}
            </Button>
            <Button
              style={{
                height: !props?.uxChannel?.length > 0 && "32px",
                marginRight: 0,
                marginTop: !props?.uxChannel?.length > 0 && 4,
              }}
              className={
                props?.uxChannel?.length > 0
                  ? classes.uibtnselect
                  : classes.uiMentionBtn
              }
              onClick={handleClick1}
              variant="outlined"
            >
              {props?.uxChannel?.length>1 ? `${props?.uxChannel?.[0]?.label} +${props?.uxChannel.length-1}` : props?.uxChannel?.[0]?.label}

              {/* {props.uxChannel?.map((item, index) => {
                return <> {(index ? ", " : "") + item}&nbsp; </>;
              })} */}
              {props?.uxChannel?.length > 0 ? (
                ""
              ) : (
                <span style={{ fontSize: 12 }}>+ UX Channel</span>
              )}
            </Button>
          </div>
        </Grid>
      </Grid>
      {/* <Grid style={{ display: "flex" }}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.newBtn}
          disableElevation
          startIcon={<AddDoc style={{ fontSize: 12 }} />}
        >
          Upload Document
        </Button>
      </Grid> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.parentDiv}>
          <List
            component="nav"
            style={{ padding: "0px" }}
            aria-labelledby="nested-list-subheader"
          >
            <ListItem
              button
              // onClick={handleExpandClick}
              className={classes.ListItemTitle}
            >
              {expanded ? (
                <ArrowDropDownIcon style={{ fontSize: 24, marginRight: 4 }} />
              ) : (
                <ArrowRightIcon style={{ fontSize: 20, marginRight: 4 }} />
              )}
              <span>
                <TabIcon />
              </span>
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography className={classes.checkhead}>
                      &nbsp;DIGITAL END POINTS
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            {/* <Collapse
              in={expanded}
              timeout="auto"
              unmountOnExit
              className={classes.collapseDiv}
            > */}
            <List component="div" disablePadding>
              <ListItem alignItems="flex-start">
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        <FormGroup aria-label="position">
                          {
                            digitalEndPointsOptions && digitalEndPointsOptions?.map(_=>{
                              return(
                                <FormControlLabel
                                    className={
                                      props?.digitalEndPoint?.some(l=>l?.value ===_?.value)
                                        ? classes.checklistselect
                                        : classes.checkboxList
                                    }
                                    value={_?.value}
                                    control={
                                      <Checkbox
                                        style={{ color: "#fff" }}
                                        checked={props?.digitalEndPoint?.some(l=>l?.value ===_?.value)}
                                        checkedIcon={
                                          <Tick
                                            style={{
                                              padding: 6,
                                              fontSize: 12,
                                            }}
                                          />
                                        }
                                        name={_?.label}
                                        onChange={(e) =>
                                          props.checkboxValue(_,'digitalEndPoint')
                                        }
                                      />
                                    }
                                    label={_?.label}
                                    labelPlacement="start"
                                  />
                              )
                            })
                          }
                        </FormGroup>
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
            {/* </Collapse> */}
          </List>
        </div>
      </Popover>
      <Popover
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List
          component="nav"
          style={{ padding: "0px" }}
          aria-labelledby="nested-list-subheader"
        >
          <ListItem
            button
            // onClick={handleExpandClick}
            className={classes.ListItemTitle}
          >
            {expanded ? (
              <ArrowDropDownIcon style={{ fontSize: 24, marginRight: 4 }} />
            ) : (
              <ArrowRightIcon style={{ fontSize: 20, marginRight: 4 }} />
            )}
            <span>
              <TabIcon />
            </span>
            <ListItemText
              secondary={
                <React.Fragment>
                  <Typography className={classes.checkhead}>
                    &nbsp; UX CHANNEL
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          {/* <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            className={classes.collapseDiv}
          > */}
          <List component="div" disablePadding>
            <ListItem alignItems="flex-start">
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      <FormGroup aria-label="position">
                      {
                        uxChannelOptions && uxChannelOptions?.map(_=>{
                          return(
                            <FormControlLabel
                                className={
                                  props?.uxChannel?.some(l=>l?.value ===_?.value)
                                    ? classes.checklistselect
                                    : classes.checkboxList
                                }
                                value={_?.value}
                                control={
                                  <Checkbox
                                    style={{ color: "#fff" }}
                                    checked={props?.uxChannel?.some(l=>l?.value ===_?.value)}
                                    checkedIcon={
                                      <Tick
                                        style={{
                                          padding: 6,
                                          fontSize: 12,
                                        }}
                                      />
                                    }
                                    name={_?.label}
                                    onChange={(e) =>
                                      props.checkboxValue(_,'uxChannel')
                                    }
                                  />
                                }
                                label={_?.label}
                                labelPlacement="start"
                              />
                            )
                          })
                        }
                      </FormGroup>
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
          {/* </Collapse> */}
        </List>
      </Popover>
    </Grid>
  );
}

/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020
 * @desc TopNavBar HOC will give you the Navbars (Top/Bottom Navbars)
 */

import React from "react";
import { Grid, Paper, Typography, Avatar } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  content: {
    width: "100%",
  },
  paper: {
    "&.MuiPaper-elevation1": {
      borderRadius: 0,
      boxShadow: " 0 0px 10px 0 #110f4714",
      borderBottom: "1px solid #eee",
      height: "51px",
    },
  },
  title: {
    padding: "10px 10px",
    color: "#0b78fa",
    // color: "#5d5df7"
  },
  username: {
    fontSize: "14px",
    fontWeight: 600,
    margin: "auto",
  },
  rounded: {
    width: "24px",
    height: "24px",
    fontSize: "11px",
    margin: "auto",
    background: "black",
    color: "blue",
    marginRight: 6,
    marginTop: 0,
  },
  rightalign: {
    textAlign: "right",
    display: "grid",
    gridTemplateColumns: "60px 112px 25px",
    float: "right",
    padding: "8px",
    marginTop: 4,
  },
});

const TopNavBar = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      <Paper className={classes.paper}>
        <Grid container className={classes.root1}>
          <Grid item md={6}>
            <Typography variant="h6" className={classes.title}>
              QDM
            </Typography>
          </Grid>
          <Grid item md={6}>
            <div className={classes.rightalign}>
              <Avatar variant="rounded" className={classes.rounded}>
                DP
              </Avatar>
              <Typography variant="h6" className={classes.username}>
                Dmirch Preach
              </Typography>
              <ExpandMoreIcon
                style={{
                  marginTop: 2,
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default withStyles(useStyles)(TopNavBar);

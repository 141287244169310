import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Typography, Grid, Divider, Button, Tooltip } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { ArrowBackIos, CalendarToday } from "@material-ui/icons";
import PersonAdd from "./../../../src/assets/images/group-22.png";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Badge from "@material-ui/core/Badge";
// import UndoIcon from "@material-ui/icons/Undo";
import Chip from "@material-ui/core/Chip";
import SaveIcon from "@material-ui/icons/Save";
import Alerts from "../alert";
import axios from "axios";
import { uuid } from "uuidv4";
import { withAllContexts } from "../../HOCs";
import environment from "../../config";
import {
  checkError,
  ConstructUpsertScene,
  fetchData,
} from "../functions/commonapi";
import { queries } from "../functions/queries";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  subnavbar: {
    minHeight: 54,
    alignItems: "center",
    backgroundColor: "white",
    color: "black",
    // paddingTop: 12,
    // paddingBottom: 12,
  },
  paddingL: {
    paddingLeft: "1.5%",
  },
  paddingR: {
    // paddingRight: "2%",
  },
  hr: {
    marginTop: "11px",
    height: 20,
  },
  small: {
    width: 32,
    height: 32,
    zIndex: 1,
    fontSize: 2,
  },
  square: {
    borderRadius: 8,
  },
  chipdiv1: {
    marginTop: "auto",
    marginBottom: "auto",
  },

  hrdiv: {
    paddingLeft: "20px",
    paddingRight: "20px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",

    lineHeight: "normal",
    letterSpacing: "normal",
    marginTop: "auto",
    marginBottom: "auto",
  },
  username: {
    fontSize: "13px",
    color: "#110f47",
    fontFamily: "fontSemiBold",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    margin: "auto",
  },
  rounded: {
    width: "22px",
    height: "22px",
    fontSize: "11px",
    margin: "auto",
    background: "black",
    color: "blue",
  },
  rightalign: {
    textAlign: "right",
    // display: "grid",
    // gridTemplateColumns: '36px 97px 8px',
    float: "right",
    padding: "8px",
    display: "flex",
  },
  small1: {
    width: "28px",
    height: "28px",
  },
  medium: {
    width: "34px",
    height: "34px",
  },
  head: {
    color: "#110f47",
    fontSize: "14px",
    fontFamily: "fontSemiBold",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    overflowWrap: "anywhere",
    width: "440px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  act: {
    color: "#9696a9",
    fontSize: "12px",
    fontWeight: 400,
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  avatar: {
    backgroundColor: "#fff",
    color: "#545465 ",
    border: "1px solid rgba(17, 15, 71, 0.08)",
    // marginBottom: "30px",
    width: "30px",
    height: "30px",
  },
  // addNewBtnDiv: {
  //   minWidth: 114,
  // },
  newBtn: {
    fontSize: 16,
    fontFamily: "fontSemiBold",
    textTransform: "none",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fff",
    width: "fit-content",
    backgroundColor: "#E34D65;",
    marginBottom: 2,
    "&:hover": {
      backgroundColor: "#E34D65",
    },
    marginRight: 6,
  },
  chipsmall: {
    height: "24px",
    color: "#fff",
    backgroundColor: "#E34D65",
    border: "1px solid #e84e67d1",
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -6,
    top: 16,
    color: "#9696a9",
  },
}))(Badge);

function SubheaderBar(props) {
  const classes = useStyles();
  //const [load, setLoad] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  //const { Data } = props.datajson;
  const { Data, setScenes } = props.scenes;
  const { color } = props.color;
  const persona = props.library.Data.personas;
  const library = props.library.Data.mediaLibrary;

  // const SaveSchema = async () => {
  //   props.savefunc("save");
  //   let dataList = {
  //     db_name: "QDM_Metadata",
  //     entity: "QPAS",
  //     is_metadata: false,
  //     projectname: "CRAYOND3",
  //     doc: {
  //       metadataId: localStorage.metadataId ? localStorage.metadataId : uuid(),
  //       qpasid: localStorage.qpasid ? localStorage.qpasid : uuid(),
  //       projectname: "CRAYOND3",
  //       attributes: Data,
  //     },
  //   };
  //   if (localStorage.qpasid) {
  //     dataList.filter = { qpasid: dataList.qpasid };
  //   }
  //   let data = JSON.stringify([dataList]);

  //   var config = {
  //     method: "post",
  //     url: `${environment.api_url}/api/upsert_document`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   await axios;
  //   axios(config)
  //     .then((response) => {
  //       localStorage.setItem(
  //         "qpasid",
  //         response.data.Result[0].properties.doc.qpasid
  //       );
  //       localStorage.setItem(
  //         "metadataId",
  //         response.data.Result[0].properties.doc.metadataId
  //       );
  //       setLoad(false);
  //       setOpenAlert(true);
  //     })
  //     .catch((err) => {
  //       setLoad(false);
  //       alert("something went wrong!");
  //     });
  // };

  const SaveSchema = async () => {
    debugger;
    // console.log(Data);
    // let overAllData = ConstructUpsertScene(Data, persona, library, color);
    // debugger;
    // console.log(overAllData);
    try {
      props.savefunc("save");
      let overAllData = ConstructUpsertScene(Data, persona, library, color);
      const data = await fetchData(
        { body: JSON.stringify(overAllData) },
        `${environment.api_url}/api/upsert_document`
      );
      const { isError, errMsg } = checkError(data);
      debugger;
      if (isError) {
        alert(errMsg);
      } else {
        setOpenAlert(true);
        let qpasid = localStorage.qpasid;
        let storyid = props.UrlstoryId;
        let playid = props.UrlplayId;
        let actid = props.UrlactId;

        //get all the scenes
        const dataRead = await fetchData(
          {
            body: JSON.stringify(
              queries.scene_read(qpasid, storyid, playid, actid)
            ),
          },
          `${environment.api_url}/api/read_documents`
        );
        setScenes({
          ...props.scenes,
          Data: dataRead.result,
        });
      }
    } catch (error) {
      debugger;
      alert("error");
    }
  };
  return (
    <div className={classes.root}>
      {/* <AppBar
        position="relative"
        elevation={2}
        className={`${classes.subnavbar} ${classes.paddingL} ${classes.paddingR}`}
      > */}
      <Grid
        className={`${classes.subnavbar} ${classes.paddingL} ${classes.paddingR}`}
        alignItems="center"
        justify="space-between"
        direction="row"
        container
      >
        <Grid alignItems="center" item container xs={12} sm={5}>
          <Grid
            item
            style={{ paddingRight: "2%", cursor: "pointer" }}
            onClick={() => {
              setScenes({
                ...props.scenes,
                Data: [],
                selectedSceneId: null,
                selectedScene: null,
              });
              props.history.push(`/`);
            }}
          >
            <ArrowBackIos
              style={{ fontSize: 16, color: "#000000de", marginTop: 4 }}
            />
          </Grid>
          {/* <Divider orientation='vertical' flexItem className={classes.hr} /> */}
          <Grid item style={{ paddingLeft: "2%" }}>
            <Avatar aria-label="recipe" className={classes.avatar}>
              <CalendarToday style={{ fontSize: "initial" }} />
            </Avatar>
          </Grid>
          <Grid item style={{ paddingLeft: "4%", paddingRight: "4%" }}>
            <Tooltip title={props.title}>
              <Typography className={classes.head} variant="subtitle2">
                {props.title}
              </Typography>
            </Tooltip>
            <Typography variant="body2" className={classes.act}>
              Act : 1
            </Typography>
          </Grid>
          {/* <Divider orientation='vertical' flexItem className={classes.hr} />
          <UndoIcon style={{ padding: '10px', color: '#9696a9' }} />
          <Divider
            orientation="vertical"
            flexItem
            style={{ width: "2px" }}
            className={classes.hr}
          />
          <UndoIcon
            style={{
              transform: "scaleX(-1)",
              padding: "10px",
              color: "#110f47",
            }}
          /> */}
        </Grid>
        <Grid
          // alignItems="flex-end"
          // justify="flex-end"
          item
          xs={12}
          sm={3}
          // style={{ textAlign: "right" }}
        >
          {props.save ? (
            <Chip
              className={classes.chipsmall}
              icon={
                <SaveIcon
                  style={{ color: "#fff", width: "18px", height: "18px" }}
                />
              }
              label="Please save your changes"
              color="secondary"
              variant="outlined"
            />
          ) : (
            ""
          )}
        </Grid>

        <Grid
          item
          container
          xs={12}
          sm={4}
          alignItems="center"
          justify="flex-end"
        >
          <div style={{ display: "flex", marginRight: "42px" }}>
            <div className={classes.chipdiv1}>
              <Avatar
                className={classes.medium}
                alt="Remy Sharp"
                src={PersonAdd}
              />
            </div>
            <div className={classes.hrdiv}>
              <Divider
                orientation="vertical"
                flexItem
                className={classes.hr}
                style={{ padding: 0, margin: 0 }}
              />
            </div>
            <div className={classes.title}>
              <StyledBadge badgeContent={11} max={4}>
                <AvatarGroup max={4}>
                  <Avatar
                    alt="Remy Sharp"
                    src="https://picsum.photos/200/300?random=2"
                    className={classes.small1}
                  />
                  <Avatar
                    alt="Travis Howard"
                    src="https://picsum.photos/200/300?random=3"
                    className={classes.small1}
                  />
                  <Avatar
                    alt="Cindy Baker"
                    src="https://picsum.photos/200/300?random=4"
                    className={classes.small1}
                  />
                  <Avatar
                    alt="Agnes Walker"
                    src="https://res.cloudinary.com/demo/image/upload/smiling_man.jpg"
                    className={classes.small1}
                  />
                </AvatarGroup>
              </StyledBadge>
            </div>
          </div>
          <Divider orientation="vertical" flexItem className={classes.hr} />
          {/* <div className={classes.rightalign}>
            <Avatar variant="rounded" className={classes.rounded}>
              DP
            </Avatar>
            <Typography variant="h6" className={classes.username}>
              &nbsp;Dmirch Preach
            </Typography>
            <ExpandMoreIcon style={{ marginBottom: 3 }} />
          </div> */}
          <div className={classes.addNewBtnDiv}>
            <Button
              variant="text"
              color="default"
              className={classes.newBtn}
              onClick={() => SaveSchema()}
              disableElevation
            >
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
      {/* </AppBar> */}
      {openAlert && (
        <Alerts
          open={openAlert}
          closeAlert={() => {
            setOpenAlert(false);
          }}
          vertical={"top"}
          horizontal={"right"}
          msg={"Thanks for saving !"}
        />
      )}
    </div>
  );
}

export default withAllContexts(withRouter(SubheaderBar));

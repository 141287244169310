import dotenv from "dotenv";
dotenv.config();

let environment = {};
// by manikandan-ainqa
//if we add environment file property inside the config, REACT_APP add prifix env property
environment.api_url = process.env.REACT_APP_ARANGO_DOMAIN_NAME;
environment.document_upload = process.env.REACT_APP_ARANGO_DOCUMENT;

// environment.api_url = `${process.env.REACT_APP_SECURITY}://${process.env.REACT_APP_DOMAINNAME}:${process.env.REACT_APP_PORT}`;
// environment.api_url=process.env.AP
environment.graphql = process.env.REACT_APP_GQL_ENDPOINT;
environment.socket = process.env.REACT_APP_SOCKET;
// environment.document_upload = `${process.env.REACT_APP_SECURITY}://164.52.210.54:${process.env.REACT_APP_DOCUMENTPORT}`;
environment.qdm_property_entity = process.env.REACT_APP_QDMPROPERTIES_ENTITY;
environment.database = process.env.REACT_APP_DATABASE;
environment.qdm_enterprisemodeler_entity =
  process.env.REACT_APP_ENTERPRISEMODELER_ENTITY;
environment.qdm_qpas_entity = process.env.REACT_APP_QPAS_ENTITY;
environment.entity_template = process.env.REACT_APP_ENTITY_TEMPLATE;
environment.stratigic_entity = process.env.REACT_APP_STRATEGIC_ENTITY;
environment.kpi_master_entity = process.env.R;
environment.story_entity = process.env.REACT_APP_STORY_ENTITY;
environment.arango_adaptor_api_url =  process.env.REACT_APP_ADAPTOR_QDM_PLATFORM;
//upload url
environment.upload_protocal = process.env.REACT_APP_UPLOAD_PROTOCAL
  ? process.env.REACT_APP_UPLOAD_PROTOCAL
  : "https";

environment.upload_host = process.env.REACT_APP_UPLOAD_HOST
  ? process.env.REACT_APP_UPLOAD_HOST
  : "fileupload.dev.ainqaplatform.in";

environment.upload_port = process.env.REACT_APP_UPLOAD_PORT
  ? process.env.REACT_APP_UPLOAD_PORT
  : 443;

environment.configration_number = process.env.REACT_APP_CONFIG_NO
  ? process.env.REACT_APP_CONFIG_NO
  : 11;
environment.uploadUrl = `${environment.upload_protocal}://${environment.upload_host}:${environment.upload_port}/${environment.database}/${environment.configration_number}`;
environment.downloadUrl = `${environment.upload_protocal}://${environment.upload_host}:${environment.upload_port}/${environment.database}/`;

export default environment;

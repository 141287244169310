import React from "react";

export default function Body(props) {
  console.log(props)
  let width = props.width ? props.width : "19";
  let height = props.height ? props.height : "16";
  let color = props.color ? props.color : "#545465";
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 19 16"
    >
      <path
        fill={color}
        d="M16,0.3H3C1.5,0.3,0.3,1.5,0.3,3v10c0,1.5,1.2,2.7,2.7,2.7h13c1.5,0,2.7-1.2,2.7-2.7V3
	C18.7,1.5,17.5,0.3,16,0.3z M1.7,13V3c0-0.7,0.6-1.3,1.3-1.3h9.3v12.6H3C2.3,14.3,1.7,13.7,1.7,13z M17.3,13c0,0.7-0.6,1.3-1.3,1.3
	h-2.3V1.7H16c0.7,0,1.3,0.6,1.3,1.3V13z"
      />
    </svg>
  );
}

import React from "react";
import { Library } from "./";

const LibraryProvider = (props) => {
  let [library, setLibrary] = React.useState({
    Data: {
      icons: [],
      personas: [],
      mediaLibrary: [],
    },
  });

  return (
    <Library.Provider value={{ ...library, setLibrary }}>
      {props.children}
    </Library.Provider>
  );
};

export default LibraryProvider;

import environment from "../../config";

export const queries = {
  story_read: (qpasid) => {
    return {
      db_name: `${environment.database}`,
      entity: `${environment.story_entity}`,
      filter: `qpas_stories.qpasid=='${qpasid}' && qpas_stories.activestatus==true`,
      return_fields:
        "merge(qpas_stories,{plays:(for qpas_plays in qpas_plays filter qpas_plays.storyid == qpas_stories.storyid && qpas_plays.activestatus==true return merge(qpas_plays,{acts:(for qpas_acts in qpas_acts filter qpas_acts.storyid==qpas_stories.storyid && qpas_acts.playid== qpas_plays.playid && qpas_acts.activestatus==true return qpas_acts)}))})",
    };
  },
  scene_read: (qpasid, storyid, playid, actid) => {
    return {
      db_name: `${environment.database}`,
      entity: "qpas_scene",
      filter: `qpas_scene.qpasid=='${qpasid}' && qpas_scene.storyid=='${storyid}' && qpas_scene.playid=='${playid}' && qpas_scene.actid=='${actid}' && qpas_scene.activestatus==true`,
      return_fields:
        "merge(qpas_scene,{steps:(for qpas_steps in qpas_steps filter qpas_steps.sceneid==qpas_scene.sceneid && qpas_steps.activestatus==true return qpas_steps),personas:(for qpas_persona in qpas_persona filter qpas_persona.SceneId==qpas_scene.sceneid && qpas_persona.activestatus==true return qpas_persona)})",
    };
  },
  color_read: () => {
    return {
      db_name: `${environment.database}`,
      entity: "qdm_color",
      filter: "qdm_color.activestatus == true",
      return_fields: "qdm_color",
    };
  },
  library_read: () => {
    return {
      db_name: `${environment.database}`,
      entity: "qpas_library",
      filter: "qpas_library.activestatus == true",
      return_fields: "qpas_library",
    };
  },
  persona_read: () => {
    return {
      db_name: `${environment.database}`,
      entity: "qdm_persona",
      filter: "qdm_persona.activestatus == true",
      return_fields: "qdm_persona",
    };
  },
  kpi_read: () => {
    return {
      db_name: `${environment.database}`,
      entity: "qdm_kpi",
      filter: "qdm_kpi.activestatus == true",
      return_fields: "qdm_kpi",
    };
  },
  projVsToo: (metadataId) => {
    return {
      db_name: `${environment.database}`,
      entity: "projectvstools",
      filter: `projectvstools.metadataid=='${metadataId}'`,
      return_fields: `{projectvstools}`,
    };
  },
  entityTool: () => {
    return {
      db_name: `${environment.database}`,
      entity: "tools",
      filter: `tools.toolid=='88fd87fa-6163-4a05-ba19-5dee347e0f2d'`,
      return_fields: `{tools}`,
    };
  },
  metaDataId: (project_details, tool_id) => {
    return {
      db_name: `${environment.database}`,
      entity: "projectvstools",
      filter: `projectvstools.projectid=='${project_details}' and projectvstools.toolid=='${tool_id}'`,
      return_fields: `{projectvstools}`,
    };
  },
};

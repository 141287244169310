import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Chip from "@material-ui/core/Chip";
// import Divider from '@material-ui/core/Divider'
import Acts from "./createAct";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "../../../components/icons/delete";
// import AddIcon from '@material-ui/icons/Add'
import {
  ListItemIcon,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { PopoverComponent } from "../../../components/popover";

const useStyles = makeStyles((theme, props) => ({
  root: {
    width: "100%",
  },
  Accordion: {
    boxShadow: "none",
    borderRadius: "16px",

    // border: "1px solid  rgba(17, 15, 71, 0.08)",
  },
  line: {
    padding: "0px 10px",
    borderRight: "2px solid  rgba(17, 15, 71, 0.08)",
    height: "25px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  heading: {
    color: "#110f47",
    fontFamily: "fontSemiBold",
    fontSize: "15px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginTop: "auto",
    marginBottom: "auto",
  },
  chipdiv: {
    padding: "0px 16px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  Chip: {
    padding: "4px 0px",
    borderRadius: "4px",

    color: "#545465 ",

    fontSize: "11px",
    fontWeight: "normal",
    fontStretch: "normal",

    lineHeight: "normal",
    letterSpacing: "normal",
    marginTop: "auto",
    marginBottom: "auto",
  },
  chipdiv1: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  Chip1: {
    padding: "1px 5px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    color: "#545465 ",
    border: "1px solid rgba(17, 15, 71, 0.08)",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",

    lineHeight: "normal",
    letterSpacing: "normal",
    marginTop: "auto",
    marginBottom: "auto",
  },
  chipdiv2: {
    marginTop: "auto",
    marginBottom: "auto",
    float: "right",
  },
  Chip2: {
    padding: "0px",
    borderRadius: "6px",
    backgroundColor: "rgba(0, 122, 255, 0.07)",
    color: "#007aff",
    // border: '0px',
    fontSize: "13px",
    fontWeight: 500,
    fontStretch: "normal",

    lineHeight: "normal",
    letterSpacing: "normal",
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: "10px",
    border: "1px solid #eee",
    "& .MuiChip-label": {
      padding: "0px 10px",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 122, 255, 0.07)",
    },
  },

  dotline: {
    border: "1.5px dotted rgba(17, 15, 71, 0.08)",
  },
  CreateScene: {
    marginLeft: "15px",
    padding: "16px",
    // paddingBottom: "16px",
    flexWrap: "wrap",
    // padding: '20px 38px 0px'
  },
  deleteDiv: {
    height: "fit-content",
    width: 100,
    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
    "& .MuiList-padding": {
      padding: "0px",
    },
  },
  more: {
    padding: "0px",
  },
  input: {
    "& textarea": {
      // fontWeight: 500,
    },
  },
}));

//  Accordionsummary Style
const AccordionSummary = withStyles({
  root: {
    // padding: "0px 4px 0px 16px",
    "& .MuiAccordionSummary-content": {
      margin: "9px 0px",
    },
  },
  expandIcon: {
    order: -1,
    width: "10px",
    height: "10px",
    // border: "1px solid rgba(17, 15, 71, 0.08)",

    color: "black",
    backgroundColor: "white !important",
    "&$expanded": {
      transform: "rotate(90deg)",
    },
  },
  expanded: {
    // background: "whitesmoke",
  },
})(MuiAccordionSummary);

// main component for Createplay

const CreatPlay = (props) => {
  const classes = useStyles();
  // const [values, setvalues] = React.useState(props.title);
  const [anchorDel, setAnchorDel] = React.useState(null);
  const Styles = {
    avatarbg: {
      width: "30px",
      height: "30px",
      backgroundColor:
        props.id % 3 === 0
          ? "#f2b23a "
          : props.id % 2 !== 0
          ? "#007aff"
          : props.id % 2 === 0
          ? "#34b69c"
          : "",
    },
    chipbg: {
      // backgroundColor: props.id % 2 !== 0 ? '#fff' : 'rgba(17, 15, 71, 0.03)',
      backgroundColor: "#fff",
      border: "1px solid rgba(17, 15, 71, 0.08)",
      // fontWeight: 650,
      // fontSize: 12,
      letterSpacing: "0.5px",
    },
  };

  const open2 = Boolean(anchorDel);
  const id2 = open2 ? "Delete" : undefined;

  const MorePop = (e) => {
    e.stopPropagation();
    setAnchorDel(e.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorDel(null);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} xl={12} className={classes.root}>
        <Accordion
          expanded={props.expanded}
          onChange={props.onClick}
          className={classes.Accordion}
        >
          <AccordionSummary
            style={{ borderBottom: "1px solid #eee" }}
            expandIcon={<ArrowRightIcon fontSize="large" />}
            className={classes.Accordioncontent}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={10}
                xl={10}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ padding: "0px 16px" }}>
                    <Avatar style={Styles.avatarbg}>{props.icon}</Avatar>
                  </div>
                  {props.play?.playid === props.edit?.id ? (
                    <TextField
                      className={classes.input}
                      id={props.play?.playid}
                      value={props?.edit?.name}
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      onKeyDown={(e) => props.keydownPlay(e)}
                      fullWidth
                      multiline
                      autoComplete="off"
                      onChange={(event) => props?.onChangePlayName(event.target.value)}
                      autoFocus={true}
                      onBlur={props.onBlurPlay}
                    />
                  ) : (
                    <Typography
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      onDoubleClick={props.onDoubleClick}
                      id={`playname-${props.play?.playid}`}
                      className={classes.heading}
                    >
                      {props.playname}
                    </Typography>
                  )}

                  <div className={classes.line}></div>
                  <div className={classes.chipdiv}>
                    <Chip
                      // onClick={(event) => event.stopPropagation()}
                      // onFocus={(event) => event.stopPropagation()}
                      variant="outlined"
                      style={Styles.chipbg}
                      className={classes.Chip}
                      label={
                        <span>
                          <PopoverComponent
                            data={props.KPI}
                            selectedValue={props.addKPI}
                            label={"+ Strategic KPI" + (props.id + 1)}
                            onChange={(e) =>
                              props.onChangeAnswerType2(
                                e,
                                props.id,
                                props.parentid
                              )
                            }
                          />
                        </span>
                      }
                    />
                  </div>

                  {/* {props.id % 2 !== 0 ? (
                    ""
                  ) : (
                    <div className={classes.chipdiv1}>
                      <Chip
                        variant="outlined"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        className={classes.Chip1}
                        label="+"
                      />
                    </div>
                  )} */}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={2} xl={2}>
                <div className={classes.chipdiv2}>
                  <Chip
                    variant="outlined"
                    className={classes.Chip2}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    label={props.actLength}
                  />
                  <IconButton
                    aria-label="settings"
                    onClick={(e) => MorePop(e)}
                    onFocus={(event) => event.stopPropagation()}
                    className={classes.more}
                  >
                    <MoreVertIcon style={{ color: "#b1a7a7bd" }} />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </AccordionSummary>
          <div className={classes.dotline}>
            <AccordionDetails style={{ padding: "0px ", border: "unset" }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <div
                    className={classes.CreateScene}
                    style={
                      {
                        // padding: '20px 38px 0px'
                      }
                    }
                  >
                    <Acts
                      acts={props.acts}
                      // story_id={props.story_id}
                      // story_title={props.story_title}
                      // play_id={props?.play?.playid}
                      play={props?.play}
                      // play_title={props.playname}
                      onKeyPressact={props.onKeyPressact}
                      handleDeleteAct={props.handleDeleteAct}
                      storyIndex={props.parentid}
                      playIndex={props.id}
                      onChangeAct={props.onChangeAct}
                      setDupmsg={props.setDupmsg}
                      setDuplicate={props.setDuplicate}
                    />
                  </div>

                  {/* <Divider /> */}
                </Grid>
              </Grid>
            </AccordionDetails>
          </div>
        </Accordion>
      </Grid>
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorDel}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.deleteDiv}>
          <List component="nav" aria-labelledby="nested-list-subheader">
            <ListItem button onClick={()=>{
              props.handleDeletePlay();
              handleClose2()
            }}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      Delete
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Popover>
    </Grid>
  );
};
export default CreatPlay;

/*
 * @authored by porkalanchiyam
 */

import React from "react";

export default function Body(props) {

  let width = props.width ? props.width : "14";
  let height = props.height ? props.height : "18";
  let color = props.color ? props.color : "#545465";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 18"
    >
      <g fill="none">
        <g fill={color}>
          <g>
            <path
              d="M17.946 13C16.873 13 16 13.865 16 14.929v13.285c0 1.064.873 1.929 1.946 1.929h11.73v-1.286h-11.73c-.358 0-.649-.288-.649-.643V14.93c0-.355.291-.643.649-.643h4.973v3.643c0 1.063.873 1.928 1.946 1.928h3.676v2.721c.197-.093.415-.15.648-.15.233 0 .452.057.649.15v-3.364c0-.177-.073-.338-.19-.454l-5.622-5.572c-.117-.116-.28-.188-.458-.188h-5.622zm6.27 2.195l3.407 3.376h-2.758c-.358 0-.649-.288-.649-.642v-2.734zM28.5 30.15h1.333V21.5H28.5"
              transform="translate(-1388 -491) translate(1372 478)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

import React, { useEffect } from "react";
import { makeStyles, Popover } from "@material-ui/core";

import { withAllContexts } from "../../../HOCs";
import Properties from "../rightSection/components/properties";
import Function from "../rightSection/components/function";
import Comments from "../rightSection/components/comments";
import Document from "../rightSection/components/document";
import Share from "../rightSection/components/share";
import BPMN from "../rightSection/components/bpmn";

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    height: "100%",
    width: 300,
    "& .MuiPaper-elevation8": {
      boxShadow: "4px 6px 13px 0 rgb(17 15 71 / 6%)",
    },
  },
  main: {
    "& .MuiPaper-elevation8": {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
      border: "1px solid #d8d8d896",
      borderRadius: 4,
    },
  },
}));

function Body(props) {
  const [anchorEl, setAnchorEl] = React.useState(props.popoverstatus);

  const closeHandle = () => {
    props.closepopover();
  };

  useEffect(() => {
    setAnchorEl(props.popoverstatus);
  }, [props.popoverstatus]);

  const classes = useStyles();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Popover
      className={classes.main}
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: 320,
      }}
      onClose={closeHandle}
    >
      <div className={classes.parentDiv}>
        <div className={classes.ItemList}>
          {(() => {
            switch (props.activemenu) {
              case "Properties":
                return (
                  <Properties
                    head="Scene"
                    addproptextfield={props.addproptextfield}
                    addprop={props.addprop}
                    properties={props.properties}
                    closeHandle={closeHandle}
                    textChange={props.textChange}
                    text={props.text}
                    keypressprop={props.keypressprop}
                    onClickprop={props.onClickprop}
                    onClickpropval={props.onClickpropval}
                    handleDeleteprop={props.handleDeleteprop}
                    err={props.err}
                    // functions for sub properties
                    addproptext={props.addproptext}
                    subproptext={props.subproptext}
                    subproptextChange={props.subproptextChange}
                    keypresssubprop={props.keypresssubprop}
                    onClicksubprop={props.onClicksubprop}
                    handleDeletesubprop={props.handleDeletesubprop}
                    suberr={props.suberr}
                    DeletesubProp={props.DeletesubProp}
                    editSubprop={props.editSubprop}
                    editsub={props.editsub}
                    handleClosesubprop={props.handleClosesubprop}
                    subprop={props.subprop}
                    keypresseditsubprop={props.keypresseditsubprop}
                    onClickeditsubprop={props.onClickeditsubprop}
                    editsubproptextChange={props.editsubproptextChange}
                  />
                );
              case "Comments":
                return (
                  <Comments
                    closeHandle={closeHandle}
                    comments={props.comments}
                  />
                );
              case "Share":
                return <Share closeHandle={closeHandle} />;
              case "Function":
                return (
                  <Function
                    // handleClick={handleClick}
                    scene={props.scene}
                    function={props.function}
                    // opene={opene}
                    addfuntextfield={props.addfuntextfield}
                    addfun={props.addfun}
                    closeHandle={closeHandle}
                    funtextChange={props.funtextChange}
                    funtext={props.funtext}
                    keypressfun={props.keypressfun}
                    onClickfun={props.onClickfun}
                    handleDeletefun={props.handleDeletefun}
                    funerr={props.funerr}
                    editFunction={props.editFunction}
                    deleteFunction={props.deleteFunction}
                    editFunctionItem={props.editFunctionItem}
                    onClickfunAdd={props.onClickfunAdd}
                    handleTextDeletefun={props.handleTextDeletefun}
                    handleKeyPressEditFunction={
                      props.handleKeyPressEditFunction
                    }
                    // sub function
                    addsubfun={props.addsubfun}
                    subfuntextChange={props.subfuntextChange}
                    subfuntext={props.subfuntext}
                    keypresssubfun={props.keypresssubfun}
                    onClicksubfun={props.onClicksubfun}
                    handleDeletesubfun={props.handleDeletesubfun}
                    subfunerr={props.subfunerr}
                    Deletesubfun={props.Deletesubfun}
                    handleClosesubfun={props.handleClosesubfun}
                    editfun={props.editfun}
                    editSubfun={props.editSubfun}
                    subfun={props.subfun}
                    editsubfuntextChange={props.editsubfuntextChange}
                    onClickeditsubfun={props.onClickeditsubfun}
                    keypresseditsubfun={props.keypresseditsubfun}
                  />
                );
              case "Document":
                return (
                  <Document
                    closeHandle={closeHandle}
                    progressval={props.progressval}
                    handleUpload={props.handleUpload}
                    uploaddocc={props.uploaddocc}
                    DeleteDocument={props.DeleteDocument}
                    progress={props.progress}
                  />
                );
              case "BPMN":
                return <BPMN closeHandle={closeHandle} />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </Popover>
  );
}

export default withAllContexts(Body);

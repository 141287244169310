import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import Iconlist from "../librarylist/Iconlist";
import IconButton from "@material-ui/core/IconButton";
// import DataJson from "../../../../data.json";
import { withAllContexts } from "../../../../HOCs";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "grey",
    fontSize: 20,
    margin: "12px",
  },
  textwidth: {
    borderRadius: 0,
    padding: "0px 0px 5px 0px",
  },
  headtext: {
    fontSize: 12,
    color: "#110f47",
    fontFamily: "fontRegular",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    fontWeight: 800,
    display: "inline-flex",
    paddingLeft: "20px",
    textTransform: "uppercase",
  },
  search: {
    color: "grey",
    fontSize: 18,
    justifyContent: "flex-end",
    textAlign: "right!important",
    cursor: "pointer",
  },
  arrowleft: {
    color: "black",
    display: "inline-flex",
  },
  gridsearch: {
    marginRight: "10px",
  },
}));

function Iconpop(props) {
  const classes = useStyles();

  return (
    <div className={classes.textwidth}>
      <Grid justify="space-between" container>
        <Grid>
          <p className={classes.headtext}>
            ICON
          </p>
        </Grid>

        <Grid className={classes.gridsearch}>
          <IconButton>
            <SearchIcon className={classes.search} />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <Iconlist />
    </div>
  );
}
export default withAllContexts(Iconpop);

import React from "react";

export default function Body() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
    >
      <defs>
        <filter id="wduye46mza">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.329412 0 0 0 0 0.329412 0 0 0 0 0.396078 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none">
        <g>
          <g
            filter="url(#wduye46mza)"
            transform="translate(-37 -286) translate(17 130)"
          >
            <g>
              <path
                fill="#000"
                d="M3.947 0c-.284-.004-.55.138-.693.372-.143.234-.143.523 0 .756.144.234.409.376.693.372h12.106c.284.004.55-.138.693-.372.143-.233.143-.522 0-.756-.144-.234-.409-.376-.693-.372H3.947zM2.368 2.5c-.284-.004-.55.138-.693.372-.143.234-.143.523 0 .756.144.234.409.376.693.372h15.264c.284.004.55-.138.693-.372.143-.233.143-.522 0-.756-.144-.234-.409-.376-.693-.372H2.368zM1.842 5C.834 5 0 5.793 0 6.75v9.5C0 17.207.834 18 1.842 18h16.316C19.166 18 20 17.207 20 16.25v-9.5C20 5.793 19.166 5 18.158 5H1.842zm0 1.5h16.316c.155 0 .263.103.263.25v9.5c0 .148-.108.25-.263.25H1.842c-.155 0-.263-.102-.263-.25v-9.5c0-.147.108-.25.263-.25z"
                transform="translate(20 156)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

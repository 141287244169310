import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Medialist from "../librarylist/Medialist";
import IconButton from "@material-ui/core/IconButton";
// import DataJson from "../../../../data.json";
import { withAllContexts } from "../../../../HOCs";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "grey",
    fontSize: 20,
    margin: "12px",
  },
  textwidth: {
    borderRadius: 0,
    padding: "0px 0px 5px 0px",
  },
  headtext: {
    fontSize: 12,
    color: "#110f47",
    fontFamily: "fontRegular",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    fontWeight: 800,
    display: "inline-flex",
    paddingLeft: "20px",
    textTransform: "uppercase",
  },
  add: {
    color: "#e34d65",
    fontSize: 16,
    justifyContent: "right!important",
    textAlign: "right!important",
    cursor: "pointer",
  },
  gridadd: {
    marginRight: "10px",
  },
}));

function Mediapop(props) {
  const classes = useStyles();
  // const { Data } = props.datajson;
  return (
    <div className={classes.textwidth}>
      <Grid justify="space-between" container>
        <Grid>
          {/* {Data?.library?.Topics?.map((libr, i) => {
            return ( */}
          <p className={classes.headtext}>
            {/* {libr.sectionthree} */}MEDIA Library
          </p>
          {/* );
          })} */}
        </Grid>
        <Grid className={classes.gridadd}>
          <IconButton onClick={props.onClickMedia}>
            <AddOutlinedIcon className={classes.add} />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <Medialist />
    </div>
  );
}
export default withAllContexts(Mediapop);

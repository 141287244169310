import React, { useState } from "react";

import { Grid, makeStyles } from "@material-ui/core";

// user 1st funcation icons
import Properties from "../../../components/icons/properties";
// import Comments from "../../../components/icons/comments";
//import Share from "../../../components/icons/share";
import Function from "../../../components/icons/function";
import Doc from "../../../components/icons/documents";
import BPMN from "../../../components/icons/bpmn";
import { withAllContexts } from "../../../HOCs";
// import defaultJson from "../../../data.json";
import moment from "moment";
// user 2nd assest icons
// import CropFreeIcon from "@material-ui/icons/CropFree";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import axios from "axios";
import TableChartIcon from "@material-ui/icons/TableChart";
//
import Popover from "./drawer";
import { v4 as uuid } from "uuid";
import environment from "../../../config";
import { UserList } from "./components/entity";
const useStyles = makeStyles((theme) => ({
  parentDiv: {
    // height: "100%",
    zIndex: 1,
    "& .MuiPaper-elevation8": {
      boxShadow: "4px 6px 13px 0 rgb(17 15 71 / 6%)",
    },
  },
  buttonViewiconsCustum: {
    fontSize: 16,
    color: "#5D5D6D",
  },
  ViewbuttonDiv: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    backgroundColor: "#fff",
    marginTop: 12,
    marginBottom: 6,
    borderRadius: 50,
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    lineHeight: 0,
    boxShadow: "0 6px 10px 0 rgba(1, 1, 1, 0.2)",
    cursor: "pointer",
    width: 38,
    height: 38,
    "&:hover": {
      backgroundColor: "#006ee6",
      boxShadow: "none",
      "& svg": {
        "& g": {
          fill: "#fff",
        },
        "& path": {
          fill: "#fff",
        },
      },
    },
    "&:focus": {
      outline: "none",
    },
  },
  buttondisable: {
    backgroundColor: "#E0E0E0",
    marginTop: 12,
    marginBottom: 6,
    borderRadius: 50,
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    lineHeight: 0,
    boxShadow: "0 6px 10px 0 rgba(1, 1, 1, 0.2)",
    cursor: "pointer",
    width: 38,
    height: 38,
    "&:hover": {
      backgroundColor: "#006ee6",
      boxShadow: "none",
      "& svg": {
        "& g": {
          fill: "#fff",
        },
        "& path": {
          fill: "#fff",
        },
      },
    },
    "&:focus": {
      outline: "none",
    },
  },
  buttonActive: {
    backgroundColor: "#006ee6",
    marginTop: 12,
    marginBottom: 6,
    borderRadius: 50,
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    padding: 12,
    lineHeight: 0,
    boxShadow: "0 6px 10px 0 rgba(1, 1, 1, 0.2)",
    cursor: "pointer",
    width: 44,
    height: 44,
    "& svg": {
      "& g": {
        fill: "#fff",
      },
      "& path": {
        fill: "#fff",
      },
    },
    "&:hover": {
      backgroundColor: "#006ee6",
      boxShadow: "none",
      "& svg": {
        "& g": {
          fill: "#fff",
        },
        "& path": {
          fill: "#fff",
        },
      },
    },
    "&:focus": {
      outline: "none",
    },
  },
  Viewbutton: {
    backgroundColor: "#fff",
    marginTop: 12,
    marginBottom: 6,
    borderRadius: 50,
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    padding: 12,
    lineHeight: 0,
    boxShadow: "0 6px 10px 0 rgba(1, 1, 1, 0.2)",
    cursor: "pointer",
    width: 44,
    height: 44,
    "&:hover": {
      boxShadow: "0 6px 10px 0 rgba(1, 1, 1, 0.2)",
    },
    "&:focus": {
      outline: "none",
    },
  },
  Icontext: {
    margin: 0,
    fontSize: 12,
    color: "#110f47",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    fontWeight: "normal",
    width: "min-content",
  },
  buttonDiv: {
    textAlign: "center",
    fontSize: 14,
  },
  entityIcon: {
    width: "16px",
    height: "16px",
    color: "rgb(125 121 121)",
  },
}));

function Body(props) {
  const { selectedArr } = props;
  const [text, setText] = React.useState("");
  const [err, setErr] = React.useState(false);
  const classes = useStyles();
  const [popoverstatus, setpopoverstatus] = React.useState(null);
  const [activemenu, setactivemenu] = React.useState("");
  const [progressval] = React.useState(100);
  const [editFunctionItem, setEditFunctionItem] = React.useState({
    action: false,
    functionItem: {},
    index: 0,
  });
  // const { Data, setData } = props.datajson;
  const { Data, setScenes } = props.scenes;

  // properties

  const openpopover = (event, name) => {
    setpopoverstatus(event.currentTarget);
    setactivemenu(name);
  };

  const addUser = (selectEntity) => {
    console.log(selectedArr);
    Data[selectedArr].entity = selectEntity;
    setScenes({ ...props.scenes, Data });
    // SaveSchema(Data);
    handleClose();
  };

  // comments

  // const comments = () => {
  //   let commcount = Data.comments.length + 1;
  //   var prop = {
  //     id: commcount,
  //     name: "Kalanchiyam",
  //     days: "2 Days ago",
  //     message: "hi there",
  //   };
  //   Data.comments.push(prop);
  //   //setData({ ...props.datajson, Data });
  // };

  const [entity, setEntity] = useState([]);
  React.useEffect(() => {
    getEntity();
  }, []);
  const getEntity = async () => {
    await axios
      .post(`${environment.arango_adaptor_api_url}/api/get_schema`, {
        filter: {
          columname: "metadataId",
          operator: "equals",
          value: localStorage.getItem("metadataId")
            ? localStorage.getItem("metadataId")
            : "",
        },
      })
      .then((response) => {
        let copyEntityData = response?.data?.Result?.metadata;
        copyEntityData = copyEntityData?.map((data) => {
          data.label = data.entity;
          data.value = data.attributes.id;
          return data;
        });
        setEntity(copyEntityData);
      });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.parentDiv}
      style={{
        position: "fixed",
        right: props.isopen ? 6 : 6,
        width: "80px",
        top: !props.isheaderhidden && 62,
        marginBottom: "20px",
      }}
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        // style={{ position: "fixed", top: "115px" }}
      >
        {/* <div className={classes.buttonDiv}>
          <button
            className={
              activemenu === "Properties"
                ? classes.buttonActive
                : classes.button
            }
            onClick={(e) => openpopover(e, "Properties")}
          >
            <Properties />
          </button>
          <p className={classes.Icontext}>Properties</p>
        </div> */}
        {/* <div className={classes.buttonDiv}>
          <button
            className={
              activemenu === "Comments" ? classes.buttonActive : classes.button
            }
            onClick={(e) => openpopover(e, "Comments")}
          >
            <Comments />
          </button>
          <p className={classes.Icontext}>Comments</p>
        </div> */}
        {/* <div className={classes.buttonDiv}>
          <button
            className={
              activemenu === "Share" ? classes.buttonActive : classes.button
            }
            onClick={(e) => openpopover(e, "Share")}
          >
            <Share />
          </button>
          <p className={classes.Icontext}>Share</p>
        </div> */}
        {/* <div className={classes.buttonDiv}>
          <button
            className={
              activemenu === "Function" ? classes.buttonActive : classes.button
            }
            onClick={(e) => openpopover(e, "Function")}
          >
            <Function />
          </button>
          <p className={classes.Icontext}>Function</p>
        </div> */}
        {/* <div className={classes.buttonDiv}>
          <button
            className={
              activemenu === "Document" ? classes.buttonActive : classes.button
            }
            onClick={(e) => openpopover(e, "Document")}
          >
            <Doc />
          </button>
          <p className={classes.Icontext}>Document</p>
        </div> */}
        <div className={classes.buttonDiv}>
          <button
            disabled
            className={
              activemenu === "BPMN"
                ? classes.buttonActive
                : classes.buttondisable
            }
            onClick={(e) => openpopover(e, "BPMN")}
          >
            <BPMN />
          </button>
          <p className={classes.Icontext}>Workflow Diagram</p>
        </div>
        <div className={classes.buttonDiv}>
          <button
            aria-describedby={"simple-popover"}
            className={
              activemenu === "Share" ? classes.buttonActive : classes.button
            }
            onClick={handleClick}
          >
            <TableChartIcon className={classes.entityIcon} />
          </button>
          <UserList
            userList={entity}
            handleClose={handleClose}
            addUser={addUser}
            anchorEl={anchorEl}
            selectedUsers={
              Data?.[selectedArr]?.entity?.length > 0
                ?  Data?.[selectedArr]?.entity
                : []
            }
          />

          <p className={classes.Icontext}>Entities</p>
        </div>
        {/* <div className={classes.buttonDiv}>
          <button className={classes.Viewbutton}>
            <CropFreeIcon className={classes.buttonViewiconsCustum} />
          </button>
        </div> */}
        {/* <UserList /> */}
        <div className={classes.buttonDiv}>
          <button
            className={classes.Viewbutton}
            onClick={() =>
              props.zooming <= 80 && props.handlesetzooming("zoomin")
            }
          >
            <AddIcon className={classes.buttonViewiconsCustum} />
          </button>
        </div>
        <div className={classes.buttonDiv}>
          <button
            className={classes.Viewbutton}
            onClick={() =>
              props.zooming >= 80 && props.handlesetzooming("zoomout")
            }
          >
            <RemoveIcon className={classes.buttonViewiconsCustum} />
          </button>
        </div>
      </Grid>
      {/* <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.ViewbuttonDiv}
      >
        <div className={classes.buttonDiv}>
          <button className={classes.Viewbutton}>
            <CropFreeIcon className={classes.buttonViewiconsCustum} />
          </button>
        </div>
        <div className={classes.buttonDiv}>
          <button className={classes.Viewbutton}>
            <AddIcon className={classes.buttonViewiconsCustum} />
          </button>
        </div>
        <div className={classes.buttonDiv}>
          <button className={classes.Viewbutton}>
            <RemoveIcon className={classes.buttonViewiconsCustum} />
          </button>
        </div>
      </Grid> */}
    </Grid>
  );
}
export default withAllContexts(Body);
